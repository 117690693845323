import { useMutation, useQuery } from "@tanstack/react-query";
import { apiClient } from "./apiClient";
import {
  CreateEmployeeParams,
  CreateLeaveParams,
  GetContractReviewParams,
  GetContractsToOnboard,
  GetContractUpdateParams,
  GetContractUpsertParams,
  GetEmployeeAssetsResponse,
  GetEmployeeLeavesParams,
  GetEmployeeLeavesResponse,
  GetEmployeePayslipsListParams,
  GetEmployeePayslipsListResponse,
  GetEmployeesParams,
  GetEmployeesResponse,
  GetJobHierarchyResponse,
  getOnBoardingAndOffBoardingEmployeesDashboardResponse,
  GetUpdateEmployeeParams,
} from "../types/apisTypes";
import qs from "qs";
import {
  Benefit,
  Employee,
  EmployeeDetails,
  EmployeeRequest,
  Payslip,
  PayslipOverView,
  RequestOverview,
  SalaryCalculations,
  Leave,
} from "../types/people";
import { PEOPLE_TO_ONBOARD, PEOPLE_TO_OFFBOARD } from "../mockServer/MockData";
import { Invoice } from "../types/payments";

export const useGetContractsToOnboard = () => {
  return useQuery<typeof PEOPLE_TO_ONBOARD, Error>({
    queryFn: async () => {
      const response = await apiClient.get("/employee_contract_onboarding");
      return response.data.data;
    },
    queryKey: ["contractsToOnboard"],
  });
};

export const useGetContractsToOffboard = () => {
  return useQuery<typeof PEOPLE_TO_OFFBOARD, Error>({
    queryFn: async () => {
      const response = await apiClient.get("/employee_contract_offboarding");
      return response.data.contracts;
    },
    queryKey: ["contractsToOffboard"],
  });
};

export const useCancelContract = () => {
  return useMutation<void, Error, string>({
    mutationFn: async (id: string) => {
      await apiClient.post("/employee_contract_cancel", {
        id,
      });
    },
  });
};

export const useGetEmployees = (params: GetEmployeesParams) => {
  return useQuery<GetEmployeesResponse, Error>({
    queryFn: async () => {
      const response = await apiClient.get(
        `/company_employees/${params.company_id}?${qs.stringify({
          ...params,
          company_id: undefined,
        })}`
      );
      return response.data;
    },
    retry: false,
    enabled: !!params.company_id,
    queryKey: ["employees", params],
  });
};

export const useGetActiveEmployees = (id: number | undefined) => {
  return useQuery<GetEmployeesResponse, Error>({
    queryFn: async () => {
      const response = await apiClient.get(
        `/company_employees/${id}?status=active`
      );
      return response.data;
    },
    retry: false,
    queryKey: ["activeEmployees"],
    enabled: !!id,
  });
};
export const useDeleteEmployee = (id: string) => {
  return useMutation<void, Error, string>({
    mutationFn: async () => {
      const response = await apiClient.delete(`/employee/${id}`);
      return response.data;
    },
  });
};

export const useGetEmployee = (id: string | undefined) => {
  return useQuery<EmployeeDetails, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/employee/${id}`);
      return response.data.employee;
    },
    queryKey: ["employee", id],
    retry: false,
    enabled: !!id,
  });
};
export const useCreateEmployee = () => {
  return useMutation<Employee, Error, CreateEmployeeParams>({
    mutationFn: async (data) => {
      const response = await apiClient.post("/employee", data);
      return response.data.employee;
    },
  });
};

export const useGetContractPreview = () => {
  return useMutation<ArrayBuffer, Error, GetContractReviewParams>({
    mutationFn: async (data: GetContractReviewParams) => {
      const response = await apiClient.post(
        "/employee_contract_preview",
        data,
        {
          responseType: "arraybuffer",
        }
      );
      return response.data;
    },
  });
};

export const useGetQuoteSummary = (data: GetContractReviewParams) => {
  return useQuery<Invoice, Error>({
    queryFn: async () => {
      const response = await apiClient.post(`/contract_quote`, data);
      return response.data.invoice;
    },
    queryKey: ["quoteSummary"],
    retry: false,
  });
};

export const useCreateEmployeeContract = () => {
  return useMutation<void, Error, GetContractReviewParams>({
    mutationFn: async (data) => {
      const response = await apiClient.post(`/employee_contract`, data);
      return response.data;
    },
  });
};

export const useUpsertEmployeeContract = () => {
  return useMutation<void, Error, GetContractUpsertParams>({
    mutationFn: async (data) => {
      const response = await apiClient.post(`/contract_adjustment`, data);
      return response.data;
    },
  });
};

export const useUpdateEmployeeContract = () => {
  return useMutation<void, Error, GetContractUpdateParams>({
    mutationFn: async (data) => {
      const response = await apiClient.patch(`/employee_contract/${data.contract_id}`, data);
      return response.data;
    },
  });
};

export const useUpdateEmployee = () => {
  return useMutation<void, Error, GetUpdateEmployeeParams>({
    mutationFn: async (data) => {
      const response = await apiClient.patch(`/employee/${data.employee_id}`, data);
      return response.data;
    },
  });
};

export const useGetEmployeeAssets = (id: string | undefined) => {
  return useQuery<GetEmployeeAssetsResponse, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/employee/${id}/asset`);
      return response.data;
    },
    queryKey: ["employeeAssets", id],
    retry: false,
    enabled: !!id,
  });
};

export const useGetAvailableAssets = () => {
  return useQuery<GetEmployeeAssetsResponse, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/asset`);
      return response.data;
    },
    queryKey: ["AllAssets"],
    retry: false,
  });
};

export const useAssignAsset = () => {
  return useMutation<void, Error, { employeeId: string; asset_id: string }>({
    mutationFn: async ({ employeeId, asset_id }) => {
      const response = await apiClient.post(`/employee/${employeeId}/asset`, {
        asset_id,
      });
      return response.data;
    },
  });
};

export const useUnassignAsset = () => {
  return useMutation<void, Error, { employeeId: string; asset_id: string }>({
    mutationFn: async ({ employeeId, asset_id }) => {
      const response = await apiClient.delete(
        `/employee/${employeeId}/asset/${asset_id}`
      );
      return response.data;
    },
    retry: false,
  });
};

export const useGetAllREmployeeRequests = () => {
  return useQuery<RequestOverview[], Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/request`);
      return response.data.data.requests;
    },
    queryKey: ["AllRequests"],
    retry: false,
  });
};

export const useGetRequestDetails = (id: string | undefined) => {
  return useQuery<EmployeeRequest, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/request/${id}`);
      return response.data.data.request;
    },
    queryKey: ["Request", id],
    retry: false,
    enabled: !!id,
  });
};

export const useGetEmployeeBenefits = () => {
  return useQuery<Benefit[], Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/benefit`);
      return response.data.data.benefits;
    },
    queryKey: ["employeeBenefits"],
    retry: false,
  });
};

export const useUnenrollBenefitNow = () => {
  return useMutation<void, Error, { benefit_id: string }>({
    mutationFn: async ({ benefit_id }) => {
      const response = await apiClient.delete(`/benefit/${benefit_id}`);
      return response.data;
    },
  });
};

export const useUnenrollBenefitByDate = () => {
  return useMutation<
    void,
    Error,
    { benefit_id: string; unenroll_date: string }
  >({
    mutationFn: async ({ benefit_id, unenroll_date }) => {
      const response = await apiClient.post(`/benefit/${benefit_id}/unenroll`, {
        unenroll_date,
      });
      return response.data;
    },
  });
};

export const useGetEmployeePayslips = (
  id: string | undefined,
  params: GetEmployeePayslipsListParams
) => {
  return useQuery<GetEmployeePayslipsListResponse, Error>({
    queryFn: async () => {
      const response = await apiClient.get(
        `/payslip_employee/${id}?${qs.stringify(params)}`
      );
      return response.data;
    },
    queryKey: ["employeePayslips", id, params],
    retry: false,
    enabled: !!id,
  });
};

export const useGetPayslipDetails = (id: string | undefined) => {
  return useQuery<Payslip, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/payslip/${id}`);
      return response.data.payslip;
    },
    queryKey: ["employeePayslip", id],
    retry: false,
    enabled: !!id,
  });
};

export const useDownloadPayslip = ({
  payslipId,
  enabled,
}: {
  payslipId: string | undefined;
  enabled: boolean;
}) => {
  return useQuery<ArrayBuffer, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/payslip_download/${payslipId}`, {
        responseType: "arraybuffer",
      });
      return response.data;
    },
    queryKey: ["payslipFile", payslipId],
    retry: false,
    enabled: enabled && !!payslipId,
  });
};

export const useRenewEmployeeContract = () => {
  return useMutation<void, Error, { contractId: string }>({
    mutationFn: async ({ contractId }) => {
      const response = await apiClient.post(`/contract/renew/${contractId}`);
      return response.data;
    },
  });
};

export const useCancelRenewEmployeeContract = () => {
  return useMutation<void, Error, { id: number }>({
    mutationFn: async ({ id }) => {
      const response = await apiClient.post('/cancel_autorenew', { id });
      return response.data;
    },
  });
};

export const useGetSalaryCalculations = (salary_net: string) => {
  return useQuery<SalaryCalculations, Error>({
    queryFn: async () => {
      const response = await apiClient.post(`/utils/calculate-salary`, {
        salary_net,
      });
      return response.data.data;
    },
    queryKey: ["salaryCalculations"],
    retry: false,
  });
};

export const useGetJobHierarchy = () => {
  return useQuery<GetJobHierarchyResponse, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/job_hierarchy`);
      return response.data;
    },
    queryKey: ["jobHierarchy"],
    retry: false,
  });
};

export const useOnBoardingAndOffBoardingEmployeesDashboard = (companyId: number | undefined) => {
  return useQuery<getOnBoardingAndOffBoardingEmployeesDashboardResponse, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/dashboard/employees?company_id=${companyId}`);
      return response.data;
    },
    retry: false,
    queryKey: ["getOnBoardingAndOffBoardingEmployeesDashboard", companyId],
    enabled: !!companyId
  });
};

export const useGetEmployeeLeaves = (params: GetEmployeeLeavesParams) => {
  return useQuery<GetEmployeeLeavesResponse, Error>({
     queryFn: async () => {
      const response = await apiClient.get(`/leaves?${qs.stringify(params)}`);
      return response.data;
     },
     retry: false,
     enabled: !!params.company_id,
     queryKey: ["Leaves", params]
   });
};

export const useCreateLeave = () => {
  return useMutation<Leave, Error, CreateLeaveParams>({
    mutationFn: async (data) => {
      const response = await apiClient.post("/leave", data);
      return response.data;
    },
  });
};