import moment from "moment";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../constants/styles";
import { PAYMENT_STATUS_BADGE_COLOR } from "../../../types/payments";
import { currencyFormatter } from "../../../utilities/currencyFormatter";
import Badge from "../../shared/Badge";
import {
  Table,
  TableCell,
  TableHeader,
  TableRow,
  TableRowDynamic,
  TableHeadWithIcon,
} from "../../shared/Table/styled";
import { TableTextBold } from "../InvoiceDetails/styled";
import { PaymentsTable, TableFadedText } from "./styled";
import Button from "../../shared/Button";
import { useNavigate } from "react-router-dom";
import { useGetAllPayments } from "../../../queries/payment";
import PeopleEmptyState from "../../people/PeopleEmptyState";
import { useAppDispatch, useAppSelector } from "../../../redux/reduxHooks";
import TablePagination from "../../shared/TablePagination";
import { updatePaymentTableVariables } from "../../../redux/slices/paymentSlice";
import { Sort } from "../../shared/SortIcon";
import { useEffect } from "react";

const PaymentsList = () => {
  const { activeCompany } = useAppSelector((state) => state.companies);
  const { payments_table_Variables } = useAppSelector((state) => state.payment);
  const dispatch = useAppDispatch();
  const { data } = useGetAllPayments({
    company_id: activeCompany?.id,
    sort: payments_table_Variables.sortKey,
    sort_dir: payments_table_Variables.sortDirection,
    page: payments_table_Variables.page,
    limit: payments_table_Variables.limit,
  });
  const navigate = useNavigate();

  const changeSortDirection = () => {
    dispatch(
      updatePaymentTableVariables({
        sortDirection:
          payments_table_Variables.sortDirection === "ASC" ? "DESC" : "ASC"
      })
    );
  };

  useEffect(() => {
    return function() {
      dispatch(updatePaymentTableVariables({
        sortDirection: "ASC",
        sortKey: "public_id",
        page: 1
      }))
    }
  }, [])

  const renderRows = () => {
    return data?.payments?.map((payment) => {
      return (
        <TableRow>
          <TableCell>
            <TableTextBold size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
              {payment.public_id}
            </TableTextBold>
          </TableCell>
          <TableCell>
            <Badge
              text={payment.status}
              dot
              color={PAYMENT_STATUS_BADGE_COLOR[payment.status]}
            />
          </TableCell>
          <TableCell>
            <TableFadedText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {payment.invoice_count}
            </TableFadedText>
          </TableCell>
          <TableCell>
            <TableFadedText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {currencyFormatter(payment.invoice_total, payment.currency)}
            </TableFadedText>
          </TableCell>
          <TableCell>
            <TableFadedText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {currencyFormatter(payment.value, payment.currency)}
            </TableFadedText>
          </TableCell>

          <TableCell>
            <TableFadedText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {moment(payment.created_at).format("DD MMM, YYYY")}
            </TableFadedText>
          </TableCell>
          <TableCell>
            <Button
              text="View Details"
              onClick={() => navigate(`/payment/payment-details/${payment.public_id}`)}
              variant={ButtonVariants.secondary_color}
              size={ButtonSizes.sm}
            />
          </TableCell>
        </TableRow>
      );
    });
  };
  return data?.payments?.length && data?.payments?.length > 0 ? (
      <PaymentsTable>
        <TableHeader>
          <TableCell>
            <TableHeadWithIcon
              onClick={() => {
                dispatch(
                  updatePaymentTableVariables({
                    sortKey: "public_id",
                  })
                );
              }}
            >
              <TableFadedText
                size={FontSizes.TX_XS}
                weight={FontWeights.MEDIUM}
              >
                Payment ID
              </TableFadedText>
              <Sort
                sortKey="public_id"
                currentSortKey={payments_table_Variables.sortKey}
                sortDirection={payments_table_Variables.sortDirection}
                onClick={changeSortDirection}
              />
            </TableHeadWithIcon>
          </TableCell>
          <TableCell>
            <TableHeadWithIcon
              onClick={() => {
                dispatch(
                  updatePaymentTableVariables({
                    sortKey: "status",
                  })
                );
              }}
            >
              <TableFadedText
                size={FontSizes.TX_XS}
                weight={FontWeights.MEDIUM}
              >
                Status
              </TableFadedText>
              <Sort
                sortKey="status"
                currentSortKey={payments_table_Variables.sortKey}
                sortDirection={payments_table_Variables.sortDirection}
                onClick={changeSortDirection}
              />
            </TableHeadWithIcon>
          </TableCell>
          <TableCell>
            <TableHeadWithIcon
              onClick={() => {
                dispatch(
                  updatePaymentTableVariables({
                    sortKey: "invoice_count",
                  })
                );
              }}
            >
              <TableFadedText size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
                Number of Invoices
              </TableFadedText>
              <Sort
                sortKey="invoice_count"
                currentSortKey={payments_table_Variables.sortKey}
                sortDirection={payments_table_Variables.sortDirection}
                onClick={changeSortDirection}
              />
            </TableHeadWithIcon>
          </TableCell>
          <TableCell>
            <TableHeadWithIcon
              onClick={() => {
                dispatch(
                  updatePaymentTableVariables({
                    sortKey: "invoice_total",
                  })
                );
              }}
            >
              <TableFadedText size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
                Invoice Total
              </TableFadedText>
              <Sort
                sortKey="invoice_total"
                currentSortKey={payments_table_Variables.sortKey}
                sortDirection={payments_table_Variables.sortDirection}
                onClick={changeSortDirection}
              />
            </TableHeadWithIcon>
          </TableCell>
          <TableCell>
            <TableHeadWithIcon
              onClick={() => {
                dispatch(
                  updatePaymentTableVariables({
                    sortKey: "value",
                  })
                );
              }}
            >
              <TableFadedText
                size={FontSizes.TX_XS}
                weight={FontWeights.MEDIUM}
              >
                Value
              </TableFadedText>
              <Sort
                sortKey="value"
                currentSortKey={payments_table_Variables.sortKey}
                sortDirection={payments_table_Variables.sortDirection}
                onClick={changeSortDirection}
              />
            </TableHeadWithIcon>
          </TableCell>
          <TableCell>
            <TableHeadWithIcon
              onClick={() => {
                dispatch(
                  updatePaymentTableVariables({
                    sortKey: "created_at",
                  })
                );
              }}
            >
              <TableFadedText
                size={FontSizes.TX_XS}
                weight={FontWeights.MEDIUM}
              >
                Payment Date
              </TableFadedText>
              <Sort
                sortKey="created_at"
                currentSortKey={payments_table_Variables.sortKey}
                sortDirection={payments_table_Variables.sortDirection}
                onClick={changeSortDirection}
              />
            </TableHeadWithIcon>
          </TableCell>
          <TableCell>
            <TableFadedText size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
              Actions
            </TableFadedText>
          </TableCell>
        </TableHeader>
        {renderRows()}
        {data?._metadata?.last_page && data?._metadata?.last_page > 1 && (
          <TableRowDynamic height="68px">
            <TablePagination
              currentPage={data?._metadata?.page ?? 1}
              setCurrentPage={(page: number) =>
                dispatch(
                  updatePaymentTableVariables({
                    page: page,
                  })
                )
              }
              totalPages={data?._metadata?.last_page ?? 1}
            />
          </TableRowDynamic>
        )}
      </PaymentsTable>
  ) : (
    <PeopleEmptyState
      title="No Payments Found"
      description="You have not made any payments yet."
    />
  );
};

export default PaymentsList;
