import styled from "styled-components";
import {
  Colors,
  FontSizes,
  FontWeights,
  Radiuses,
  Spaces,
} from "../../../constants/styles";
import Label from "../Label";
import CalenderBlue from "../../../Images/icons/calendarBlue.svg";
import Typography from "../Typography";

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_sm};
`;
export const InputLabel = styled(Label)`
  margin-block: ${Spaces.spacing_none};
`;

export const InputRow = styled.div<{ error: boolean }>`
  width: 100%;
  display: flex;
  height: 40px;
  align-items: center;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border: 1px solid ${Colors.border_primary};
  border-radius: ${Radiuses.radius_md};
  background-color: #fff;
  justify-content: space-between;
  padding: 0px;

  &:focus-within {
    border: 1px solid ${Colors.border_brand};
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05),
      0px 0px 0px 4px rgba(158, 119, 237, 0.24);
  }
  ${(props) =>
    props.error &&
    `
    border: 1px solid ${Colors.border_error};
   
  `};
`;

export const CurrencyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: ${Spaces.spacing_lg};
  height: 100%;
  border-radius: ${Radiuses.radius_md};
  border: solid ${Colors.border_primary};
  border-width: 0px 0px 0px 1px;
`;

export const Currency = styled(Typography)`
  font-size: ${FontSizes.TX_MD};
  font-weight: ${FontWeights.REGULAR};
`;

export const ExtraMessage = styled.p`
  display: none;
  margin-block-end: ${Spaces.spacing_none};
  margin-block-start: ${Spaces.spacing_sm};
  font-size: ${FontSizes.TX_SM};
  font-weight: ${FontWeights.REGULAR};
  color: ${Colors.text_tertiary_600};
`;

export const Input = styled.input<{
  disabled?: boolean;
  error?: string;
  hint?: string;
}>`
  font-family: inter;
  height: 40px;
  padding: 10px 14px;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border: 1px solid ${Colors.border_primary};
  border-radius: ${Radiuses.radius_md};
  font-size: "1rem";
  font-weight: 400;
  color: ${Colors.text_primary};
  text-overflow: ellipsis;

  ::placeholder {
    color: ${Colors.text_placeholder};
  }
  &[type="password"] {
    color: ${Colors.text_placeholder};
    letter-spacing: 0.3px;
  }

  &[type="date"]::-webkit-calendar-picker-indicator {
    background: url(${CalenderBlue});
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
  }

  &[type="month"]::-webkit-calendar-picker-indicator {
    background: url(${CalenderBlue});
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
  }

  input::-webkit-outer-spin-button,
  &[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:focus {
    outline: none;

    ${(props) =>
      !props.disabled &&
      !props.error &&
      `
    border: 1px solid ${Colors.border_brand};

    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05),
      0px 0px 0px 4px rgba(158, 119, 237, 0.24);
  `};

    ${(props) =>
      !props.disabled &&
      props.error &&
      `
    border: 1px solid ${Colors.border_error};

    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(240, 68, 56, 0.24);
  `};
  }

  ${(props) =>
    props.disabled &&
    `
    background-color: ${Colors.bg_disabled_subtle};
    border: 1px solid ${Colors.border_disabled};
    color: ${Colors.text_disabled};
  `};

  ${(props) =>
    props.error &&
    `
    border: 1px solid ${Colors.border_error};
    ~ ${ExtraMessage} {
      display: block;

      color: ${Colors.text_error_primary};
    }
  `};

  ${(props) =>
    props.hint &&
    `
    ~ ${ExtraMessage} {
      display: block;
    }
  `};
`;
