import { useEffect, useState } from 'react'
import VerticalModal from "../../../../shared/VerticalModal";
import { Content, ModalContent, ModalContentContainer, ModalContentHeader, ModalFooter, ModalTitle } from './styled';
import { ButtonSizes, ButtonVariants, FontSizes, FontWeights } from '../../../../../constants/styles';
import Button from '../../../../shared/Button';
import { useSnackbar } from '../../../../../hooks/useSnackbar';
import { useQueryClient } from '@tanstack/react-query';
import InputField from '../../../../shared/InputField';
import { EmployeeDetails } from '../../../../../types/people';
import { SnackbarTypes } from '../../../../../types/componentsProps';
import DropdownList from '../../../../shared/DropdownList';
import { useUpdateEmployee } from '../../../../../queries/people';
import { useAppSelector } from '../../../../../redux/reduxHooks';
import { formErrorArrayHandler, formErrorHandler } from '../../../../../utilities/formHandler';

interface ModalProps {
  open: boolean;
  onClose: () => void;
  employee: EmployeeDetails
}

const EditEmployeeModal: React.FC<ModalProps> = ({ open, onClose, employee }) => {
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { countriesOptions } = useAppSelector((state) => state.appState);

  const [ formData, setFormData ] = useState({
    firstName: employee.user.first_name,
    lastName: employee.user.last_name,
    email: employee.user.email,
    nationality: {label: employee.nationality, value: employee.nationality_id}
  })

  const [ formErrors, setFormErrors ] = useState({
    first_name: "",
    last_name: "",
    email: "",
    nationality: ""
  });
  
  const { mutateAsync: updateEmployee } = useUpdateEmployee();

  useEffect(() => {
    setFormData({
      firstName: employee.user.first_name,
      lastName: employee.user.last_name,
      email: employee.user.email,
      nationality: {label: employee.nationality, value: employee.nationality_id}
    })
    setFormErrors({
      first_name: "",
      last_name: "",
      email: "",
      nationality: ""
    });
  }, [employee, open])

  const isDisabled = () => {
    return (
      formData.firstName === employee.user.first_name &&
      formData.lastName === employee.user.last_name &&
      formData.email === employee.user.email &&
      formData.nationality.label === employee.nationality
    );
  }

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      first_name: "",
      last_name: "",
      email: "",
      nationality: ""
    };
    if (formData.firstName === "") {
      newErrors.first_name = "Please enter your first name";
      isValid = false;
    }
    if (formData.lastName === "") {
      newErrors.last_name = "Please enter your last name";
      isValid = false;
    }
    if (formData.email === "") {
      newErrors.email = "Please enter your email";
      isValid = false;
    }
    if (formData.nationality.label === "") {
      newErrors.nationality = "Please select your nationality";
      isValid = false;
    }
    setFormErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async () => {
    if(validateForm()) {
      try {
        await updateEmployee({
          employee_id: employee.id,
          first_name: formData.firstName,
          last_name: formData.lastName,
          email: formData.email,
          nationality: formData.nationality.value
        })
        queryClient.invalidateQueries({
          queryKey: ["employee"],
        });
        onClose();
        showSnackbar({
          type: SnackbarTypes.SUCCESS,
          open: true,
          title: "Success",
          description: "Employee Data Changed.",
        });
      } catch (err: any) {
        const errorMessage: string = err.response.data.message;
        const errorArray: [] = err.response.data.errors;
        const errorHandled: boolean = errorMessage ?
          formErrorHandler(errorMessage, formErrors, setFormErrors) :
          formErrorArrayHandler(errorArray, formErrors, setFormErrors);

        if(!errorHandled) {
          onClose();
          showSnackbar({
            type: SnackbarTypes.ERROR,
            open: true,
            title: "Couldn't Apply Contract Changes.",
            description: "Please try again later.",
          });
        }
      }
    }
  }

  return employee ? (
    <VerticalModal open={open} onClose={onClose}>
      <ModalContentContainer>
        <ModalContentHeader>
          <ModalTitle size={FontSizes.XS} weight={FontWeights.SEMIBOLD}>
            Updating Employee
          </ModalTitle>
        </ModalContentHeader>

        <ModalContent>
          <Content>
            <InputField
              label="First Name"
              value={formData.firstName}
              onChange={(e) => {
                setFormData({ ...formData, firstName: e.target.value });
              }}
              error={formErrors.first_name}
              onFocus={() => {
                setFormErrors((prevData) => ({
                  ...prevData,
                  first_name: "",
                }));
              }}
              type="text"
            />
            <InputField
              label="Last Name"
              value={formData.lastName}
              onChange={(e) => {
                setFormData({ ...formData, lastName: e.target.value });
              }}
              error={formErrors.last_name}
              onFocus={() => {
                setFormErrors((prevData) => ({
                  ...prevData,
                  last_name: "",
                }));
              }}
              type="text"
            />
            <InputField
              label="Email"
              value={formData.email}
              onChange={(e) => {
                setFormData({ ...formData, email: e.target.value });
              }}
              error={formErrors.email}
              onFocus={() => {
                setFormErrors((prevData) => ({
                  ...prevData,
                  email: "",
                }));
              }}
              type="email"
            />
            <DropdownList
              label="Nationality"
              error={formErrors.nationality}
              options={countriesOptions}
              selectedOption={formData.nationality}
              setSelectedOption={(option) => {
                setFormErrors((prevData) => ({
                  ...prevData,
                  nationality: "",
                }));
                setFormData({ ...formData, nationality: option });
              }}
            />
          </Content>
        </ModalContent>

        <ModalFooter>
          <Button
            text="Cancel"
            variant={ButtonVariants.secondary_gray}
            size={ButtonSizes.lg}
            onClick={() => onClose()}
          />
          <Button
            text="Submit"
            variant={ButtonVariants.primary}
            size={ButtonSizes.lg}
            onClick={() => handleSubmit()}
            disabled={isDisabled()}
          />
        </ModalFooter>
      </ModalContentContainer>
    </VerticalModal>
  ) : null;
};

export default EditEmployeeModal;