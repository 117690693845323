import { useQuery } from "@tanstack/react-query";
import { apiClient } from "./apiClient";

export const useGetPayslips = ({payroll_id, enabled} : {payroll_id: string | undefined,enabled: boolean}) => {
  return useQuery<void, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/payslip_download_all/${payroll_id}`,{
        responseType: 'arraybuffer',
      });
      return response.data;
    },
    enabled: !!payroll_id && enabled,
    retry: false,
    queryKey: ["all_payslips", payroll_id],
    });
  };