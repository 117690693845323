import styled from "styled-components";
import { Colors, Spaces } from "../../constants/styles";
import Typography from "../../components/shared/Typography";

export const PayrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: ${Colors.bg_active};
  flex: 1;
  padding-inline: ${Spaces.spacing_xl};

  height: 100%;
  overflow-y: auto;
`;

export const PayrollHeader = styled.div`
  width: 100%;
  padding-bottom: ${Spaces.spacing_2xl};
  border-bottom: 1px solid ${Colors.border_secondary};
`;

export const PayrollTitle = styled(Typography)`
  color: ${Colors.text_primary};
  margin-bottom: ${Spaces.spacing_xs};
`;
export const PayrollSubtitle = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
