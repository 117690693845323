import styled from "styled-components";
import { Colors, Radiuses } from "../../../../constants/styles";
import Typography from "../../../shared/Typography";
import { Table } from "../../../shared/Table/styled";

export const ScrollableTableWrapper = styled.tbody`
  max-height: 21.25rem;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const EmployeeTableContainer = styled(Table)`
  border: 1px;
  border-radius: ${Radiuses.radius_xl};
  border-color: ${Colors.border_secondary};
  border-style: solid;
  background-color: ${Colors.bg_primary};
  border-collapse: collapse;
  position: relative;
  overflow: hidden;
`;

export const TextFaded = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;

export const TextBold = styled(Typography)`
  color: ${Colors.text_primary};
`;