import { useState } from "react";
import {
  ButtonsContainer,
  FormContainer,
  FormSubtitle,
  FormTitle,
  Salary,
  SalaryType,
  ToggleButton,
  ToggleContainer,
} from "./styled";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import DropdownList from "../../../shared/DropdownList";
import { FormFields } from "../../NewEmployee/styled";
import Button from "../../../shared/Button";
import ArrowBack from "../../../../Images/icons/arrow-left.svg";
import InputField from "../../../shared/InputField";
import PartialDropdown from "../../../shared/PartialDropdown";
import { DropdownListOption } from "../../../../types/componentsProps";
import { useAppDispatch, useAppSelector } from "../../../../redux/reduxHooks";
import { updateNewContractData } from "../../../../redux/slices/peopleSlice";
import {
  EMPLOYMENT_TERMS,
  EMPLOYMENT_TYPES,
  CURRENCIES,
} from "../../../../constants/dropDownLists";
import { getFirstSundayOfUpcomingMonth } from "../../../../utilities/getFirstSundayOfUpcomingMonth";
import Checkbox from "../../../shared/Checkbox";
import Label from "../../../shared/Label";

const CompensationForm = ({
  onNext,
  onBack,
}: {
  onNext: () => void;
  onBack: () => void;
}) => {
  const dispatch = useAppDispatch();

  const { activeCompany } = useAppSelector((state) => state.companies);
  const { newContractData } = useAppSelector((state) => state.people);

  const [formData, setFormData] = useState({
    employment_type: newContractData.employment_type,
    hours_per_week: newContractData.hours_per_week,
    salary: newContractData.salary,
    salary_type: newContractData.salary_type,
    currency: newContractData.currency,
    start_date: newContractData.start_date || getFirstSundayOfUpcomingMonth(),
    employment_term: {
      label: "1 Year",
      value: 12,
    } satisfies DropdownListOption,
  });
  const [errors, setErrors] = useState({
    hours_per_week: "",
    salary: "",
    start_date: "",
  });

  const setDataPoint = (key: string, value: any) => {
    setFormData({ ...formData, [key]: value });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };
    if (formData.hours_per_week === 0) {
      newErrors.hours_per_week = "Work hours per week is required";
      isValid = false;
    }
    if (formData.hours_per_week > 40) {
      newErrors.hours_per_week = "Invalid value";
      isValid = false;
    }
    if (formData.start_date === "") {
      newErrors.start_date = "Start date is required";
      isValid = false;
    }
    if (formData.start_date !== "") {
      const selectedDate = new Date(formData.start_date);
      const threeMonthsAgo = new Date();
      threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

      if (selectedDate < threeMonthsAgo) {
        newErrors.start_date = "Start date cannot be older than 3 months";
        isValid = false;
      }
    }
    if (formData.salary == 0) {
      newErrors.salary = "Salary is required";
      isValid = false;
    }    
    if (formData.salary < 6000 && formData.currency.label == 'EGP') {
      newErrors.salary = "Min salary is 6000 EGP";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  const onSubmit = () => {
    if (validateForm()) {
      dispatch(
        updateNewContractData({
          ...formData,
          currency: formData.currency,
          employment_type: formData.employment_type,
          employment_term: formData.employment_term,
        })
      );
      onNext();
    }
  };

  return (
    <FormContainer>
      <FormTitle size={FontSizes.XS} weight={FontWeights.SEMIBOLD}>
        Compensation
      </FormTitle>
      <FormSubtitle size={FontSizes.TX_MD} weight={FontWeights.REGULAR}>
        Specify the agreed salary and any additional financial benefits.
      </FormSubtitle>
      <FormFields>
        <DropdownList
          label="Employment Type*"
          options={EMPLOYMENT_TYPES}
          selectedOption={formData.employment_type}
          setSelectedOption={(option) => {
              setDataPoint("employment_type", option)
              setDataPoint("hours_per_week", option.value === "Full-time" ? 40 : 20)
            }
          }
        />
        <InputField
          label="Work Hours Per Week*"
          onChange={(e) => {
            setDataPoint("hours_per_week", e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "e" || e.key === ".") {
              e.preventDefault();
            }
          }}
          onFocus={() => {
            setErrors({ ...errors, hours_per_week: "" });
          }}
          error={errors.hours_per_week}
          type="number"
          value={formData.hours_per_week == 0 ? '' : formData.hours_per_week}
        />
        <Salary>
          <PartialDropdown
            label="Salary*"
            value={formData.salary == 0 ? '' : formData.salary}
            error={errors.salary}
            onFocus={() => {
              setErrors({
                ...errors,
                salary: "",
              });
            }}
            onChange={(e) => {
              setDataPoint("salary", e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "e" || e.key === ".") {
                e.preventDefault();
              }
            }}
            options={CURRENCIES.filter((currency) => {
              return activeCompany?.invoice_currency === "EGP"
                ? currency.label === "EGP"
                : currency.label === "EGP" || currency.label === activeCompany?.invoice_currency;
            })}
            setSelectedOption={(option: DropdownListOption) => {
              setDataPoint("currency", option);
            }}
            selectedOption={formData.currency}
            type="number"
            trailing
          />

          <SalaryType>
            <Label text="Salary Type*" />
            <ToggleContainer>
              <ToggleButton
                active={formData.salary_type === "Gross"}
                onClick={() => setFormData((prevData) => ({
                  ...prevData,
                  salary_type: "Gross"
                }))}
              >
              Gross
            </ToggleButton>
            <ToggleButton
              active={formData.salary_type === "Net"}
              onClick={() => setFormData((prevData) => ({
                ...prevData,
                salary_type: "Net"
              }))}
            >
              Net
            </ToggleButton>
            </ToggleContainer>
          </SalaryType>
        </Salary>

        <InputField
          label="Start Date*"
          type="date"
          placeholder="11-10-1990"
          value={formData.start_date}
          onChange={(e) => {
            setDataPoint("start_date", e.target.value);
          }}
          error={errors.start_date}
          onFocus={() => {
            setErrors({
              ...errors,
              start_date: "",
            });
          }}
          min={
            new Date(new Date().setMonth(new Date().getMonth() - 3))
              .toISOString()
              .split("T")[0]
          }
        />
        <DropdownList
          label="Employment Terms*"
          options={EMPLOYMENT_TERMS}
          selectedOption={formData.employment_term}
          setSelectedOption={(option) =>
            setDataPoint("employment_term", option)
          }
        />
        <ButtonsContainer>
          <Button
            text="Back"
            onClick={onBack}
            variant={ButtonVariants.secondary_gray}
            size={ButtonSizes.lg}
            style={{
              width: "fit-content",
            }}
            iconLeading={<img src={ArrowBack} />}
          />
          <Button
            text="Next"
            onClick={onSubmit}
            variant={ButtonVariants.primary}
            size={ButtonSizes.lg}
            style={{
              width: "50%",
            }}
          />
        </ButtonsContainer>
      </FormFields>
    </FormContainer>
  );
};

export default CompensationForm;
