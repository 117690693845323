import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../redux/reduxHooks";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  NoPaymentText,
  PaymentCard,
  PaymentCardAmount,
  PaymentCardData,
  PaymentCardDate,
  PaymentCardLeftCol,
  PaymentCardRightCol,
  PaymentCardType,
  PaymentCardsContainer,
  PaymentSectionContainer,
  PaymentSectionHeader,
  PaymentSectionTitle,
  PaymentsContainer,
} from "./styled";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../constants/styles";
import Button from "../../shared/Button";
import PaymentAlert from "./PaymentAlert";
import { BadgeTypes, SnackbarTypes } from "../../../types/componentsProps";
import BankIcon from "../../../Images/icons/bankIcon.svg";
import CryptoIcon from "../../../Images/icons/crypto.svg";
import PayPalIcon from "../../../Images/icons/paypal.svg";
import CreditCardIcon from "../../../Images/icons/credit-card-02.svg";
import Badge from "../../shared/Badge";
import { useGetPendingPaymentsDashboard } from "../../../queries/payment";
import { PAYMENT_METHODS, PaymentMethodsKey } from "../../../types/dashboard";
import { useSnackbar } from "../../../hooks/useSnackbar";
interface PaymentSectionProps {
  style?: React.CSSProperties;
}

const PaymentSection: React.FC<PaymentSectionProps> = ({ style }) => {
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const { showSnackbar } = useSnackbar();

  const { activeCompany } = useAppSelector((state) => state.companies);

  const { data: pendingPaymentsRequest, error } =
    useGetPendingPaymentsDashboard(activeCompany?.id);

  useEffect(() => {
    if (error) {
      showSnackbar({
        open: true,
        type: SnackbarTypes.ERROR,
        title: "Error fetching payments section data",
        description: "Please try again later",
      });
    }
  }, [error]);
  const isEmpty = pendingPaymentsRequest?.payments.length === 0;

  const getPaymentMethodIcon = (method: PaymentMethodsKey) => {
    switch (method) {
      case PAYMENT_METHODS.TRANSFER:
        return BankIcon;
      case PAYMENT_METHODS.CRYPTO:
        return CryptoIcon;
      case PAYMENT_METHODS.PAYPAL:
        return PayPalIcon;
      case PAYMENT_METHODS.DEBIT_CARD:
      case PAYMENT_METHODS.CREDIT_CARD:
        return CreditCardIcon;
    }
  };

  const getBadgeColor = (status: string) => {
    switch (status) {
      case "Waiting for payment to arrive":
        return BadgeTypes.warning;
      case "Processing":
        return BadgeTypes.blue;
      default:
        return BadgeTypes.blue;
    }
  };

  const renderPayments = () => {
    return pendingPaymentsRequest?.payments.map((payment, index) => {
      return (
        <PaymentCard
          key={index}
          onClick={() => navigate(`/payment/${payment.id}`)}
        >
          <PaymentCardLeftCol>
            <img
              src={getPaymentMethodIcon(payment.method as PaymentMethodsKey)}
              alt="bank-icon"
              width={36}
              height={36}
            />
            <PaymentCardData>
              <PaymentCardType
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {payment.method}
              </PaymentCardType>
              <PaymentCardDate
                size={FontSizes.TX_SM}
                weight={FontWeights.REGULAR}
              >
                Initiated on {moment(payment.created_at).format("D MMM YYYY")}
              </PaymentCardDate>
            </PaymentCardData>
          </PaymentCardLeftCol>

          <PaymentCardRightCol>
            <PaymentCardAmount
              size={FontSizes.TX_SM}
              weight={FontWeights.SEMIBOLD}
            >
              ${new Intl.NumberFormat("en-US").format(payment.value)}
            </PaymentCardAmount>
            <Badge
              color={getBadgeColor(payment.status)}
              text={payment.status}
            />
          </PaymentCardRightCol>
        </PaymentCard>
      );
    });
  };
  return (
    <PaymentSectionContainer style={style}>
      <PaymentSectionHeader>
        <PaymentSectionTitle
          size={FontSizes.TX_MD}
          weight={FontWeights.SEMIBOLD}
        >
          Payments in Progress
        </PaymentSectionTitle>
        <Button
          text="View all"
          variant={ButtonVariants.link_color}
          size={ButtonSizes.md}
          style={{ color: "#2191F9" }}
          onClick={() => navigate(`/payment`)}
        />
      </PaymentSectionHeader>
      {showAlert ? <PaymentAlert /> : null}
      {isEmpty ? (
        <PaymentsContainer>
          <NoPaymentText size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            No Payments
          </NoPaymentText>
        </PaymentsContainer>
      ) : (
        <PaymentCardsContainer>{renderPayments()} </PaymentCardsContainer>
      )}
    </PaymentSectionContainer>
  );
};

export default PaymentSection;
