import { useEffect, useState } from "react";
import { ButtonSizes, ButtonVariants, FontSizes, FontWeights } from "../../../constants/styles";
import SearchInput from "../../shared/SearchInput";
import { ActionsCell, Container, Divider, EmployeeTableContainer, FiltersContainer, NameCell, TextBold, TextFaded } from "./styled";
import { useAppDispatch, useAppSelector } from "../../../redux/reduxHooks";
import { setEmployeesLeavesTableVairables } from "../../../redux/slices/peopleSlice";
import { useDebounce } from "../../../hooks/useDebounce";
import { TableCell, TableHeader, TableHeadWithIcon, TableRowDynamic } from "../../shared/Table/styled";
import { Sort } from "../../shared/SortIcon";
import TablePagination from "../../shared/TablePagination";
import InitialsProfilePic from "../../shared/InitialsProfilePic";
import Button from "../../shared/Button";
import LeaveDetailsModal from "../LeaveDetailsModal";
import { useNavigate } from "react-router-dom";
import { EmployeeDetails } from "../../../types/people";

const LeaveTable = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    sortKey,
    sortDirection,
    currentPage,
    filteredEmployees,
    totalPages,
  } = useAppSelector((state) => state.people.employees_leaves_table_variables);

  const [searchValue, setSearchValue] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState<EmployeeDetails | null>(null);
  const [openLeaveModal, setOpenLeaveModal] = useState(false);

  const debounceValue = useDebounce(searchValue);

  useEffect(() => {
    dispatch(
      setEmployeesLeavesTableVairables({
          search: debounceValue,
    }));
  }, [debounceValue]);

  const renderRows = () => {
    return filteredEmployees?.map((employee: EmployeeDetails, index) => {
      return (
        <>
          <TableRowDynamic height="40px">
            <TableCell>
              <NameCell>
                <InitialsProfilePic
                  fullName={`${employee.user.first_name} ${employee.user.last_name}`}
                />
                <div>
                  <TextBold size={FontSizes.TX_MD} weight={FontWeights.MEDIUM}>
                    {`${employee.user.first_name} ${employee.user.last_name}`}
                  </TextBold>
                  <TextFaded
                    size={FontSizes.TX_MD}
                    weight={FontWeights.REGULAR}
                  >
                    {employee.user.email}
                  </TextFaded>
                </div>
              </NameCell>
            </TableCell>

            <TableCell>
              <TextBold size={FontSizes.TX_MD} weight={FontWeights.MEDIUM}>
                {employee.number_of_days ? employee.number_of_days : "--"}
              </TextBold>
            </TableCell>

            <TableCell>
              <TextBold size={FontSizes.TX_MD} weight={FontWeights.MEDIUM}>
                {employee.contract?.leave_balance === 365 ? "Unlimited" : (employee.contract?.leave_balance ? employee.contract?.leave_balance : "--")}
              </TextBold>
            </TableCell>

            <TableCell>
              <ActionsCell>
                <Button
                  text="View"
                  variant={ButtonVariants.secondary_color}
                  size={ButtonSizes.sm}
                  onClick={() => {
                    navigate(`/leave-management/leaves/${employee.id}`)
                  }}
                />
                <Button
                  text="Assign"
                  variant={ButtonVariants.primary}
                  size={ButtonSizes.sm}
                  onClick={() => {
                    setSelectedEmployee(employee);
                    setOpenLeaveModal(true);
                  }}
                />
              </ActionsCell>
            </TableCell>
          </TableRowDynamic>
          {index !== filteredEmployees.length - 1 && <Divider />}
        </>
      );
    });
  };

  const renderTable = () => {
    return (
      <EmployeeTableContainer>
        <TableHeader>
          <TableCell>
            <TableHeadWithIcon
              onClick={() => {
                dispatch(
                  setEmployeesLeavesTableVairables({
                    sortDirection: sortKey !== "name" ? "ASC" : (sortDirection === "ASC" ? "DESC" : "ASC"),
                    sortKey: "name",
                  })
                );
              }}
            >
              <TextFaded size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
                Employee
              </TextFaded>
              <Sort
                sortKey={"name"}
                currentSortKey={sortKey}
                sortDirection={sortDirection}
                onClick={() => {}}
              />
            </TableHeadWithIcon>
          </TableCell>

          <TableCell>
            <TableHeadWithIcon
              onClick={() => {
                dispatch(
                  setEmployeesLeavesTableVairables({
                    sortDirection: sortKey !== "number_of_days" ? "ASC" : (sortDirection === "ASC" ? "DESC" : "ASC"),
                    sortKey: "number_of_days",
                  })
                );
              }}
            >
              <TextFaded size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
                Number Of Leaves
              </TextFaded>
              <Sort
                sortKey={"number_of_days"}
                currentSortKey={sortKey}
                sortDirection={sortDirection}
                onClick={() => {}}
              />
            </TableHeadWithIcon>
          </TableCell>

          <TableCell>
            <TableHeadWithIcon
              onClick={() => {
                dispatch(
                  setEmployeesLeavesTableVairables({
                    sortDirection: sortKey !== "leave_balance" ? "ASC" : (sortDirection === "ASC" ? "DESC" : "ASC"),
                    sortKey: "leave_balance",
                  })
                );
              }}
            >
              <TextFaded size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
                Leave Balance
              </TextFaded>
              <Sort
                sortKey={"leave_balance"}
                currentSortKey={sortKey}
                sortDirection={sortDirection}
                onClick={() => {}}
              />
            </TableHeadWithIcon>
          </TableCell>

          <TableCell>
            <TextFaded size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              Actions
            </TextFaded>
          </TableCell>
        </TableHeader>
        <Divider />

        {renderRows()}
        {totalPages > 1 && (
          <TableRowDynamic height="68px">
            <TablePagination
              currentPage={currentPage}
              setCurrentPage={(page: number) =>
                dispatch(setEmployeesLeavesTableVairables({ currentPage: page }))
              }
              totalPages={totalPages}
            />
          </TableRowDynamic>
        )}
      </EmployeeTableContainer>
    );
  };

  return (
    <Container>
      <FiltersContainer>
        <SearchInput
          placeholder="Search by employee"
          containerStyle={{ boxSizing: "border-box" }}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </FiltersContainer>
      {renderTable()}
      <LeaveDetailsModal
        open={openLeaveModal}
        onClose={() => {
          setOpenLeaveModal(false);
        }}
        employee={selectedEmployee}
      />
    </Container>
  );
}

export default LeaveTable
