import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../constants/styles";
import Typography from "../../shared/Typography";

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Spaces.spacing_3xl};
  justify-content: center;
  width: 400px;
  flex-wrap: wrap;
`;
export const ModalTitle = styled(Typography)`
  color: ${Colors.text_primary};
`;

export const ModalContentTitleAndImgContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${Spaces.spacing_xl};
`;

export const CloseIcon = styled.img`
  margin-left: auto;
  cursor: pointer;
`;

export const ModalFooter = styled.div`
  display: flex;
  gap: ${Spaces.spacing_lg};
  width: 100%;
  justify-content: space-between;
  margin-top: ${Spaces.spacing_4xl};
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_2xl};
  width: 100%;
`;

export const Information = styled.div`
  display: flex;
  align-items: start;
  padding: ${Spaces.spacing_md};
  background-color: ${Colors.utility_blue_light_100};
  border-radius: ${Radiuses.radius_md};
  border: 1px solid ${Colors.fg_brand_primary_alt};
  gap: ${Spaces.spacing_lg};
  width: 100%;
  box-sizing: border-box;
  margin-block-end: ${Spaces.spacing_xs};
`;

export const InformationDescription = styled(Typography)`
  color: ${Colors.text_tertiary_600};
  white-space: pre-line;
`;

export const SalaryFields = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: ${Spaces.spacing_2xl};
`;