import { useEffect, useState } from 'react'
import VerticalModal from "../../../../shared/VerticalModal";
import { Content, ModalContent, ModalContentContainer, ModalContentHeader, ModalFooter, CardHint, ModalTitle, AlertCard } from './styled';
import { ButtonSizes, ButtonVariants, FontSizes, FontWeights } from '../../../../../constants/styles';
import Button from '../../../../shared/Button';
import { useSnackbar } from '../../../../../hooks/useSnackbar';
import { useQueryClient } from '@tanstack/react-query';
import alertIcon from '../../../../../Images/icons/alertCircleGray.svg'
import Typography from '../../../../shared/Typography';
import InputField from '../../../../shared/InputField';
import { EmployeeDetails, PEOPLE_STATUS } from '../../../../../types/people';
import { DropdownListOption, SnackbarTypes } from '../../../../../types/componentsProps';
import DropdownList from '../../../../shared/DropdownList';
import { useGetJobHierarchy, useUpdateEmployeeContract, useUpsertEmployeeContract } from '../../../../../queries/people';
import { EMPLOYMENT_TERMS } from '../../../../../constants/dropDownLists';
import { formErrorArrayHandler, formErrorHandler } from '../../../../../utilities/formHandler';

interface ModalProps {
  open: boolean;
  onClose: () => void;
  employee: EmployeeDetails
}

const EditContractModal: React.FC<ModalProps> = ({ open, onClose, employee }) => {
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [ jobTitleOptions, setJobTitleOptions ] = useState<DropdownListOption[]>([]); 
  const [ departmentOptions, setDepartmentOptions ] = useState<DropdownListOption[]>([]); 

  const [ formData, setFormData ] = useState({
    salary: 0,
    department: { label: "", value: ""} as DropdownListOption,
    title: { label: "", value: ""} as DropdownListOption,
    titleInputFieldText: "",
    employmentTerms: EMPLOYMENT_TERMS[0],
    hoursPerWeek: 0,
    effectiveDate: "",
    startDate: ""
  })

  const [ formErrors, setFormErrors ] = useState({
    new_salary: "",
    salary: "",
    department: "",
    title: "",
    employment_terms: "",
    hours_per_week: "",
    effective_date: "",
    start_date: ""
  })

  const { data: jobs } = useGetJobHierarchy();

  const { mutateAsync: upsertContract } = useUpsertEmployeeContract();
  const { mutateAsync: updateContract } = useUpdateEmployeeContract();

  const isUpdating = (employee.contract?.status === PEOPLE_STATUS.pending_invite && !employee.contract.invoice_id) || employee.contract?.status === PEOPLE_STATUS.pending_payment;

  useEffect(() => {
    if (formData.department.label !== employee.contract?.department) {
      setFormData({
        ...formData,
        title: { label: "", value: "" },
      });
      const jobsFiltered = jobs?.records
        .filter((job) => job.category === formData.department.value)
        .map((job) => ({ label: job.role, value: job.role }));
      setJobTitleOptions(jobsFiltered || []);
    }
  }, [formData.department]);

  useEffect(() => {
    if(jobs) {
      const departments = [] as DropdownListOption[];
      jobs.records.map((job) => {
        if (departments.findIndex((dep) => dep.value === job.category) === -1) {
          departments.push({ label: job.category, value: job.category });
        }
      });

      const department = employee.contract?.department;
    
      const filteredJobs = jobs?.records.filter((job) => job.category === department);
  
      const titles = filteredJobs?.map((job) => ({
        value: job.role,
        label: job.role,
      })) || [];
  
      setJobTitleOptions(titles);
      setDepartmentOptions(departments);
    }
  }, [jobs, open])

  useEffect(() => {
    if(employee.contract) {
      const jobTitle = employee.contract.job_title;
      const department = employee.contract.department;
      const employmentTerm = EMPLOYMENT_TERMS.find((term) => term.value === employee.contract?.employment_term);
      setFormData({
        department: {label: department, value: department},
        title: {label: jobTitle, value: jobTitle},
        titleInputFieldText: jobTitle,
        salary: employee.contract.salary_type === "Gross" ?
          employee.contract.salary_gross : employee.contract.salary_net,
        hoursPerWeek: employee.contract.hours_per_week,
        startDate: employee.contract.start_date,
        effectiveDate: "",
        employmentTerms: employmentTerm || EMPLOYMENT_TERMS[0],
      })
      setFormErrors({
        new_salary: "",
        salary: "",
        effective_date: "",
        start_date: "",
        title: "",
        department: "",
        hours_per_week: "",
        employment_terms: ""
      });
    }
  }, [employee, open])

  const isDisabled = () => {
    return (
      (employee.contract?.salary_type === "Gross" 
        ? formData.salary === employee.contract.salary_gross 
        : formData.salary === employee.contract?.salary_net) &&
      formData.effectiveDate === "" &&
      formData.startDate === employee.contract?.start_date &&
      formData.department.label === employee.contract?.department &&
      ((jobTitleOptions.length > 0 && formData.title.label === employee.contract?.job_title) ||
        (jobTitleOptions.length === 0 && formData.titleInputFieldText === employee.contract?.job_title) ) &&
      formData.hoursPerWeek === employee.contract?.hours_per_week &&
      formData.employmentTerms.value === employee.contract?.employment_term
    );
  }

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      new_salary: "",
      salary: "",
      department: "",
      title: "",
      employment_terms: "",
      hours_per_week: "",
      effective_date: "",
      start_date: ""
    };

    if (formData.salary === 0) {
      newErrors.new_salary = "Please enter a correct salary";
      isValid = false;
    }
    if (!isUpdating && formData.effectiveDate === "") {
      newErrors.effective_date = "Please enter the month of changes";
      isValid = false;
    }
    if (isUpdating && formData.startDate === "") {
      newErrors.start_date = "Please enter a start date";
      isValid = false;
    }
    if (formData.department.label === "") {
      newErrors.department = "Please select a department";
      isValid = false;
    }
    if(jobTitleOptions.length > 0 && formData.title.label === "" ||
      jobTitleOptions.length === 0 && formData.titleInputFieldText === ""
    ) {
      newErrors.title = "Please select a job title";
      isValid = false;
    }
    if (!formData.hoursPerWeek) {
      newErrors.hours_per_week = "Please enter employee hours per week";
      isValid = false;
    }
    if (formData.hoursPerWeek > 40) {
      newErrors.hours_per_week = "Hours per week can't exceed 40 hours";
      isValid = false;
    }
    setFormErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async () => {
    if(validateForm() && employee.contract) {
      try {
        if(isUpdating) {
          await updateContract({
            contract_id: employee.contract.id,
            salary: formData.salary,
            start_date: formData.startDate,
            employment_term: formData.employmentTerms.value,
            job_title: jobTitleOptions.length > 0 ? formData.title.label : formData.titleInputFieldText,
            department: formData.department.label,
            hours_per_week: formData.hoursPerWeek,
            salary_type: employee.contract.salary_type,
            currency: employee.contract.currency
          });
        } else {
          const date = new Date(`${formData.effectiveDate}-01`).toISOString().split('T')[0];
          await upsertContract({
            contract_id: employee.contract.id,
            new_salary: formData.salary,
            new_job_title: jobTitleOptions.length > 0 ? formData.title.label : formData.titleInputFieldText,
            effective_date: date
          })
        }
        queryClient.invalidateQueries({
          queryKey: ["employee"],
        });
        onClose();
        showSnackbar({
          type: SnackbarTypes.SUCCESS,
          open: true,
          title: "Success",
          description: "Contract Changes applied or will be applied.",
        });
      } catch (err: any) {
        const errorMessage: string = err.response.data.message;
        const errorArray: [] = err.response.data.errors;
        const errorHandled: boolean = errorMessage ?
          formErrorHandler(errorMessage, formErrors, setFormErrors) :
          formErrorArrayHandler(errorArray, formErrors, setFormErrors);

        if(!errorHandled) {
          onClose();
          showSnackbar({
            type: SnackbarTypes.ERROR,
            open: true,
            title: "Couldn't Apply Contract Changes.",
            description: "Please try again later.",
          });
        }
      }
    }
  }

  return employee.contract ? (
    <VerticalModal open={open} onClose={onClose}>
      <ModalContentContainer>
        <ModalContentHeader>
          <ModalTitle size={FontSizes.XS} weight={FontWeights.SEMIBOLD}>
            Updating Contract
          </ModalTitle>
          {isUpdating ? null : (
            <AlertCard>
              <img src={alertIcon} alt="warning" width={32} height={32} />
              <CardHint>
                <Typography size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
                  Please note
                </Typography>
                <Typography size={FontSizes.TX_MD} weight={FontWeights.REGULAR}>
                  Currently, only salary and job title can be updated.
                </Typography>
              </CardHint>
            </AlertCard>
          )}
        </ModalContentHeader>

        <ModalContent>
          <Content>
            <InputField
              label={`${employee.contract?.salary_type} Salary in ${employee.contract?.currency}`}
              placeholder='Salary'
              value={formData.salary ?? ""}
              onChange={(e) => {
                setFormData({ ...formData, salary: parseFloat(e.target.value) });
              }}
              error={formErrors.new_salary || formErrors.salary}
              onFocus={() => {
                setFormErrors((prevData) => ({
                  ...prevData,
                  new_salary: "",
                  salary: "",
                }));
              }}
              type="number"
            />
            {isUpdating && (
              <DropdownList
                label="Department"
                error={formErrors.department}
                options={departmentOptions}
                selectedOption={formData.department}
                setSelectedOption={(option) => {
                  setFormErrors((prevData) => ({
                    ...prevData,
                    department: "",
                  }));
                  setFormData({ ...formData, department: option });
                }}
              />
            )}
            {jobTitleOptions.length > 0 ? (
              <DropdownList
                label="Job title"
                error={formErrors.title}
                options={jobTitleOptions}
                selectedOption={formData.title}
                setSelectedOption={(option) => {
                  setFormErrors((prevData) => ({
                    ...prevData,
                    title: "",
                  }));
                  setFormData({ ...formData, title: option });
                }}
              />
            ) : (
              <InputField
                label="Job title"
                value={formData.titleInputFieldText}
                onChange={(e) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    titleInputFieldText: e.target.value
                  }));
                }}
                error={formErrors.title}
                onFocus={() => {
                  setFormErrors((prevData) => ({
                    ...prevData,
                    title: "",
                  }));
                }}
                type="text"
              />
            )}
            {isUpdating ? (
              <>
                <DropdownList
                  label="Employment Terms"
                  options={EMPLOYMENT_TERMS}
                  selectedOption={formData.employmentTerms}
                  error={formErrors.employment_terms}
                  setSelectedOption={(option) => {
                    setFormErrors((prevData) => ({
                      ...prevData,
                      employment_terms: "",
                    }));
                    setFormData({ ...formData, employmentTerms: option });
                  }}
                />
                <InputField
                  label="Hours Per Week"
                  value={formData.hoursPerWeek}
                  onChange={(e) => {
                    setFormData({ ...formData, hoursPerWeek: parseFloat(e.target.value)});
                  }}
                  error={formErrors.hours_per_week}
                  onFocus={() => {
                    setFormErrors((prevData) => ({
                      ...prevData,
                      hours_per_week: "",
                    }));
                  }}
                  type="number"
                />
                <InputField
                  label="Start Date"
                  value={formData.startDate}
                  onChange={(e) => {
                    setFormData({ ...formData, startDate: e.target.value });
                  }}
                  error={formErrors.start_date}
                  onFocus={() => {
                    setFormErrors((prevData) => ({
                      ...prevData,
                      start_date: "",
                    }));
                  }}
                  type="date"
                />
              </>
            ) : (
              <InputField
                label="Effective Date of Changes"
                value={formData.effectiveDate}
                onChange={(e) => {
                  setFormData({ ...formData, effectiveDate: e.target.value });
                }}
                error={formErrors.effective_date}
                onFocus={() => {
                  setFormErrors((prevData) => ({
                    ...prevData,
                    effective_date: "",
                  }));
                }}
                type="month"
                min={new Date().toISOString().slice(0, 7)}
              />
            )}
          </Content>
        </ModalContent>

        <ModalFooter>
          <Button
            text="Cancel"
            variant={ButtonVariants.secondary_gray}
            size={ButtonSizes.lg}
            onClick={() => onClose()}
          />
          <Button
            text="Submit"
            variant={ButtonVariants.primary}
            size={ButtonSizes.lg}
            onClick={() => handleSubmit()}
            disabled={isDisabled()}
          />
        </ModalFooter>
      </ModalContentContainer>
    </VerticalModal>
  ) : null;
};

export default EditContractModal;