import { useParams } from "react-router-dom";
import {
  PaymentDetailsHeader,
  PaymentDetailsSubtitle,
  PaymentDetailsTitle,
} from "./styled";
import { FontSizes, FontWeights } from "../../../constants/styles";
import Tabs from "../../shared/Tabs";
import { useState } from "react";
import DetailsTab from "./DetailsTab";
import InvoicesTab from "./InvoicesTab";
import { useGetPaymentDetails } from "../../../queries/payment";
import { useAppSelector } from "../../../redux/reduxHooks";

const PaymentDetails = () => {
  const { activeCompany } = useAppSelector((state) => state.companies)
  const { payment_public_id } = useParams();
  const [activeTab, setActiveTab] = useState(0);

  const { data: payment } = useGetPaymentDetails(payment_public_id);


  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return <DetailsTab />;
      case 1:
        return <InvoicesTab />;
      default:
        return null;
    }
  };
  return (
    <>
      <PaymentDetailsHeader>
        <PaymentDetailsTitle size={FontSizes.SM} weight={FontWeights.SEMIBOLD}>
          {payment?.public_id}
        </PaymentDetailsTitle>
        <PaymentDetailsSubtitle
          size={FontSizes.TX_MD}
          weight={FontWeights.REGULAR}
        >
          {activeCompany?.name}
        </PaymentDetailsSubtitle>
        <Tabs
          tabsTitles={["Payment Details", "Invoices"]}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </PaymentDetailsHeader>
      {renderTabContent()}
    </>
  );
};

export default PaymentDetails;
