export enum Breakpoints {
  tablet = "768px",
  mobile = "375px",
}

export enum FontSizes {
  XXL = "3.375rem",
  XL = "3rem",
  LG = "2.25rem",
  MD = "1.875rem",
  SM = "1.5rem",
  XS = "1.25rem",
  TX_XL = "1.125rem",
  TX_LG = "1rem",
  TX_MD = "0.875rem",
  TX_SM = "0.75rem",
  TX_XS = "0.625rem",
}

export enum FontWeights {
  REGULAR = "400",
  MEDIUM = "500",
  SEMIBOLD = "600",
  BOLD = "700",
}

export const enum Colors {
  fg_disabled = "#98A2B3",
  fg_senary = "#D0D5DD",
  fg_warning_secondary = "#F79009",
  fg_brand_secondary_500 = "#033B6E",
  fg_brand_primary_alt = "#033462",
  fg_warning_primary = "#DC6803",

  // TEXT
  text_white = "#FFF",
  text_primary = "#101828",
  text_tertiary_600 = "#475467",
  text_tertiary = "#94969C",
  text_primary_on_brand = "#FFF",
  text_tertiary_hover = "#344054",

  text_secondary = "#CECFD2",
  text_secondary_700 = "#344054",
  text_secondary_hover = "#182230",
  text_secondary_on_brand = "#2191F9",
  text_placeholder = "#667085",
  text_placeholder_subtle = "#D0D5DD",

  text_quarterary_500 = "#667085",

  text_disabled = "#667085",
  text_error_primary = "#D92D20",
  text_brand_primary = "#022342",

  text_warning_primary_600 = " #DC6803",

  text_quarterary_on_brand = "#0675DC",

  // BUTTON
  button_tertiary_color_fg = "#022E58",
  button_tertiary_color_fg_hover = "#344054",
  button_primary_bg = "#033462",
  button_primary_hover = "#022E58",
  button_primary_fg = "#FFF",
  button_secondary_bg = "#FFF",
  button_secondary_fg = "#344054",
  button_secondary_border = "#D0D5DD",
  button_secondary_border_hover = "#D0D5DD",
  button_secondary_bg_hover = "#F9FAFB",
  button_secondary_color_fg = "#022E58",
  button_secondary_color_border = "#0675DC",
  button_secondary_color_bg_hover = "#90C8FC",
  button_tertiary_color_bg_hover = "#90C8FC",
  button_tertiary_error_fg = "#B42318",
  // BORDER
  border_primary = "#D0D5DD",
  border_brand = "#0675dc",
  border_brand_solid = "#033462",
  border_disabled = "#D0D5DD",
  border_disabled_subtle = "#EAECF0",
  border_error = "#FDA29B",
  border_secondary = "#EAECF0",

  // BG
  bg_disabled_subtle = "#F9FAFB",
  bg_brand_solid = "#033462",
  bg_brand_section = "#02294D",
  bg_primary = "#FFF",
  bg_primary_hover = "#F9FAFB",
  bg_disabled = "#F2F4F7",
  bg_secondary = "#F9FAFB",
  bg_secondary_hover = "#F2F4F7",
  bg_brand_secondary = "#59ACFB",
  bg_active = "#F9FAFB",
  bg_tertiary = "#F2F4F7",
  bg_overlay = "#0C111D",
  bg_warning_secondary = "#FEF0C7",
  bg_warning_primary = " #FFFAEB",
  bg_error_secondary = "#FEE4E2",

  utility_gray_blue_100 = "#EAECF5",
  utility_gray_blue_500 = "#4E5BA6",
  utility_gray_blue_50 = "#F8F9FC",
  utility_gray_blue_200 = "#D5D9EB",
  utility_gray_blue_700 = "##363F72",
  utility_gray_50 = "#F9FAFB",
  utility_gray_200 = "#EAECF0",
  utility_gray_500 = "#667085",
  utility_gray_700 = "#344054",
  utility_warning_50 = "#FFFAEB",
  utility_warning_200 = "#FEDF89",
  utility_warning_500 = "#F79009",
  utility_warning_700 = " #B54708",
  utility_blue_700 = "#175CD3",
  utility_blue_200 = " #B2DDFF",
  utility_blue_50 = "#EFF8FF",
  utility_blue_500 = "#0BA5EC",
  utility_blue_light_100 = "#E0F2FE",
  utility_brand_500 = "#2E90FA",
  Blue_light_50 = "#F0F9FF",
  Blue_light_200 = "#B9E6FE",
  utility_success_500 = "#17B26A",
  utility_success_200 = "#ABEFC6",
  utility_success_50 = "#ECFDF3",
  utility_success_700 = "#067647",
  utility_error_500 = "#F04438",
  utility_error_200 = "#FECDCA",
  utility_error_50 = "#FEF3F2",
  utility_error_700 = "#B42318",
  utility_brand_50 = "#90C8FC",
  utility_brand_200 = "#2191F9",
  utility_brand_700 = "#022E58",

  utility_orange_500 = "#EF6820",
  utility_orange_200 = "#F9DBAF",
  utility_orange_50 = "#FEF6EE",
  utility_orange_700 = "#B93815",

  utility_purple_500 = "#7A5AF8",
  utility_purple_200 = "#D9D6FE",
  utility_purple_50 = "#F4F3FF",
  utility_purple_700 = "#5925DC",

  utility_indigo_500 = "#6172F3",
  utility_indigo_200 = "#C7D7FE",
  utility_indigo_50 = "#EEF4FF",
  utility_indigo_700 = "#3538CD",
  utility_light_blue_700 = "#026AA2",

  Warning_25 = "#FFFCF5",

  Error_25 = "#FFFBFA",

  gray_Dar_mode_600 = "#61646C",

  Green_light_25 = "#FAFEF5",
  Brand_500 = "#033B6E",
  Brand_50 = "#90C8FC",
  gray_dark_mode_400 = "#94969C",
  utility_error_100 = "#FEE4E2",
  avatar_bg = "#F2F4F7",
  breadcrumb_fg = "#475467",
  breadcrumb_fg_hover = "#344054",
  success_solid = "#079455",
  error_fg_hover = "##912018",
  fg_white = "#FFFFFF",
}

export const enum Spaces {
  spacing_none = "0rem",
  spacing_2xs = "0.125rem",
  spacing_xs = "0.25rem",
  spacing_sm = "0.375rem",
  spacing_md = "0.5rem",
  spacing_lg = "0.75rem",
  spacing_xl = "1rem",
  spacing_2xl = "1.25rem",
  spacing_3xl = "1.5rem",
  spacing_4xl = "2rem",
  spacing_5xl = "2.5rem",
  spacing_6xl = "3rem",
  spacing_7xl = "4rem",
  spacing_8xl = "5rem",
  spacing_9xl = "6rem",
  spacing_10xl = "8rem",
  spacing_11xl = "10rem",
}

export const enum Radiuses {
  none = "0rem",
  radius_xxs = "0.125rem",
  radius_xs = "0.25rem",
  radius_sm = "0.375rem",
  radius_md = "0.5rem",
  radius_lg = "0.625rem",
  radius_xl = "0.75rem",
  radius_2xl = "1rem",
  radius_3xl = "1.25rem",
  radius_4xl = "1.5rem",
  radius_full = "9999px",
}

export enum ButtonSizes {
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
  xxl = "xxl",
}

export enum ButtonVariants {
  primary = "primary",
  secondary_color = "secondary_color",
  secondary_gray = "secondary_gray",
  tertiary_gray = "tertiary_gray",
  tertiary_color = "tertiary_color",
  link_gray = "link_gray",
  link_color = "link_color",
  destructive_primary = "destructive_primary",
  destructive_secondary = "destructive_secondary",
}

export enum LoaderVariants {
  primary = "primary",
  secondary = "secondary",
}

export enum LoaderSizes {
  sm = "sm",
  md = "md",
  lg = "lg",
}
