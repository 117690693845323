import { useEffect, useState } from 'react';
import { LeaveManagementContainer, Title } from './styled';
import { FontSizes, FontWeights } from '../../constants/styles';
import LeaveTable from '../../components/leave/LeaveTable';
import { useAppDispatch, useAppSelector } from '../../redux/reduxHooks';
import { Outlet, useLocation } from 'react-router-dom';
import { useSnackbar } from '../../hooks/useSnackbar';
import { SnackbarTypes } from '../../types/componentsProps';
import { useGetEmployeeLeaves } from '../../queries/people';
import { setEmployeesLeavesTableVairables } from '../../redux/slices/peopleSlice'

const LeaveScreen = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const {
    sortDirection,
    sortKey,
    search,
    currentPage,
    filteredEmployees
  } = useAppSelector((state) => state.people.employees_leaves_table_variables)

  const [isOutlet, setIsOutlet] = useState(false);
  const { showSnackbar } = useSnackbar();

  const activeCompany = useAppSelector((state) => state.companies.activeCompany);
  const { data: employeesData, error } = useGetEmployeeLeaves({
    company_id: activeCompany?.id,
    sort: sortKey,
    sort_dir: sortDirection,
    search: search,
    page: currentPage
  });

  useEffect(() => {
    if(error) {
      showSnackbar({
        type: SnackbarTypes.ERROR,
        open: true,
        title: "Something went wrong while fetching employees.",
        description: "Please try again later.",
      });
    }
  }, [error])

  useEffect(() => {
    if(employeesData?.employees) {
      dispatch(
        setEmployeesLeavesTableVairables({
          filteredEmployees: employeesData.employees,
          currentPage: employeesData._metadata.page,
          totalRecords: employeesData._metadata.total_records,
          totalPages: employeesData._metadata.last_page,
        })
      );
    }
  }, [employeesData])

  useEffect(() => {
    if (
      location.pathname.includes("leaves")
    ) {
      setIsOutlet(true);
    } else {
      setIsOutlet(false);
    }
  }, [location.pathname]);

  return (
    <LeaveManagementContainer>
      {isOutlet ? (
        <Outlet />
      ) : (
        <>
          <Title size={FontSizes.SM} weight={FontWeights.SEMIBOLD}>
            Leave Management
          </Title>
          {filteredEmployees && <LeaveTable />}
        </>
      )}
    </LeaveManagementContainer>
  )
}

export default LeaveScreen;
