import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../constants/styles";
import Button from "../Button";
import { ArrowIcon, ButtonsContainer, Page, PagesContainer, PaginationContainer } from "./styled";
import ArrowLeft from "../../../Images/icons/arrow-left.svg";
import ArrowRight from "../../../Images/icons/arrow-right.svg";
import JumpStart from "../../../Images/icons/jumpStart.svg";
import JumpLast from "../../../Images/icons/jumpLast.svg";
import Typography from "../Typography";

const TablePagination = ({
  currentPage,
  setCurrentPage,
  totalPages,
}: {
  currentPage: number;
  setCurrentPage: (pageNumber: number) => void;
  totalPages: number;
}) => {
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  
  const calculateVisiblePages = () => {
    const pagesToShow = 6
    let start = Math.max(1, currentPage - Math.floor((pagesToShow - 1) / 2));
    let end = start + pagesToShow - 1;

    if (end > totalPages) {
      end = totalPages;
      start = Math.max(1, end - pagesToShow + 1);
    }
    return Array.from({ length: end - start + 1 }, (_, idx) => start + idx);
  };
  
  
    const visiblePages = calculateVisiblePages();

  return (
    <PaginationContainer>
      <ButtonsContainer>
        <Button
          disabled={currentPage === 1}
          onClick={() => handlePageChange(1)}
          text=""
          variant={ButtonVariants.secondary_gray}
          size={ButtonSizes.sm}
          iconLeading={
            <ArrowIcon
              disabled={currentPage === 1}
              src={JumpStart}
              alt="Jump Start"
            />
          }
        />
        <Button
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
          text="Previous"
          variant={ButtonVariants.secondary_gray}
          size={ButtonSizes.sm}
          iconLeading={
            <ArrowIcon
              disabled={currentPage === 1}
              src={ArrowLeft}
              alt="Arrow Left"
            />
          }
        />
      </ButtonsContainer>
      <PagesContainer>{visiblePages.map((pageIndex) => (
        <Page
          key={pageIndex}
          onClick={() => handlePageChange(pageIndex)}
          active={currentPage === pageIndex}
        >
          <Typography size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
            {pageIndex}
          </Typography>
        </Page>
      ))}</PagesContainer>
      <ButtonsContainer>
        <Button
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
          text="Next"
          variant={ButtonVariants.secondary_gray}
          size={ButtonSizes.sm}
          iconTrailing={
            <ArrowIcon
              disabled={currentPage === totalPages}
              src={ArrowRight}
              alt="Arrow Right"
            />
          }
        />
        <Button
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(totalPages)}
          text=""
          variant={ButtonVariants.secondary_gray}
          size={ButtonSizes.sm}
          iconTrailing={
            <ArrowIcon
              disabled={currentPage === totalPages}
              src={JumpLast}
              alt="Jump Last"
            />
          }
        />

      </ButtonsContainer>
    </PaginationContainer>
  );
};

export default TablePagination;
