import { useMutation, useQuery } from "@tanstack/react-query";
import { apiClient } from "./apiClient";
import qs from "qs";
import {
  CreatePaymentParams,
  GetInvoicesDueListResponse,
  GetInvoicesListParams,
  PendingPaymentsDashboardResponse,
  GetPaymentListParams,
  GetPaymentListResponse,
  GetInternalBankAccount,
} from "../types/apisTypes";
import { Balance, Invoice, UserPayment } from "../types/payments";

export const useGetInternalBankAccount = (currency: string | undefined) => {
  return useQuery<GetInternalBankAccount, Error>({
    queryFn: async () => {
      const response = await apiClient.get(
        `/internal_bank_account/?currency=${currency}`
      );
      return response.data.internal_bank_account;
    },
    retry: false,
    enabled: !!currency,
    queryKey: ["currency", currency],
  });
};

export const useGetInvoicesList = (params: GetInvoicesListParams) => {
  return useQuery<GetInvoicesDueListResponse, Error>({
    queryFn: async () => {
      const response = await apiClient.get(
        `/invoices_due?${qs.stringify(params)}`
      );
      return response.data;
    },
    retry: false,
    enabled: !!params.company_id,

    queryKey: ["payments", params],
  });
};

export const useGetInvoiceDetails = (invoiceId: string | undefined) => {
  return useQuery<Invoice, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/invoice_details/${invoiceId}`);
      return response.data.invoice;
    },
    retry: false,
    queryKey: ["invoices", invoiceId],
  });
};

export const useDownloadInvoice = ({
  invoiceId,
  enabled,
}: {
  invoiceId: string | undefined;
  enabled: boolean;
}) => {
  return useQuery<ArrayBuffer, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/invoice_download/${invoiceId}`, {
        responseType: "arraybuffer",
      });
      return response.data;
    },
    retry: false,
    queryKey: ["invoiceURL", invoiceId],
    enabled: !!invoiceId && enabled,
  });
};

export const useGetCurrentBalance = (currency: string | undefined) => {
  return useQuery<Balance, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/current_balance/${currency}`);
      return response.data.balance;
    },
    retry: false,
    queryKey: ["currentBalance", currency],
    enabled: !!currency,
  });
};
export const useCreatePayment = (params: CreatePaymentParams) => {
  return useMutation<void, Error>({
    mutationFn: async () => {
      await apiClient.post("/payment", params);
    },
  });
};

export const useGetAllPayments = (params: GetPaymentListParams) => {
  return useQuery<GetPaymentListResponse, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/payment?${qs.stringify(params)}`);
      return response.data;
    },
    retry: false,
    queryKey: ["paymentsList", params],
    enabled: !!params.company_id,
  });
};

export const useGetPaymentDetails = (paymentId: string | undefined) => {
  return useQuery<UserPayment, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/payment_details/${paymentId}`);
      return response.data.payment;
    },
    retry: false,
    queryKey: ["paymentsList", paymentId],
    enabled: !!paymentId,
  });
};

export const useGetPendingPaymentsDashboard = (companyId: number | undefined) => {
  return useQuery<PendingPaymentsDashboardResponse, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/dashboard/payment/pending?company_id=${companyId}`);
      return response.data;
    },
    retry: false,
    queryKey: ["pendingPaymentsDashboard", companyId],
    enabled: !!companyId
  });
};
