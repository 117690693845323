import styled from "styled-components";
import { Colors, Spaces } from "../../../constants/styles";
import Typography from "../../shared/Typography";
import { CardTitle } from "../../shared/Card";

export const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Spaces.spacing_3xl};
`;

export const ModalContentHeader = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: ${Spaces.spacing_3xl};
`;

export const ModalContentTitleAndImgContainer = styled.div`
  display: flex;
  gap: ${Spaces.spacing_xl};
  width: 100%;
  align-items: start;
`;

export const ModalContentTitle = styled(Typography)`
  color: ${Colors.text_primary};
`;

export const ProfileLink = styled.a`
  display: flex;
  gap: ${Spaces.spacing_sm};
  text-decoration: none;
  color: ${Colors.text_quarterary_on_brand};
`;

export const CloseIcon = styled.img`
  margin-left: auto;
  cursor: pointer;
`;

export const ReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_3xl};
  width: 100%;
`;

export const AddBonusButton = styled.div`
  color: ${Colors.text_quarterary_on_brand};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${Spaces.spacing_xs};
`;

export const AddReductionButton = styled.div`
  color: ${Colors.text_quarterary_on_brand};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${Spaces.spacing_xs};
`;

export const ModalFooter = styled.div`
  bottom: ${Spaces.spacing_xl};
  display: flex;
  justify-content: end;
  padding-block: ${Spaces.spacing_xl};
  padding-inline: ${Spaces.spacing_3xl};
  width: 100%;
  border-top: 1px solid ${Colors.border_secondary};
  gap: ${Spaces.spacing_lg};
  margin-inline: -${Spaces.spacing_2xl};
  background-color: ${Colors.bg_primary};
`;

export const ModalCardTitle = styled(CardTitle)`
  margin-bottom: 0px;
`;
