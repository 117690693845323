import styled from "styled-components";
import { Colors, Spaces } from "../../../constants/styles";
import Typography from "../../shared/Typography";

export const NewEmployeeScreenContainer = styled.div`
  width: 100%;
  background-color: ${Colors.bg_active};
`;

export const NewEmployeeHeader = styled.div`
  width: 100%;
  padding-bottom: ${Spaces.spacing_2xl};
  border-bottom: 1px solid ${Colors.border_secondary};
`;

export const PageTitle = styled(Typography)`
  color: ${Colors.text_primary};
  margin-bottom: ${Spaces.spacing_xs};
`;

export const PageSubtitle = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;
export const FormContainer = styled.div`
  margin-top: ${Spaces.spacing_4xl};
  width: 440px;
`;
export const FormTitle = styled(Typography)`
  color: ${Colors.text_primary};
  margin-bottom: ${Spaces.spacing_5xl};
`;

export const FormFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_xl};
`;

export const FormRow = styled.div`
  display: flex;
  width: 100%;
  gap: ${Spaces.spacing_md};
  justify-content: space-between;
  flex-wrap: wrap;
`;
export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: ${Spaces.spacing_xl};
  margin-block: ${Spaces.spacing_2xl};
`;
