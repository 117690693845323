import React, { useEffect, useState } from "react";
import { Company } from "../../../../types/companies";
import Modal from "../../../shared/Modal";
import {
  CompanyDetailsLabel,
  CompanyDetailsRow,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTitleAndIcon,
  ModalSubtitle,
  DataContainer,
  RadioGroup,
} from "./styled";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import CloseIcon from "../../../../Images/icons/alertClose.svg";
import CompanyDetailsIcon from "../../../../Images/icons/companyDetailsIcon.svg";
import Button from "../../../shared/Button";
import { PhoneCodes } from "../../../../constants/registration";
import { CORPORATION_TYPE } from "../../../../constants/dropDownLists";
import DropdownList from "../../../shared/DropdownList";
import InputField from "../../../shared/InputField";
import PartialDropdown from "../../../shared/PartialDropdown";
import {
  DropdownListOption,
  SnackbarTypes,
} from "../../../../types/componentsProps";
import Checkbox from "../../../shared/Checkbox";
import { useCreateCompany } from "../../../../queries/companies";
import { useAppDispatch, useAppSelector } from "../../../../redux/reduxHooks";
import { useGetCountries, useGetUserData } from "../../../../queries/auth";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import { createCountriesDropdownOptions } from "../../../../redux/slices/appState";
import { selectToken } from "../../../../redux/slices/authSlice";

interface AddCompanyModalProps {
  company: Company | null;
  onClose: () => void;
  open: boolean;
}

const AddCompanyModal: React.FC<AddCompanyModalProps> = ({ onClose, open }) => {
  const { showSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state.auth);
  const { countriesOptions } = useAppSelector((state) => state.appState);

  const { mutateAsync: createCompany } = useCreateCompany();
  const { data: countries, error: countriesError } = useGetCountries();

  const queryClient = useQueryClient();

  const initialValidationErrors = {
    phone: "",
  }

  const emptyForm = {
    country: { label: "", value: 0 },
    legal_name: "",
    corporation_type: CORPORATION_TYPE[0],
    phone: "",
    phoneCode: PhoneCodes[0],
    industry: "",
    established: false,
    tax_id: "",
    registration_number: "",
    billing_information: {
      name: "",
      country: { label: "", value: 1 },
      state: "",
      city: "",
      street: "",
      zip: "",
    }
  }

  const [formData, setFormData] = useState(emptyForm);
  const [ validationErrors, setValidaitonErrors ] = useState(initialValidationErrors);
  const [country, setCountry] = useState(1);

  useEffect(() => {
    if (user?.organization && countriesOptions) {
      setCountry(user?.organization.country - 1);
      setFormData((prev) => ({
        ...prev,
        country: countriesOptions[user?.organization.country - 1],
        billing_information: {
          ...prev.billing_information,
          country: countriesOptions[user?.organization.country - 1]
        }
      }));
    }
  }, [user, countriesOptions]);

  useEffect(() => {
    if (countriesError) {
      showSnackbar({
        type: SnackbarTypes.ERROR,
        open: true,
        title: "Something went wrong while fetching data.",
        description: "Please try again later.",
      });
    }
  }, [countriesError]);

  useEffect(() => {
    if (countries) {
      dispatch(createCountriesDropdownOptions(countries));
    }
  }, [countries]);

  const handleClose = () => {
    setFormData({
      ...emptyForm,
      country: countriesOptions[country],
      billing_information: {
        ...emptyForm.billing_information,
        country: countriesOptions[country]
      }
    });
    setValidaitonErrors(initialValidationErrors)
    onClose();
  }

  const onCreateCompany = async () => {
    try {
      await createCompany({
        country: formData.country.value,
        legal_company_name: formData.legal_name,
        corporation_type: formData.corporation_type.value,
        phone: formData.phoneCode.value + formData.phone,
        industry: formData.industry,
        established: formData.established,
        tax_id: formData.tax_id,
        registration_number: formData.registration_number,
        billing_information: {...formData.billing_information,country: formData.billing_information.country.value}
      });
      queryClient.invalidateQueries({ queryKey: ["AllCompanies"] });
      handleClose();
      showSnackbar({
        type: SnackbarTypes.SUCCESS,
        open: true,
        title: "Success",
        description: "Successfully added company",
      });
    } catch (e: any) {
      if(e.response.data.errors){
        const phoneError: boolean = e.response.data.errors.some((err: string) => err.includes("phone"));
        if(phoneError) {
          setValidaitonErrors({phone: "Phone is invalid"});
        }
      }
      else {
        handleClose();
        showSnackbar({
          type: SnackbarTypes.ERROR,
          open: true,
          title: "Something went wrong while creating company. " + e.response.data.message,
          description: "Please try again later.",
        });
      }
    }
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <ModalContainer>
        <ModalHeader>
          <ModalTitleAndIcon>
            <img
              src={CompanyDetailsIcon}
              alt="company-details-icon"
              width={48}
              height={48}
            />
            <div>
              {" "}
              <ModalTitle size={FontSizes.TX_LG} weight={FontWeights.SEMIBOLD}>
                Add Company details
              </ModalTitle>
              <ModalSubtitle
                size={FontSizes.TX_SM}
                weight={FontWeights.REGULAR}
              >
                Create your company profile for free in less than 5 minutes.
              </ModalSubtitle>
            </div>
          </ModalTitleAndIcon>

          <img src={CloseIcon} alt="close-icon" onClick={handleClose} />
        </ModalHeader>
        <DataContainer>
        <CompanyDetailsLabel
              size={FontSizes.TX_SM}
              weight={FontWeights.BOLD}
            >
              Basic Information
        </CompanyDetailsLabel>
        <CompanyDetailsRow>
            <CompanyDetailsLabel
              size={FontSizes.TX_SM}
              weight={FontWeights.MEDIUM}
            >
              Country
            </CompanyDetailsLabel>
            <DropdownList
              options={countriesOptions}
              searchOption={true}
              selectedOption={formData.country}
              setSelectedOption={(option) =>
                setFormData({ ...formData, country: option })
              }
            />
          </CompanyDetailsRow>
          <CompanyDetailsRow>
            <CompanyDetailsLabel
              size={FontSizes.TX_SM}
              weight={FontWeights.MEDIUM}
            >
              Legal Company Name
            </CompanyDetailsLabel>
            <InputField
              value={formData.legal_name}
              onChange={(e) =>
                setFormData({ ...formData, legal_name: e.target.value })
              }
              containerStyle={{ width: "100%" }}
            />
          </CompanyDetailsRow>
          <CompanyDetailsRow>
            <CompanyDetailsLabel
              size={FontSizes.TX_SM}
              weight={FontWeights.MEDIUM}
            >
              Company Type
            </CompanyDetailsLabel>
            <DropdownList
              options={CORPORATION_TYPE}
              selectedOption={formData.corporation_type}
              setSelectedOption={(option) =>
                setFormData({ ...formData, corporation_type: option })
              }
            />
          </CompanyDetailsRow>

          <CompanyDetailsRow>
            <CompanyDetailsLabel
              size={FontSizes.TX_SM}
              weight={FontWeights.MEDIUM}
            >
              Phone
            </CompanyDetailsLabel>
            <PartialDropdown
              value={formData.phone}
              onChange={(e) => {
                setFormData({ ...formData, phone: e.target.value });
              }}
              onKeyDown={(e) => {
                if (e.key === "e" || e.key === ".") {
                  e.preventDefault();
                }
              }}
              error={validationErrors.phone}
              options={PhoneCodes}
              setSelectedOption={(option: DropdownListOption) => {
                setFormData({ ...formData, phoneCode: option });
              }}
              selectedOption={formData.phoneCode}
              type="number"
            />
          </CompanyDetailsRow>
          <CompanyDetailsRow>
            <CompanyDetailsLabel
              size={FontSizes.TX_SM}
              weight={FontWeights.MEDIUM}
            >
              Industry
            </CompanyDetailsLabel>
            <InputField
              value={formData.industry}
              onChange={(e) =>
                setFormData({ ...formData, industry: e.target.value })
              }
              containerStyle={{ width: "100%" }}
            />
          </CompanyDetailsRow>
          <CompanyDetailsLabel
              size={FontSizes.TX_SM}
              weight={FontWeights.BOLD}
            >
              Billing Information
          </CompanyDetailsLabel>
          <CompanyDetailsRow>
            <CompanyDetailsLabel
              size={FontSizes.TX_SM}
              weight={FontWeights.MEDIUM}
            >
              Name
            </CompanyDetailsLabel>
            <InputField
              value={formData.billing_information.name}
              onChange={(e) =>
                setFormData({ ...formData, billing_information: { ...formData.billing_information, name: e.target.value }})
              }
              containerStyle={{ width: "100%" }}
            />
          </CompanyDetailsRow>
          <CompanyDetailsRow>
            <CompanyDetailsLabel
              size={FontSizes.TX_SM}
              weight={FontWeights.MEDIUM}
            >
              Country
            </CompanyDetailsLabel>
            <DropdownList
              options={countriesOptions}
              searchOption={true}
              selectedOption={formData.billing_information.country}
              setSelectedOption={(option) =>
                setFormData({ ...formData, billing_information: { ...formData.billing_information, country: option }})
              }
            />
          </CompanyDetailsRow>
          <CompanyDetailsRow>
            <CompanyDetailsLabel
              size={FontSizes.TX_SM}
              weight={FontWeights.MEDIUM}
            >
              Street
            </CompanyDetailsLabel>
            <InputField
              value={formData.billing_information.street}
              onChange={(e) =>
                setFormData({ ...formData, billing_information: { ...formData.billing_information, street: e.target.value }})
              }
              containerStyle={{ width: "100%" }}
            />
          </CompanyDetailsRow>
          <CompanyDetailsRow>
            <CompanyDetailsLabel
              size={FontSizes.TX_SM}
              weight={FontWeights.MEDIUM}
            >
              City
            </CompanyDetailsLabel>
            <InputField
              value={formData.billing_information.city}
              onChange={(e) =>
                setFormData({ ...formData, billing_information: { ...formData.billing_information, city: e.target.value }})
              }
              containerStyle={{ width: "100%" }}
            />
          </CompanyDetailsRow>
          <CompanyDetailsRow>
            <CompanyDetailsLabel
              size={FontSizes.TX_SM}
              weight={FontWeights.MEDIUM}
            >
              State
            </CompanyDetailsLabel>
            <InputField
              value={formData.billing_information.state}
              onChange={(e) =>
                setFormData({ ...formData, billing_information: { ...formData.billing_information, state: e.target.value }})
              }
              containerStyle={{ width: "100%" }}
            />
          </CompanyDetailsRow>
          <CompanyDetailsRow>
            <CompanyDetailsLabel
              size={FontSizes.TX_SM}
              weight={FontWeights.MEDIUM}
            >
              ZIP
            </CompanyDetailsLabel>
            <InputField
              value={formData.billing_information.zip}
              onChange={(e) =>
                setFormData({ ...formData, billing_information: { ...formData.billing_information, zip: e.target.value }})
              }
              containerStyle={{ width: "100%" }}
            />
          </CompanyDetailsRow>
          <CompanyDetailsRow>
            <CompanyDetailsLabel
              size={FontSizes.TX_SM}
              weight={FontWeights.MEDIUM}
            >
              Is it established?
            </CompanyDetailsLabel>
            <RadioGroup>
              <Checkbox
                label={"Yes"}
                checked={formData.established}
                onChange={() => setFormData({ ...formData, established: true })}
                radio={true}
              />
              <Checkbox
                label={"No"}
                checked={!formData.established}
                onChange={() =>
                  setFormData({ ...formData, established: false })
                }
                radio={true}
              />
            </RadioGroup>
          </CompanyDetailsRow>
          {formData.established && (
            <>
              <CompanyDetailsRow>
                <CompanyDetailsLabel
                  size={FontSizes.TX_SM}
                  weight={FontWeights.MEDIUM}
                >
                  Tax ID
                </CompanyDetailsLabel>
                <InputField
                  value={formData.tax_id}
                  onChange={(e) =>
                    setFormData({ ...formData, tax_id: e.target.value })
                  }
                  containerStyle={{ width: "100%" }}
                />
              </CompanyDetailsRow>
              <CompanyDetailsRow>
                <CompanyDetailsLabel
                  size={FontSizes.TX_SM}
                  weight={FontWeights.MEDIUM}
                >
                  Registration Number
                </CompanyDetailsLabel>
                <InputField
                  value={formData.registration_number}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      registration_number: e.target.value,
                    })
                  }
                  containerStyle={{ width: "100%" }}
                />
              </CompanyDetailsRow>
            </>
          )}
        </DataContainer>

        <ModalFooter>
          <Button
            text="Add Company"
            variant={ButtonVariants.primary}
            size={ButtonSizes.lg}
            disabled={
              !formData.legal_name ||
              !formData.corporation_type ||
              !formData.phone ||
              !formData.industry ||
              (formData.established &&
                (!formData.tax_id || !formData.registration_number))
            }
            onClick={() => onCreateCompany()}
          />
        </ModalFooter>
      </ModalContainer>
    </Modal>
  );
};

export default AddCompanyModal;
