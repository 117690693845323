import { useEffect, useState } from "react";
import {
  GeneralDataContainer,
  GeneralDataLabel,
  GeneralDataRow,
  GeneralDataValue,
  DataContainer,
  DataContainerHeader,
  DataContainerTitle,
  OverviewContainer,
} from "./styled";
import { ButtonSizes, ButtonVariants, FontSizes, FontWeights } from "../../../../constants/styles";
import { useNavigate, useParams } from "react-router-dom";
import { useGetEmployee } from "../../../../queries/people";
import moment from "moment";
import ContractOverView from "./ContractOverView";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import { SnackbarTypes } from "../../../../types/componentsProps";
import Button from "../../../shared/Button";
import { PEOPLE_STATUS } from "../../../../types/people";
import EditEmployeeModal from "./EditEmployeeModal";

const Overview = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  
  const [ openUpdateModal, setOpenUpdateModal ] = useState(false);

  const { data: employee, error } = useGetEmployee(id);


  useEffect(() => {
    if (error) {
      showSnackbar({
        open: true,
        type: SnackbarTypes.ERROR,
        title: "Something went wrong while fetching employee data",
        description: "Please try again later",
        actionBtnText: "Go to Employees List",
        actionBtnCallback: () => {
          navigate("/people/manage");
        },
      });
    }
  }, [error]);

  const renderOverview = () => {
    return employee && (
      <DataContainer>
        <DataContainerHeader>
          <DataContainerTitle
            size={FontSizes.TX_MD}
            weight={FontWeights.SEMIBOLD}
          >
            General Information
          </DataContainerTitle>
          {(!employee.contract ||
          employee.contract?.status === PEOPLE_STATUS.pending_invite ||
          employee.contract?.status === PEOPLE_STATUS.pending_payment) && (
            <Button
              text="Update"
              variant={ButtonVariants.primary}
              size={ButtonSizes.md}
              onClick={() => setOpenUpdateModal(true)}
            />
          )}
        </DataContainerHeader>

        <GeneralDataRow>
          <GeneralDataLabel
            size={FontSizes.TX_SM}
            weight={FontWeights.SEMIBOLD}
          >
            First Name
          </GeneralDataLabel>
          <GeneralDataValue
            size={FontSizes.TX_SM}
            weight={FontWeights.SEMIBOLD}
          >
            {employee?.user.first_name}
          </GeneralDataValue>
        </GeneralDataRow>

        <GeneralDataRow>
          <GeneralDataLabel
            size={FontSizes.TX_SM}
            weight={FontWeights.SEMIBOLD}
          >
            Last Name
          </GeneralDataLabel>
          <GeneralDataValue
            size={FontSizes.TX_SM}
            weight={FontWeights.SEMIBOLD}
          >
            {employee?.user.last_name}
          </GeneralDataValue>
        </GeneralDataRow>
        {employee?.contract ? (
          <GeneralDataRow>
            <GeneralDataLabel
              size={FontSizes.TX_SM}
              weight={FontWeights.SEMIBOLD}
            >
              Start Date
            </GeneralDataLabel>
            <GeneralDataValue
              size={FontSizes.TX_SM}
              weight={FontWeights.SEMIBOLD}
            >
              {moment(employee.contract.start_date).format("DD, MMM YYYY")}
            </GeneralDataValue>
          </GeneralDataRow>
        ) : null}

        <GeneralDataRow>
          <GeneralDataLabel
            size={FontSizes.TX_SM}
            weight={FontWeights.SEMIBOLD}
          >
            Nationality
          </GeneralDataLabel>
          <GeneralDataValue
            size={FontSizes.TX_SM}
            weight={FontWeights.SEMIBOLD}
          >
            {employee?.nationality}
          </GeneralDataValue>
        </GeneralDataRow>
        <GeneralDataRow>
          <GeneralDataLabel
            size={FontSizes.TX_SM}
            weight={FontWeights.SEMIBOLD}
          >
            Work Email
          </GeneralDataLabel>
          <GeneralDataContainer>
            <GeneralDataValue
              size={FontSizes.TX_SM}
              weight={FontWeights.SEMIBOLD}
            >
              {employee?.user.email}
            </GeneralDataValue>
          </GeneralDataContainer>
        </GeneralDataRow>
        <GeneralDataRow>
          <GeneralDataLabel
            size={FontSizes.TX_SM}
            weight={FontWeights.SEMIBOLD}
          >
            Company
          </GeneralDataLabel>
          <GeneralDataValue
            size={FontSizes.TX_SM}
            weight={FontWeights.SEMIBOLD}
          >
            {employee?.company.name}
          </GeneralDataValue>
        </GeneralDataRow>
      </DataContainer>
    );
  };

  return (
    <OverviewContainer>
      {renderOverview()} <ContractOverView />
      {employee && (
        <EditEmployeeModal open={openUpdateModal} onClose={() => setOpenUpdateModal(false)} employee={employee} />
      )}
    </OverviewContainer>
  );
};

export default Overview;
