import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../constants/styles";

export const PaginationContainer = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-top: 1px solid ${Colors.border_secondary};
  padding-inline: ${Spaces.spacing_3xl};
  box-sizing: border-box;
  padding-block: ${Spaces.spacing_lg} ${Spaces.spacing_xl};
  height: 68px;
`;

export const ArrowIcon = styled.img<{ disabled: boolean }>`
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

export const Page = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
  color: ${Colors.text_secondary_hover};
  border-radius: ${Radiuses.radius_md};
  background-color: ${(props) =>
    props.active ? Colors.bg_primary_hover : "transparent"};
`;

export const PagesContainer = styled.div`
  width: fit-content;
  display: flex;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    gap: ${Spaces.spacing_2xl}
`;
