import { CardsColumn, CardsContainer, EmployeeLeavesContainer, EmployeeLeavesHeader, EmptyContainer, TextBold } from './styled';
import Typography from '../../shared/Typography';
import { FontSizes, FontWeights } from '../../../constants/styles';
import { useParams } from 'react-router-dom';
import { useGetEmployee } from '../../../queries/people';
import Loader from '../../shared/Loader';
import { Card } from '../../shared/Card';
import EmployeeLeavesCards from './EmployeeLeavesCards';
import EmployeeLeavesTable from './EmployeeLeavesTable';
import EmployeeLeavesPieCard from './EmployeeLeavesPieCard';
import { useEffect } from 'react';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { SnackbarTypes } from '../../../types/componentsProps';
import Badge from '../../shared/Badge';
import { PEOPLE_STATUS_BADGE_COLOR } from '../../../types/people';

const EmployeeLeaves = () => {
  const { showSnackbar } = useSnackbar();
  const { id } = useParams<{ id: string }>();
  const {data: employeeData, error} = useGetEmployee(id);

  useEffect(() => {
    if(error) {
      showSnackbar({
        type: SnackbarTypes.ERROR,
        open: true,
        title: "Something went wrong while fetching employee details.",
        description: "Please try again later.",
      });
    }
  }, [error]);
  
  return employeeData && employeeData.contract? (
    <EmployeeLeavesContainer>
      <EmployeeLeavesHeader>
        <Typography size={FontSizes.SM} weight={FontWeights.SEMIBOLD}>
          {`${employeeData.user.first_name} ${employeeData.user.last_name}`}
        </Typography>
        <Badge
          text={employeeData.contract.status}
          color={PEOPLE_STATUS_BADGE_COLOR[employeeData.contract.status]}
        />
      </EmployeeLeavesHeader>
      <EmployeeLeavesCards employeeData={employeeData} />
      <CardsContainer>
        {!employeeData.leaves?.length ? (
          <Card>
            <EmptyContainer>
              <TextBold size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
                Employee doesn't have any leaves
              </TextBold>
            </EmptyContainer>
          </Card>
        ) : (
          <>
            <CardsColumn>
              <EmployeeLeavesTable employeeData={employeeData} />
            </CardsColumn>
            <CardsColumn>
              <EmployeeLeavesPieCard employeeData={employeeData}/>
            </CardsColumn>
          </>
        )}
      </CardsContainer>
    </EmployeeLeavesContainer>
  ) : <Loader />
}

export default EmployeeLeaves;