
import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../../constants/styles";
import Typography from "../../../shared/Typography";

export const PieChartContainer = styled.div`
  display: flex;
  width: 100%;
  gap: ${Spaces.spacing_3xl};
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
`;

export const PieChartKeysContainer = styled.div`
  display: flex;
  gap: ${Spaces.spacing_md};
  justify-content: center;
  flex-wrap: wrap;
`;

export const PieChartKey = styled.div`
  display: flex;
  gap: ${Spaces.spacing_md};
  align-items: center;
`;

export const PieChartKeyColor = styled.div<{ color: string }>`
  width: 8px;
  height: 8px;
  border-radius: ${Radiuses.radius_full};
  background-color: ${(props) => props.color};
`;

export const LeavesTypeCardTitle = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;