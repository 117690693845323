import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../constants/styles";
import { useAppDispatch, useAppSelector } from "../../../redux/reduxHooks";
import Button from "../../shared/Button";
import {
  Table,
  TableCell,
  TableRow,
  TableRowDynamic,
  TableHeadWithIcon,
} from "../../shared/Table/styled";
import {
  InvoicesDueContainer,
  InvoicesDueHeader,
  InvoicesDueSelectedCounter,
  InvoicesDueTableContainer,
  TableBoldText,
  TableCustomHeader,
  TableFadedText,
} from "./styled";
import Checkbox from "../../shared/Checkbox";
import {
  selectSingleInvoice,
  setSelectedInvoices,
  setTotalPayment,
  updateInvoicesDueTableVariables,
} from "../../../redux/slices/paymentSlice";
import { useEffect } from "react";
import { currencyFormatter } from "../../../utilities/currencyFormatter";
import moment from "moment";
import { INVOICE_STATUS_BADGE_COLOR } from "../../../types/payments";
import Badge from "../../shared/Badge";
import { useNavigate } from "react-router-dom";
import { useGetInvoicesList } from "../../../queries/payment";
import TablePagination from "../../shared/TablePagination";
import { Sort } from "../../shared/SortIcon";

const InvoicesDue = () => {
  const { selected_invoices, invoices_due_tableVariables } = useAppSelector(
    (state) => state.payment
  );
  const { activeCompany } = useAppSelector((state) => state.companies);

  const { data } = useGetInvoicesList({
    sort: invoices_due_tableVariables.sortKey,
    sort_dir: invoices_due_tableVariables.sortDirection,
    company_id: activeCompany?.id,
    page: invoices_due_tableVariables.page,
    limit: invoices_due_tableVariables.limit,
  });

  useEffect(() => {
    if (data?._metadata) {
      dispatch(
        updateInvoicesDueTableVariables({
          page: data._metadata.page,
        })
      );
    }
  }, [data]);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setSelectedInvoices([]))
    return function() {
      dispatch(updateInvoicesDueTableVariables({
        sortDirection: "ASC",
        sortKey: "id",
        page: 1
      }));
    }
  }, [])

  const renderInvoices = () => {
    return data?.invoices?.map((invoice, index) => {
      return (
        <TableRow key={index}>
          <TableCell onClick={() => dispatch(selectSingleInvoice(invoice))}>
            <TableHeadWithIcon>
              <Checkbox
                label={""}
                checked={selected_invoices.includes(invoice)}
              />

              <TableBoldText size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                {invoice.public_id}
              </TableBoldText>
            </TableHeadWithIcon>
          </TableCell>
          <TableCell>
            <TableFadedText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {invoice.description}
            </TableFadedText>
          </TableCell>
          <TableCell>
            <Badge
              text={invoice.status}
              dot
              color={INVOICE_STATUS_BADGE_COLOR[invoice.status]}
            />
          </TableCell>
          <TableCell>
            <TableFadedText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {currencyFormatter(parseFloat(invoice.total), invoice.currency)}
            </TableFadedText>
          </TableCell>
          <TableCell>
            <TableFadedText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {moment(invoice.created_at).format("MMM DD, YYYY")}
            </TableFadedText>
          </TableCell>
          <TableCell>
            <TableFadedText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {moment(invoice.due_date).format("MMM DD, YYYY")}
            </TableFadedText>
          </TableCell>
          <TableCell>
            <Button
              variant={ButtonVariants.secondary_color}
              size={ButtonSizes.sm}
              text="View"
              onClick={() => {
                navigate(`/payment/invoice-details/${invoice.id}`);
              }}
            />
          </TableCell>
        </TableRow>
      );
    });
  };

  const renderTable = () => {
    return (
      <InvoicesDueTableContainer>
        <Table
          style={{
            position: "relative",
          }}
        >
          <TableCustomHeader height="20px">
            <TableCell
              onClick={(e) => {
                e.stopPropagation();
                dispatch(setSelectedInvoices(data?.invoices || []));
              }}
            >
              <TableHeadWithIcon>
                <Checkbox
                  label=""
                  checked={selected_invoices.length === data?.invoices?.length}
                />
                <TableFadedText
                  size={FontSizes.TX_XS}
                  weight={FontWeights.MEDIUM}
                >
                  Invoice number
                </TableFadedText>
                <Sort
                  currentSortKey={invoices_due_tableVariables.sortKey}
                  sortKey="id"
                  sortDirection={invoices_due_tableVariables.sortDirection}
                  onClick={(e: MouseEvent) => {
                    e.stopPropagation();
                    dispatch(
                      updateInvoicesDueTableVariables({
                        sortDirection:
                          invoices_due_tableVariables.sortDirection === "ASC"
                            ? "DESC"
                            : "ASC",
                      })
                    );
                  }}
                />
              </TableHeadWithIcon>
            </TableCell>
            <TableCell>
              <TableFadedText
                size={FontSizes.TX_XS}
                weight={FontWeights.MEDIUM}
              >
                Description
              </TableFadedText>
            </TableCell>

            <TableCell>
              <TableFadedText
                size={FontSizes.TX_XS}
                weight={FontWeights.MEDIUM}
              >
                Status
              </TableFadedText>
            </TableCell>
            <TableCell>
              <TableFadedText
                size={FontSizes.TX_XS}
                weight={FontWeights.MEDIUM}
              >
                Value
              </TableFadedText>
            </TableCell>

            <TableCell>
              <TableFadedText
                size={FontSizes.TX_XS}
                weight={FontWeights.MEDIUM}
              >
                Issue Date
              </TableFadedText>
            </TableCell>
            <TableCell>
              <TableFadedText
                size={FontSizes.TX_XS}
                weight={FontWeights.MEDIUM}
              >
                Due Date
              </TableFadedText>
            </TableCell>
            <TableCell>
              <TableFadedText
                size={FontSizes.TX_XS}
                weight={FontWeights.MEDIUM}
              >
                Actions
              </TableFadedText>
            </TableCell>
          </TableCustomHeader>
          {renderInvoices()}
          {data?._metadata?.last_page && data?._metadata?.last_page > 1 && (
            <TableRowDynamic height="68px">
              <TablePagination
                currentPage={data?._metadata?.page ?? 1}
                setCurrentPage={(page: number) =>
                  dispatch(
                    updateInvoicesDueTableVariables({
                      page: page,
                    })
                  )
                }
                totalPages={data?._metadata?.last_page ?? 1}
              />
            </TableRowDynamic>
          )}
        </Table>
      </InvoicesDueTableContainer>
    );
  };

  const onPayInvoices = () => {
    const total = selected_invoices.reduce((acc, invoice) => {
      const inv = data?.invoices?.find((inv) => inv.id === invoice.id);
      return acc + parseFloat(inv?.total ?? "0");
    }, 0);
    dispatch(setTotalPayment(total));

    navigate("/payment/pay-invoices");
  };
  return (
    <InvoicesDueContainer>
      <InvoicesDueHeader>
        <InvoicesDueSelectedCounter
          size={FontSizes.TX_SM}
          weight={FontWeights.MEDIUM}
        >
          {selected_invoices.length} selected invoice(s)
        </InvoicesDueSelectedCounter>
        <Button
          variant={ButtonVariants.primary}
          size={ButtonSizes.sm}
          disabled={!selected_invoices.length}
          text="Pay Invoice(s)"
          onClick={() => onPayInvoices()}
        />
      </InvoicesDueHeader>

      {renderTable()}
    </InvoicesDueContainer>
  );
};

export default InvoicesDue;
