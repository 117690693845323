import { EmployeeTableContainer, ScrollableTableWrapper, TextBold, TextFaded } from './styled';
import { FontSizes, FontWeights } from '../../../../constants/styles';
import { TableCell, TableHeader, TableRowDynamic } from '../../../shared/Table/styled';
import { LEAVE_TYPE, MONTHS } from '../../../../constants/dropDownLists';
import moment from 'moment';
import { EmployeeDetails } from '../../../../types/people';

const EmployeeLeavesTable = ({employeeData} : {employeeData: EmployeeDetails}) => {

   const renderRows = () => {
      return employeeData?.leaves?.map((leave) => {
        return (
          <>
            <TableRowDynamic height="40px">
              <TableCell>
                <TextBold size={FontSizes.TX_MD} weight={FontWeights.MEDIUM}>
                  {moment(leave.created_at).format("DD MMM , YYYY")}
                </TextBold>
              </TableCell>

              <TableCell>
                <TextBold size={FontSizes.TX_MD} weight={FontWeights.MEDIUM}>
                  {LEAVE_TYPE[leave.leave_type].label}
                </TextBold>
              </TableCell>

              <TableCell>
                <TextBold size={FontSizes.TX_MD} weight={FontWeights.MEDIUM}>
                  {MONTHS[leave.month - 1].label}
                </TextBold>
              </TableCell>

              <TableCell>
                <TextBold size={FontSizes.TX_MD} weight={FontWeights.MEDIUM}>
                  {`${leave.number_of_days} Days`}
                </TextBold>
              </TableCell>
            </TableRowDynamic>
          </>
        );
      });
    };

  return (
   <ScrollableTableWrapper>
      <EmployeeTableContainer>
         <TableHeader>
            <TableCell>
               <TextFaded size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                  Created On
               </TextFaded>
            </TableCell>

            <TableCell>
               <TextFaded size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                  Leave Type
               </TextFaded>
            </TableCell>

            <TableCell>
               <TextFaded size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                  Month
               </TextFaded>
            </TableCell>

            <TableCell>
               <TextFaded size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                  Number Of Days 
               </TextFaded>
            </TableCell>
         </TableHeader>
         {renderRows()}
      </EmployeeTableContainer>
   </ScrollableTableWrapper>
  )
}

export default EmployeeLeavesTable;