import { useMutation, useQuery } from "@tanstack/react-query";
import { apiClient } from "./apiClient";
import {
  AddServicesToCompanyParams,
  CreateCompanyParams,
  CreateOrganizationParams,
  CreateOrganizationResponse,
  getUpcomingActionsDashboardResponse,
} from "../types/apisTypes";
import qs from "qs";
import { Company } from "../types/companies";

export const useCreateOrganization = () => {
  return useMutation<CreateOrganizationResponse, Error, CreateOrganizationParams>({
    mutationFn: async (params: CreateOrganizationParams) => {
      const response = await apiClient.post("/organization", params);
      return response.data;
    },
  });
};

export const useAddServicesToCompany = ({
  companyId,
  services,
}: AddServicesToCompanyParams) => {
  return useMutation<CreateOrganizationResponse, Error>({
    mutationFn: async () => {
      const response = await apiClient.patch(`/company/${companyId}`, {
        services,
      });
      return response.data;
    },
  });
};

export const useGetUserCompanies = (enabled: boolean = true) => {
  return useQuery<Company[], Error>({
    queryKey: ["AllCompanies"],
    queryFn: async () => {
      const response = await apiClient.get("/company");
      return response.data.companies;
    },
    retry: false,
    enabled,
  });
};

export const useCreateCompany = () => {
  return useMutation<any, Error, CreateCompanyParams>({
    mutationFn: async (company: CreateCompanyParams) => {
      const response = await apiClient.post("/company", company);
      return response.data;
    },
  });
};

export const useGetCompanyPlan = (companyId: string) => {
  return useQuery<any, Error>({
    queryKey: ["CompanyPlan", companyId],
    queryFn: async () => {
      const response = await apiClient.get(`/company_plan/${companyId}`);
      return response.data.plan;
    },
    retry: false,
    enabled: !!companyId,
  });
};

export const useUpcomingActionsDashboard = (companyId: number | undefined) => {
  return useQuery<getUpcomingActionsDashboardResponse, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/dashboard/upcoming_actions?company_id=${companyId}`);
      return response.data;
    },
    retry: false,
    queryKey: [`getUpcomingActionsDashboard`, companyId],
    enabled: !!companyId
  });
};
