import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../constants/styles";
import Typography from "../../shared/Typography";
import { Card, CardRow } from "../../shared/Card";

export const PayrollDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${Spaces.spacing_xl};
`;

export const PayrollDetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: ${Spaces.spacing_xl};
  border-bottom: 1px solid ${Colors.border_secondary};
  width: 100%;
  align-items: start;
`;
export const PayrollDetailsHeaderTitleContainer = styled.div`
  display: flex;
  gap: ${Spaces.spacing_md};
`;

export const PayrollDetailsHeadersButtonsContainer = styled.div`
  display: flex;
  gap: ${Spaces.spacing_lg};
`;

export const PayrollDetailsTitle = styled(Typography)`
  color: ${Colors.text_primary};
`;
export const PayrollDetailsSubtitle = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;

export const TransparentRow = styled(CardRow)`
  background-color: transparent;
`;

export const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;
export const CardsColumn = styled.div`
  display: flex;
  gap: ${Spaces.spacing_xl};
  flex: 1;
  flex-direction: column;
`;

export const PayrollDetailsCardRow = styled(CardRow)`
  width: 100%;
`;

export const PayslipDownloadSubtitle  = styled(Typography)`
  color: ${Colors.text_warning_primary_600};
`;

// margin-bottom: ${Spaces.spacing_md};
export const Header = styled(Card)`
  margin-bottom: 0;
`;

export const NotificationBanner = styled.div`
  display: flex;
  align-items: center;
  background-color: ${Colors.Blue_light_50};
  padding: 8px;
  gap: ${Spaces.spacing_lg};
  justify-content: start;
  border-radius: ${Radiuses.radius_xl};
  border: 1px solid ${Colors.fg_brand_primary_alt};
`;

export const TextBold = styled(Typography)`
  color: ${Colors.text_primary};
`;

export const Banner = styled.div`
  display: flex;
  gap: ${Spaces.spacing_xl};
  padding: ${Spaces.spacing_xl};
  border-radius: ${Radiuses.radius_xl};
  background-color: ${Colors.bg_warning_primary};
  border: 1px solid ${Colors.fg_warning_secondary};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin-bottom: ${Spaces.spacing_xl};
  align-items: center;
  width: 100%;
  box-sizing: border-box;
`;
export const BannerDescription = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;