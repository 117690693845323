import React, { useCallback, useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/reduxHooks";
import { updateActivePayrollDetailsEmployeesVariables } from "../../../redux/slices/payrollSlice";
import {
  PayrollReviewCardRow,
  PayrollReviewCardRowValuesContainer,
  DownloadButton,
  PayrollReviewContainer,
  PayrollReviewHeader,
  PayrollReviewHeaderTitleContainer,
  PayrollReviewHeadersButtonsContainer,
  PayrollReviewSubtitle,
  PayrollReviewTableContainer,
  PayrollReviewTitle,
  SearchContainer,
  TableHeaderText,
  PayrollReviewTableHeader,
  NameCell,
  NameCellText,
  EmailCell,
  CellText,
  CellSubtext,
  Banner,
  BannerDescription,
  NotificationBanner,
  TextBold,
  HeaderCard,
} from "./styled";
import {
  Card,
  CardHeader,
  CardTitle,
  CardRowValue,
  CardRowTitle,
} from "../../shared/Card";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
  LoaderSizes,
} from "../../../constants/styles";
import Badge from "../../shared/Badge";
import {
  BadgeTypes,
  DropdownListOption,
  SnackbarTypes,
} from "../../../types/componentsProps";
import moment from "moment";
import Button from "../../shared/Button";
import ArrowRight from "../../../Images/icons/arrow-right-light.svg";
import AlertCircle from "../../../Images/icons/alertInfo.svg"
import SearchInput from "../../shared/SearchInput";
import DropdownList from "../../shared/DropdownList";
import {
  Table,
  TableCell,
  TableRow,
  TableRowDynamic,
} from "../../shared/Table/styled";
import Alert from "../../../Images/icons/alertTriangleOutline.svg";
import InitialsProfilePic from "../../shared/InitialsProfilePic";
import { currencyFormatter } from "../../../utilities/currencyFormatter";
import { PAYROLL_STATUS_BADGE_COLOR, PayrollReviewEmployee, PayrollStatus } from "../../../types/payroll";
import EmployeePayrollDetailsModal from "../EmployeePayrollDetailsModal";
import SubmitPayrollReportModal from "../SubmitPayrollReportModal";
import {
  useGetPayroll,
  useGetPayrollEmployeesCsv,
  useGetPayrollReviewEmployees,
} from "../../../queries/payroll";
import { useSnackbar } from "../../../hooks/useSnackbar";
import TablePagination from "../../shared/TablePagination";
import { ALL_STATUS_OPTIONS, PAYROLL_EMPLOYEES_STATUS_OPTIONS } from "../../../constants/dropDownLists";
import { PEOPLE_STATUS_BADGE_COLOR } from "../../../types/people";
import Loader from "../../shared/Loader";
import { downloadFile } from "../../../utilities/file";
import { useDebounce } from "../../../hooks/useDebounce";
import Check from "../../../Images/icons/thin-check.svg";
import Banknote from "../../../Images/icons/bank-note-white.svg";
import { useQueryClient } from "@tanstack/react-query";

const PayrollReview: React.FC = () => {

  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const {
    search,
    selectedStatus,
    currentPage,
    itemsPerPage,
    totalPages,
    filteredEmployees,
  } = useAppSelector(
    (state) => state.payroll.activePayrollDetailsEmployeesVariables
  );

  const [openEmployeeDetailsModal, setOpenEmployeeDetailsModal] = useState(false);
  const [openSubmitPayrollModal, setOpenSubmitPayrollModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState<PayrollReviewEmployee | null>(null);
  const [searchValue, setSearchValue] = useState<string>(search);
  const [startDownload, setStartDownload] = useState(false);
  const [date, setDate] = useState({ label: "", value: "" });

  const debounceValue = useDebounce(searchValue);
  const queryClient = useQueryClient();

  const { payroll_id } = useParams<{ payroll_id: string }>();
  const dispatch = useAppDispatch();
  
  const { data: file, error: downloadError, isFetching } = useGetPayrollEmployeesCsv({payroll_id, enabled: startDownload})
  const { data: review, error } = useGetPayroll(payroll_id);
  const { data: employeesData, error: employeesError, isPending } = useGetPayrollReviewEmployees({
    payroll_id: payroll_id,
    search,
    status: selectedStatus.label === "All Statuses"
    ? undefined
    : selectedStatus.label,
    page: currentPage,
    page_size: itemsPerPage,
  });
  
  useEffect(() => {
    if (employeesData?.payroll_employees) {
      dispatch(
        updateActivePayrollDetailsEmployeesVariables({
          filteredEmployees: employeesData.payroll_employees,
          currentPage: employeesData._metadata.page,
          totalRecords: employeesData._metadata.total_records,
          totalPages: employeesData._metadata.last_page,
        })
      );
    }
  }, [employeesData]);
  
  useEffect(() => {
    if(downloadError) {
      showSnackbar({
        type: SnackbarTypes.ERROR,
        open: true,
        title: "Something went wrong while downloading report.",
        description: "Please try again later.",
      });
    }
  }, [downloadError])

  useEffect(() => {
    if (error || employeesError) {
      showSnackbar({
        type: SnackbarTypes.ERROR,
        open: true,
        title: "Something went wrong while fetching payroll review.",
        description: "Please try again later.",
      });
    }
  }, [error, employeesError]);

  useEffect(() => {
    dispatch(
      updateActivePayrollDetailsEmployeesVariables({
        search: debounceValue,
        currentPage: 1
      }));
  }, [debounceValue]);
  
  if (!payroll_id) {
    <Navigate to="/404" />;
  }
  
  useEffect(() => {
    if(file) {
      downloadFile(file, "payroll_employees","text/csv");
      queryClient.removeQueries({ queryKey: ["payroll_employees_csv"] });
      setStartDownload(false);
    }
  }, [file])

  useEffect(() => {
    return function() {
      setStartDownload(false);
      dispatch(updateActivePayrollDetailsEmployeesVariables({
        search: "",
        selectedStatus: ALL_STATUS_OPTIONS[0],
        currentPage: 1
      }));
    }
  }, [])

  useEffect(() => {
    switch (review?.status) {
      case PayrollStatus.PENDING_REVIEW:
        setDate({
          label: "Payroll Due Date",
          value: moment(review.submission_due_date).format("DD MMM. YYYY"),
        });
        break;
      case PayrollStatus.PENDING_PAYMENT:
        setDate({
          label: "Payment Due Date",
          value: moment(review.payroll_cycle.payment_due_date).format(
            "DD MMM. YYYY"
          ),
        });
        break;
      case PayrollStatus.PENDING_PROCESSING:
      case PayrollStatus.PROCESSING:
        setDate({
          label: "Payroll Processing Date",
          value: moment(review.payroll_cycle.payroll_processing_date).format(
            "DD MMM. YYYY"
          ),
        });
        break;
      case PayrollStatus.PROCESSED:
        setDate({
          label: "Payroll Processed At",
          value: moment(review.processed_at).format("DD MMM. YYYY"),
        });
        break;
      default:
        break;
    }
  }, [review]);

  const renderRows = () => {
    return filteredEmployees.map((employee: PayrollReviewEmployee) => {
      return (
        <TableRow key={employee.id} 
          onClick={() => {
            setSelectedEmployee(employee);
            setOpenEmployeeDetailsModal(true);
          }}
        >
          <TableCell>
            <NameCell>
              <InitialsProfilePic
                fullName={`${employee.first_name} ${employee.last_name}`}
                style={{ width: "32px", height: "32px" }}
              />
              <div>
                <NameCellText
                  size={FontSizes.TX_SM}
                  weight={FontWeights.SEMIBOLD}
                >
                  {employee.first_name} {employee.last_name}
                </NameCellText>

                <EmailCell size={FontSizes.TX_XS} weight={FontWeights.SEMIBOLD}>
                  {employee.employee.user.email}
                </EmailCell>
              </div>
            </NameCell>
          </TableCell>
          <TableCell>
            <Badge text={employee.contract.status ?? ""} color={PEOPLE_STATUS_BADGE_COLOR[employee.contract.status]} />
          </TableCell>

          <TableCell>
            <div>
              <CellText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
                {currencyFormatter(
                  employee.bonuses.reduce(
                    (acc, bonus) => acc + bonus.amount,
                    0
                  ),
                  employee.currency
                )}
              </CellText>
              <CellSubtext size={FontSizes.TX_XS} weight={FontWeights.REGULAR}>
                Bonus
              </CellSubtext>
            </div>
          </TableCell> 
          <TableCell>
            <div>
              <CellText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
                {currencyFormatter(
                  employee.reductions.reduce(
                    (acc, reduction) => acc + reduction.amount,
                    0
                  ),
                  employee.currency
                )}
              </CellText>
              <CellSubtext size={FontSizes.TX_XS} weight={FontWeights.REGULAR}>
                Deductions
              </CellSubtext>
            </div>
          </TableCell>
          <TableCell>
            <div>
              <CellText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
                {currencyFormatter(employee.salary_net, employee.currency)}
              </CellText>
              <CellSubtext size={FontSizes.TX_XS} weight={FontWeights.REGULAR}>
                Salary
              </CellSubtext>
            </div>
          </TableCell>
          <TableCell>
            <div>
              <CellText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
                {currencyFormatter(employee.salary_gross - employee.salary_net, employee.currency)}
              </CellText>
              <CellSubtext size={FontSizes.TX_XS} weight={FontWeights.REGULAR}>
                SI & Taxes
              </CellSubtext>
            </div>
          </TableCell>
          <TableCell>
            <div>
              <CellText size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                {currencyFormatter(employee.salary_gross, employee.currency)}
              </CellText>
              <CellSubtext size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                Total
              </CellSubtext>
            </div>
          </TableCell>
        </TableRow>
      );
    });
  };

  const renderTable = () => {
    return (
      <PayrollReviewTableContainer>
        <PayrollReviewTableHeader>
          <TableHeaderText size={FontSizes.TX_XS} weight={FontWeights.SEMIBOLD}>
            Total of {employeesData?._metadata.total_records} employees
          </TableHeaderText>

          <DownloadButton
            onClick={() => setStartDownload(true)}
            disabled={isFetching}
          >
            Download report
          </DownloadButton>
        </PayrollReviewTableHeader>
        <Table>
          {renderRows()}
          {totalPages > 1 && (
            <TableRowDynamic height="68px">
              <TablePagination
                currentPage={currentPage}
                setCurrentPage={(page: number) =>
                  dispatch(
                    updateActivePayrollDetailsEmployeesVariables({
                      currentPage: page,
                    })
                  )
                }
                totalPages={totalPages}
              />
            </TableRowDynamic>
          )}
        </Table>
      </PayrollReviewTableContainer>
    );
  };

  return (
    <PayrollReviewContainer>
      <PayrollReviewHeader>
        <div>
          <PayrollReviewHeaderTitleContainer>
            <PayrollReviewTitle
              size={FontSizes.SM}
              weight={FontWeights.SEMIBOLD}
            >
              Payroll Details
            </PayrollReviewTitle>
            <Badge
              color={BadgeTypes.grayBlue}
              text={`${moment(review?.payroll_cycle.start_date).format(
                "DD MMM"
              )} - ${moment(review?.payroll_cycle.end_date).format(
                "DD MMM. YYYY"
              )}`}
            />
          </PayrollReviewHeaderTitleContainer>
          <PayrollReviewSubtitle
            size={FontSizes.TX_MD}
            weight={FontWeights.REGULAR}
          >
            {review?.company.name}
          </PayrollReviewSubtitle>
        </div>
        <PayrollReviewHeadersButtonsContainer>
          <Button
            text="View Summary"
            variant={ButtonVariants.primary}
            size={ButtonSizes.sm}
            onClick={() => navigate(`/payroll/payroll-details/${payroll_id}`)}
          />
          {review?.status === PayrollStatus.PENDING_REVIEW &&
            new Date() >= new Date(review.payroll_cycle.cutoff_date) &&
            new Date() <= new Date(review.submission_due_date) ? (
            <Button
              text="Submit"
              variant={ButtonVariants.primary}
              size={ButtonSizes.sm}
              iconLeading={<img src={Check} alt="Submit" width={20} height={20} />}
              onClick={() => setOpenSubmitPayrollModal(true)}
            />
          ) : review?.status === PayrollStatus.PENDING_PAYMENT ? (
            <Button
              text="Proceed with payment"
              variant={ButtonVariants.primary}
              size={ButtonSizes.sm}
              iconLeading={
                <img src={Banknote} alt="ban-note" width={20} height={20} />
              }
              onClick={() => navigate("/payment")}
            />
          ): null}
        </PayrollReviewHeadersButtonsContainer>
      </PayrollReviewHeader>
        {review?.status === PayrollStatus.PENDING_PAYMENT && (
          <Banner>
            <img src={Alert} alt="alert" />
            <BannerDescription
              size={FontSizes.TX_SM}
              weight={FontWeights.REGULAR}
            >
              An invoice has been created, please proceed with payment.
            </BannerDescription>
          </Banner>
        )}
      {review?.total_currency === "USD" && review.status === PayrollStatus.PENDING_REVIEW && (
        <NotificationBanner>
          <img src={AlertCircle} alt="alert" />
          <TextBold size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
            Calculations are subject to change upon submission due to potential fluctuations in the exchange rate.
          </TextBold>
        </NotificationBanner>
      )}
      <HeaderCard>
        <CardHeader>
          <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            Payroll report
          </CardTitle>
        </CardHeader>
        <PayrollReviewCardRowValuesContainer>
          <PayrollReviewCardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {date.label}
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {date.value}
            </CardRowValue>
          </PayrollReviewCardRow>

          <PayrollReviewCardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Payroll Status
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
              <Badge text={review?.status ?? ""} color={review ? PAYROLL_STATUS_BADGE_COLOR[review.status] : BadgeTypes.warning} />
            </CardRowValue>
          </PayrollReviewCardRow>
        </PayrollReviewCardRowValuesContainer>
      </HeaderCard>

      <SearchContainer>
        <DropdownList
          options={ALL_STATUS_OPTIONS.filter((v) =>
            [
              "Active",
              "Terminated",
              "Offboarding",
              "Pending Termination",
              "Expired",
              "Resigned",
            ].includes(v.label)
          )}
          selectedOption={selectedStatus}
          setSelectedOption={(status: DropdownListOption) =>
            dispatch(
              updateActivePayrollDetailsEmployeesVariables({
                selectedStatus: status,
              })
            )
          }
          containerStyle={{ width: "21.3%" }}
          style={{ marginTop: "0px" }}
        />
        <SearchInput
          placeholder="Search by name or email"
          containerStyle={{ boxSizing: "border-box", width: "40%" }}
          value={searchValue}
          onChange={(e) => { setSearchValue(e.target.value) }}
        />
      </SearchContainer>
      {isPending ? (
        <Loader size={LoaderSizes.lg} />
      ) : (
        renderTable()
      )}
      {selectedEmployee && (
        <EmployeePayrollDetailsModal
          open={openEmployeeDetailsModal}
          onClose={() => {
            setOpenEmployeeDetailsModal(false);
          }}
          employee={selectedEmployee}
          edit={review?.status === PayrollStatus.PENDING_REVIEW}
        />
      )}
      <SubmitPayrollReportModal
        open={openSubmitPayrollModal}
        onClose={() => {
          setOpenSubmitPayrollModal(false);
        }}
        payroll={review ? review : null}
      />
    </PayrollReviewContainer>
  );
};
export default PayrollReview;
