import { FontSizes, FontWeights } from "../../../../constants/styles";
import {
  Employee,
  PEOPLE_STATUS,
  PEOPLE_STATUS_BADGE_COLOR,
} from "../../../../types/people";
import {
  TableCell,
  TableHeadWithIcon,
  TableRowDynamic,
} from "../../../shared/Table/styled";
import {
  BadgesContainer,
  Divider,
  EmployeeTableContainer,
  TextBold,
  TextFaded,
  NameCell,
  ActionsCell,
  ActionButton,
  ActionCellLink,
} from "./styled";
import Badge from "../../../shared/Badge";
import { BadgeTypes, SnackbarTypes } from "../../../../types/componentsProps";
import InitialsProfilePic from "../../../shared/InitialsProfilePic";
import Delete from "../../../../Images/icons/trash.svg";
import Contract from "../../../../Images/icons/contract.svg";
import ContractMissing from "../../../../Images/icons/contractMising.svg";
import ContractExpiring from "../../../../Images/icons/contractExpiring.svg";
import TablePagination from "../../../shared/TablePagination";
import { useAppDispatch, useAppSelector } from "../../../../redux/reduxHooks";
import {
  setActiveEmployeeName,
  setEmployeesTableVariables,
} from "../../../../redux/slices/peopleSlice";
import { useState } from "react";
import DeleteEmployeeModal from "../DeleteEmployeeModal";
import { useDeleteEmployee } from "../../../../queries/people";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import moment from "moment";
import { contractExpiringDetails } from "../../../../utilities/contractUtils";
import { Sort } from "../../../shared/SortIcon";

const EmployeeTable = () => {
  const {
    currentPage,
    sortKey,
    sortDirection,
    filteredEmployees: data,
    totalPages,
  } = useAppSelector((state) => state.people.employees_table_variables);
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [toBeDeletedID, setToBeDeletedID] = useState<Employee | null>(null);
  const { mutateAsync: deleteEmployee, error } = useDeleteEmployee(
    toBeDeletedID?.id || "0"
  );
  const { showSnackbar } = useSnackbar();

  const deleteEmployeeHandler = async () => {
    if (toBeDeletedID) {
      try {
        await deleteEmployee(toBeDeletedID.id);
        setToBeDeletedID(null);
        setOpenDeleteModal(false);
        queryClient.invalidateQueries({ queryKey: ["employees"] });
      } catch (error) {
        setToBeDeletedID(null);
        setOpenDeleteModal(false);
        showSnackbar({
          type: SnackbarTypes.ERROR,
          open: true,
          title: "Error deleting employee",
          description:
            "An error occurred while deleting the employee. Please try again later.",
        });
      }
    }
  };
  const changeSortDirection = () => {
    dispatch(
      setEmployeesTableVariables({
        sortDirection: sortDirection === "ASC" ? "DESC" : "ASC",
      })
    );
  };

  const renderTableRows = () => {
    return data?.map((employee, index) => {
      const expiring = contractExpiringDetails({employment_term: employee.employment_term, status: employee.contract_status, end_date: employee.end_date});
      return (
        <>
          <TableRowDynamic height="40px">
            <TableCell>
              <NameCell
                to={`/people/profile/${employee.id}`}
                onMouseDown={() => {
                  dispatch(
                    setActiveEmployeeName(
                      `${employee.first_name} ${employee.last_name}`
                    )
                  );
                }}
              >
                <InitialsProfilePic
                  fullName={`${employee.first_name} ${employee.last_name}`}
                />
                <div>
                  <TextBold size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                    {employee.first_name} {employee.last_name}
                  </TextBold>
                  <TextFaded
                    size={FontSizes.TX_SM}
                    weight={FontWeights.REGULAR}
                  >
                    {employee.email}
                  </TextFaded>
                </div>
              </NameCell>
            </TableCell>

            <TableCell>
              <BadgesContainer>
                <Badge
                  text={employee.contract_status ? employee.contract_status : PEOPLE_STATUS.not_contracted}
                  color={PEOPLE_STATUS_BADGE_COLOR[employee.contract_status ? employee.contract_status : PEOPLE_STATUS.not_contracted]}
                />
                {expiring ? (
                  <Badge
                    text={`Contract expiring in ${expiring}`}
                    color={BadgeTypes.warning}
                  />
                ) : null}
              </BadgesContainer>
            </TableCell>

            <TableCell>
              <TextFaded size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
                {employee.contract_id ? employee.title : "--"}
              </TextFaded>
            </TableCell>

            <TableCell>
              <TextFaded size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
                {moment(employee.created_at).format("D MMM YYYY")}
              </TextFaded>
            </TableCell>

            <TableCell>
              <ActionsCell>
                {!employee.contract_status ? (
                  <ActionCellLink
                    to={`/people/profile/${employee.id}/contract`}
                    onMouseDown={() => {
                      dispatch(
                        setActiveEmployeeName(
                          `${employee.first_name} ${employee.last_name}`
                        )
                      );
                    }}
                  >
                    <ActionButton
                      src={ContractMissing}
                      alt="Contract Missing"
                    />
                  </ActionCellLink>
                ) : expiring ? (
                  <ActionCellLink
                    to={`/people/profile/${employee.id}/contract`}
                    onMouseDown={() => {
                      dispatch(
                        setActiveEmployeeName(
                          `${employee.first_name} ${employee.last_name}`
                        )
                      );
                    }}
                  >
                    <ActionButton
                      src={ContractExpiring}
                      alt="Contract Expiring"
                    />
                  </ActionCellLink>
                ) : (
                  <ActionCellLink
                    to={`/people/profile/${employee.id}/contract`}
                    onMouseDown={() => {
                      dispatch(
                        setActiveEmployeeName(
                          `${employee.first_name} ${employee.last_name}`
                        )
                      );
                    }}
                  >
                    <ActionButton src={Contract} alt="Contract" />
                  </ActionCellLink>
                )}

                {/* <ActionButton
                  src={Delete}
                  alt="Delete"
                  onClick={() => {
                    setToBeDeletedID(employee);
                    setOpenDeleteModal(true);
                  }}
                /> */}
              </ActionsCell>
            </TableCell>
          </TableRowDynamic>
          {index !== data.length - 1 && <Divider />}
        </>
      );
    });
  };

  return (
    <EmployeeTableContainer>
      <TableRowDynamic height="20px">
        <TableCell>
          <TableHeadWithIcon
            onClick={() => {
              dispatch(
                setEmployeesTableVariables({
                  sortKey: "name",
                })
              );
            }}
          >
            <TextFaded size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              Name
            </TextFaded>
            <Sort
              sortKey={"name"}
              currentSortKey={sortKey}
              sortDirection={sortDirection}
              onClick={changeSortDirection}
            />
          </TableHeadWithIcon>
        </TableCell>

        <TableCell>
          <TableHeadWithIcon
            onClick={() => {
              dispatch(
                setEmployeesTableVariables({
                  sortKey: "contract_status",
                })
              );
            }}
          >
            <TextFaded size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              Status
            </TextFaded>
            <Sort
              sortKey={"contract_status"}
              currentSortKey={sortKey}
              sortDirection={sortDirection}
              onClick={changeSortDirection}
            />
          </TableHeadWithIcon>
        </TableCell>
        <TableCell>
          <TableHeadWithIcon
            onClick={() => {
              dispatch(
                setEmployeesTableVariables({
                  sortKey: "title",
                })
              );
            }}
          >
            <TextFaded size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              Title
            </TextFaded>
            <Sort
              sortKey={"title"}
              currentSortKey={sortKey}
              sortDirection={sortDirection}
              onClick={changeSortDirection}
            />
          </TableHeadWithIcon>
        </TableCell>
        <TableCell>
          <TableHeadWithIcon
            onClick={() => {
              dispatch(
                setEmployeesTableVariables({
                  sortKey: "created_at",
                })
              );
            }}
          >
            <TextFaded size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              Creation Date
            </TextFaded>
            <Sort
              sortKey={"created_at"}
              currentSortKey={sortKey}
              sortDirection={sortDirection}
              onClick={changeSortDirection}
            />
          </TableHeadWithIcon>
        </TableCell>
      </TableRowDynamic>
      <Divider />

      {renderTableRows()}
      {totalPages > 1 && (
        <TableRowDynamic height="68px">
          <TablePagination
            currentPage={currentPage}
            setCurrentPage={(page: number) =>
              dispatch(setEmployeesTableVariables({ currentPage: page }))
            }
            totalPages={totalPages}
          />
        </TableRowDynamic>
      )}
      {/* <DeleteEmployeeModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        onConfirm={() => {
          deleteEmployeeHandler();
        }}
        name={`${toBeDeletedID?.first_name} ${toBeDeletedID?.last_name}`}
      /> */}
    </EmployeeTableContainer>
  );
};

export default EmployeeTable;
