import { useEffect, useState } from 'react'
import VerticalModal from '../../shared/VerticalModal';
import { CloseIcon, ModalContentContainer, ModalContentHeader, ModalContentTitle, ModalFooter, ModalSubTitle, ModalTitle } from './styled';
import Close from "../../../Images/icons/alertClose.svg";
import { ButtonSizes, ButtonVariants, FontSizes, FontWeights, Spaces } from '../../../constants/styles';
import InputField from '../../shared/InputField';
import Checkbox from '../../shared/Checkbox';
import { LEAVE_TYPE, MONTHS } from '../../../constants/dropDownLists';
import { useCreateLeave } from '../../../queries/people';
import { DropdownListOption, SnackbarTypes } from '../../../types/componentsProps';
import DropdownList from '../../shared/DropdownList';
import Button from '../../shared/Button';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { formErrorArrayHandler, formErrorHandler } from '../../../utilities/formHandler';
import { useQueryClient } from '@tanstack/react-query';
import { EmployeeDetails } from '../../../types/people';

const LeaveDetailsModal = ({open, onClose, employee} : {open: boolean, onClose: () => void, employee: EmployeeDetails | null}) => {
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

   const currentMonth = new Date().getMonth();
   const [formData, setFormData] = useState({
      leavesBalance: 21,
      numberOfDays: 0,
      unlimitedCheck: false,
      month: MONTHS[currentMonth],
      leaveType: LEAVE_TYPE[0],
   });
   const [formErrors, setFormErrors] = useState({
    leave_type: "",
    month: "",
    number_of_days: "",
    leave_balance: ""
   });
   const {mutateAsync: createLeave, error} = useCreateLeave();

  useEffect(() => {
    if(error) {
      showSnackbar({
        type: SnackbarTypes.ERROR,
        open: true,
        title: "Something went wrong while creating leave.",
        description: "Please try again later.",
      });
    }
  }, [error])

  useEffect(() => {
    if(employee && employee.contract) {
        setFormData({...formData, leavesBalance: employee.contract.leave_balance ? employee.contract.leave_balance : 21})
    }
  }, [employee])

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      leave_type: "",
      month: "",
      number_of_days: "",
      leave_balance: ""
    }
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const daysInMonth = new Date(currentYear, formData.month.value, 0).getDate();

    if(!(formData.numberOfDays > 0 && formData.numberOfDays <= daysInMonth)) {
      newErrors.number_of_days = "Please enter a valid number of leave days";
      isValid = false;
    }
    if(!formData.unlimitedCheck && !(formData.leavesBalance > 0 && formData.leavesBalance <= 365)) {
      newErrors.leave_balance = "Please enter a valid number of total leaves allowed";
      isValid = false;
    }

    setFormErrors(newErrors);
    return isValid;
  }

  const handleOnclose = () => {
    setFormData({
      leavesBalance: 21,
      numberOfDays: 0,
      unlimitedCheck: false,
      month: MONTHS[currentMonth],
      leaveType: LEAVE_TYPE[0],
    });
    setFormErrors({
      leave_type: "",
      month: "",
      number_of_days: "",
      leave_balance: ""
    });
    if(employee) {
      setFormData((prevData) => ({
        ...prevData,
        leavesBalance: employee.contract?.leave_balance ? employee.contract.leave_balance : 21,
        unlimitedCheck: !!employee.contract?.leave_balance
      }));
    }
    onClose();
  }

  const handleSubmitLeave = async () => {
    if(validateForm() && employee?.id && formData.leavesBalance) {
      try{
        await createLeave({
          employee_id: employee.id,
          leave_type: formData.leaveType.value,
          month: formData.month.value,
          number_of_days: formData.numberOfDays,
          leave_balance: formData.unlimitedCheck ? 365 : formData.leavesBalance,
        })
        queryClient.invalidateQueries({
          queryKey: ["Leaves"],
        });
        handleOnclose();
        showSnackbar({
          type: SnackbarTypes.SUCCESS,
          open: true,
          title: "Success",
          description: "Leave Added Successfully.",
        });
      } catch (err: any) {
        const errorMessage: string = err.response.data.message;
        const errorArray: [] = err.response.data.errors;
        const errorHandled: boolean = errorMessage ?
          formErrorHandler(errorMessage, formErrors, setFormErrors) :
          formErrorArrayHandler(errorArray, formErrors, setFormErrors);

        if(!errorHandled) {
          handleOnclose();
          showSnackbar({
            type: SnackbarTypes.ERROR,
            open: true,
            title: "Couldn't Add the Leave.",
            description: "Please try again later.",
          });
        }
      }
    }
  }

  return (
    <VerticalModal open={open} onClose={handleOnclose}>
        <ModalContentContainer>
          <ModalContentHeader>
            <ModalContentTitle>
              <ModalTitle
                size={FontSizes.TX_XL}
                weight={FontWeights.SEMIBOLD}
              >
                Assign Leave  
              </ModalTitle>
              <ModalSubTitle
                size={FontSizes.TX_SM}
                weight={FontWeights.REGULAR}
              >
                Enter the required details below
              </ModalSubTitle>
            </ModalContentTitle>
            <CloseIcon src={Close} alt="close" onClick={handleOnclose} />
          </ModalContentHeader>
          <InputField
            disabled={!!employee?.contract?.leave_balance || formData.unlimitedCheck}
            label="Total Number Of Days Allowed Per Year"
            type="number"
            containerStyle={{ marginBottom: Spaces.spacing_lg }}
            value={formData.leavesBalance}
            onChange={(e) => {
              setFormData({...formData, leavesBalance: parseInt(e.target.value)})
            }}
            error={formErrors.leave_balance}
            onFocus={() => {
              setFormErrors((prevErrors) => ({
                ...prevErrors,
                leave_balance: "",
              }));
            }}
          />
          <Checkbox
            disabled={!!employee?.contract?.leave_balance}
            label="Check this for unlimited leaves."
            containerStyle={{ marginBottom: Spaces.spacing_3xl }}
            name="checkbox"
            checked={formData.unlimitedCheck}
            onChange={() => {
              setFormData({...formData, unlimitedCheck: !formData.unlimitedCheck})
            }}
          />
          <InputField
            label="Number of Days"
            type="number"
            containerStyle={{ marginBottom: Spaces.spacing_3xl }}
            value={formData.numberOfDays}
            onChange={(e) => {
              setFormData({...formData, numberOfDays: parseInt(e.target.value)})
            }}
            error={formErrors.number_of_days}
            onFocus={() => {
              setFormErrors((prevErrors) => ({
                ...prevErrors,
                number_of_days: "",
              }));
            }}
          />
          <DropdownList
            label='Month'
            error={formErrors.month}
            options={MONTHS}
            selectedOption={formData.month}
            setSelectedOption={(selectedMonth: DropdownListOption) => {
              setFormData({...formData, month: selectedMonth})
              setFormErrors((prevErrors) => ({
                ...prevErrors,
                month: "",
              }));
            }}
            containerStyle={{ width: "100%", marginBottom: Spaces.spacing_3xl}}
          />
          <DropdownList
            label='Leave Type'
            error={formErrors.leave_type}
            options={LEAVE_TYPE}
            selectedOption={formData.leaveType}
            setSelectedOption={(selectedType: DropdownListOption) => {
              setFormData({...formData, leaveType: selectedType})
              setFormErrors((prevErrors) => ({
                ...prevErrors,
                number_of_days: "",
              }));
            }}
            containerStyle={{ width: "100%" }}
          />
          <ModalFooter>
            <Button
              text="Cancel"
              size={ButtonSizes.md}
              variant={ButtonVariants.secondary_color}
              onClick={handleOnclose}
            />
            <Button
              text="Submit"
              size={ButtonSizes.md}
              variant={ButtonVariants.primary}
              onClick={handleSubmitLeave}
            />
          </ModalFooter>
        </ModalContentContainer>
    </VerticalModal>
  )
}

export default LeaveDetailsModal