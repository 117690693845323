import styled from "styled-components";
import Typography from "../../../shared/Typography";
import { Colors, FontSizes, FontWeights, Radiuses, Spaces } from "../../../../constants/styles";

export const FormContainer = styled.div`
  display: flex;
  min-width: 440px;
  flex-direction: column;
`;

export const FormTitle = styled(Typography)`
  color: ${Colors.text_brand_primary};
`;
export const FormSubtitle = styled(Typography)`
  color: ${Colors.text_tertiary_600};
  white-space: pre-wrap;
  margin-bottom: ${Spaces.spacing_xl};
  margin-top: ${Spaces.spacing_xs};
`;
export const FormFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_xl};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SalaryType = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ToggleContainer = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  border-radius: ${Radiuses.radius_md};
  border: 1px solid ${Colors.border_primary};
  overflow: hidden;
  margin-top: ${Spaces.spacing_sm};
  box-sizing: border-box;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
`;

export const ToggleButton = styled.button<{active: boolean}>`
  flex:1;
  background-color: ${(props) => (props.active ? Colors.success_solid : Colors.fg_white)};
  color: ${(props) => (props.active ? Colors.fg_white : Colors.button_secondary_fg)};
  border: none;
  cursor: pointer;
  height: 40px;
  width: 60px;
  font-size: ${FontSizes.TX_SM};
  font-weight: ${FontWeights.SEMIBOLD};
`;

export const Salary = styled.div`
  display: flex;
  flex: 1;
  gap: ${Spaces.spacing_xl};
`;