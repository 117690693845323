import React from "react";
import { InputContainer, InputLabel, Input, ExtraMessage, InputRow, Currency, CurrencyContainer } from "./styled";
import { InputFieldProps } from "../../../types/componentsProps";

const InputField: React.FC<InputFieldProps> = ({
  label,
  hint,
  error,
  currency,
  containerStyle,
  extraMessageStyle,
  ...props
}) => {
  return (
    <InputContainer style={containerStyle}>
      {label && <InputLabel text={label} />}
      {currency ? (
        <InputRow error={!!error}>
          <Input 
            {...props}
            error={error}
            hint={hint} 
            style={{ border: "none", width: "100%", boxShadow: "none", height: "auto" }}
          />
          <CurrencyContainer>
            <Currency>
              {currency}  
            </Currency>
          </CurrencyContainer>
        </InputRow>
      ) : (
        <Input {...props} error={error} hint={hint} />
      )}
      <ExtraMessage style={extraMessageStyle}>
        {" "}
        {error?.length ? error : hint}
      </ExtraMessage>
    </InputContainer>
  );
};

export default InputField;
