import styled from "styled-components";
import { Spaces } from "../../../../constants/styles";
import { Card, CardRow } from "../../../shared/Card";

export const Header = styled(Card)`
  margin-bottom: ${Spaces.spacing_md};
`;

export const EmployeeLeavesCardRow = styled(CardRow)`
  width: 100%;
`;

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;