import { useEffect, useState } from 'react';
import { LeavesTypeCardTitle, PieChartContainer, PieChartKey, PieChartKeyColor, PieChartKeysContainer } from './styled';
import { FontSizes, FontWeights } from '../../../../constants/styles';
import { Card, CardHeader, CardTitle } from '../../../shared/Card';
import { LEAVE_TYPE } from '../../../../constants/dropDownLists';
import { PieChart } from 'recharts';
import { Pie } from 'recharts';
import { Cell } from 'recharts';
import { Label } from 'recharts';
import { EmployeeDetails } from '../../../../types/people';

interface CombinedLeave {
   leave_type: number;
   number_of_days: number;
}

const EmployeeLeavesPieCard = ({employeeData} : {employeeData: EmployeeDetails}) => {
   const [combinedLeaveData, setCombinedLeaveData] = useState<CombinedLeave[]>();

   useEffect(() => {
    if(employeeData?.leaves) {
      const combinedLeaveDays = employeeData.leaves.reduce<Record<number, number>>((acc, leave) => {
        const { leave_type, number_of_days } = leave;
        if (!acc[leave_type]) {
          acc[leave_type] = 0;
        }
        acc[leave_type] += number_of_days;
        return acc;
      }, {});

      const result: CombinedLeave[] = Object.keys(combinedLeaveDays).map((key) => ({
        leave_type: Number(key),
        number_of_days: combinedLeaveDays[Number(key)],
      }));

      setCombinedLeaveData(result);
    }
   }, [employeeData?.leaves]); 

  return (
    <Card>
      <CardHeader>
        <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Type Of Leaves
        </CardTitle>
      </CardHeader>
      <PieChartContainer>
        <PieChart height={200} width={200}>
          <Pie
            data={combinedLeaveData}
            dataKey="number_of_days"
            nameKey="leave_type"
            outerRadius={100}
            innerRadius={50}
            startAngle={90}
            endAngle={-270}
          >
            {combinedLeaveData?.map((leave, index) => (
              <>
                <Cell
                  key={`cell-${index}`}
                  fill={
                    LEAVE_TYPE[leave.leave_type].color
                  }
                />
              </>
            ))}
            <Label
              value={`Total ${combinedLeaveData?.reduce((sum, leaveItem) => sum + leaveItem.number_of_days, 0)}`}
              position="center"
              style={{ fontSize: FontSizes.TX_LG, fontWeight: FontWeights.SEMIBOLD }}
            />
          </Pie>
        </PieChart>
        <PieChartKeysContainer>
          {LEAVE_TYPE?.map((type, index) => (
            <PieChartKey key={index}>
            <PieChartKeyColor
              color={
                LEAVE_TYPE[index].color || ""
              }
            />
            <LeavesTypeCardTitle
              size={FontSizes.TX_SM}
              weight={FontWeights.REGULAR}
            >
              {type.label}
            </LeavesTypeCardTitle>
            </PieChartKey>
          ))}
         </PieChartKeysContainer>
      </PieChartContainer>
    </Card>
  )
}

export default EmployeeLeavesPieCard;