import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../constants/styles";
import Typography from "../../shared/Typography";

export const EmployeeLeavesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${Spaces.spacing_lg}
`;

export const EmployeeLeavesHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${Spaces.spacing_2xl};
`;

export const CardsColumn = styled.div`
  display: flex;
  gap: 10px;
  flex: 1;
  flex-direction: column;
`;

export const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

export const EmptyContainer = styled.div`
  display: flex;
  padding: ${Spaces.spacing_lg} ${Spaces.spacing_xl};
  border-radius: ${Radiuses.radius_md};
  border: 1px solid ${Colors.bg_primary_hover};
  background-color: ${Colors.bg_primary_hover};
  justify-content: center;
  align-items: center;
  flex: 1;
  height: fit-content;
`;

export const TextBold = styled(Typography)`
  color: ${Colors.text_primary};
`;