import styled from "styled-components";
import { Colors, Spaces } from "../../../constants/styles";
import Typography from "../../shared/Typography";

export const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Spaces.spacing_3xl};
`;

export const ModalContentHeader = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: ${Spaces.spacing_3xl};
`;

export const ModalContentTitle = styled(Typography)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ModalTitle = styled(Typography)`
   color: ${Colors.text_primary};
`;

export const ModalSubTitle = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;

export const CloseIcon = styled.img`
  margin-left: auto;
  cursor: pointer;
`;

export const ModalFooter = styled.div`
  position: absolute;
  bottom: ${Spaces.spacing_xl};
  right: ${Spaces.spacing_xl};
  display: flex;
  justify-content: end;
  padding-block: ${Spaces.spacing_xl};
  padding-inline: ${Spaces.spacing_xl};
  width: 100%;
  border-top: 1px solid ${Colors.border_secondary};
  gap: ${Spaces.spacing_lg};
  margin-inline: -${Spaces.spacing_2xl};
  background-color: ${Colors.bg_primary};
`;