import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../constants/styles";
import { useAppSelector } from "../../../redux/reduxHooks";
import {
  PayrollCycleCurrentUpcoming,
  PayrollStatus,
} from "../../../types/payroll";
import Button from "../../shared/Button";
import EmptyActionCard from "../ActionCard";
import { ActionCardContainer, ActionCardTitle } from "../ActionCard/styled";
import {
  CardDataContainer,
  CardDataLabel,
  CardDataValue,
  CardHeader,
} from "./styled";
import { useEffect, useState } from "react";
import { useGetPayrollCycleDashboard } from "../../../queries/payroll";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { SnackbarTypes } from "../../../types/componentsProps";

const UpcomingPayrollCard = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  const { activeCompany } = useAppSelector((state) => state.companies);

  const { data: upcomingPayroll, error } = useGetPayrollCycleDashboard(
    "upcoming",
    activeCompany?.id
  );

  // useEffect(() => {
  //   if (error) {
  //     showSnackbar({
  //       open: true,
  //       type: SnackbarTypes.ERROR,
  //       title: "Error fetching upcoming payroll data",
  //       description: "Please try again later",
  //     });
  //   }
  // }, [error]);
  return upcomingPayroll?.id && upcomingPayroll.payroll_cycle.start_date ? (
    <ActionCardContainer>
      <CardHeader>
        <ActionCardTitle size={FontSizes.TX_MD} weight={FontWeights.MEDIUM}>
          Upcoming Payroll
        </ActionCardTitle>

        {upcomingPayroll.status === PayrollStatus.PENDING_REVIEW ? (
          <Button
            text="Review"
            onClick={() =>
              navigate(`/payroll/payroll-review/${upcomingPayroll.id}`)
            }
            size={ButtonSizes.sm}
            variant={ButtonVariants.primary}
            style={{ width: "fit-content" }}
          />
        ) : (
          <Button
            text="View"
            onClick={() =>
              navigate(`/payroll/payroll-details/${upcomingPayroll.id}`)
            }
            size={ButtonSizes.sm}
            variant={ButtonVariants.primary}
            style={{ width: "fit-content" }}
          />
        )}
      </CardHeader>
      <CardDataContainer>
        <div>
          <CardDataLabel size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
            Cycle
          </CardDataLabel>
          <CardDataValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {moment(upcomingPayroll.payroll_cycle.start_date).format(
              "D MMM YYYY"
            )}{" "}
            -{" "}
            {moment(upcomingPayroll.payroll_cycle.end_date).format(
              "D MMM YYYY"
            )}
          </CardDataValue>
        </div>
        <div>
          <CardDataLabel size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
            {upcomingPayroll.status == PayrollStatus.PENDING_REVIEW
              ? "Due Date"
              : upcomingPayroll.status === PayrollStatus.PENDING_PAYMENT
              ? "Payment Due Date"
              : "Processing Date"}
          </CardDataLabel>
          <CardDataValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {upcomingPayroll.status === PayrollStatus.PENDING_REVIEW
              ? moment(upcomingPayroll.payroll_cycle.review_deadline).format(
                  "D MMM YYYY"
                )
              : upcomingPayroll.status === PayrollStatus.PENDING_PAYMENT
              ? moment(upcomingPayroll.payroll_cycle.payment_due_date).format(
                  "D MMM YYYY"
                )
              : moment(
                  upcomingPayroll.payroll_cycle.payroll_processing_date
                ).format("D MMM YYYY")}
          </CardDataValue>
        </div>
      </CardDataContainer>
    </ActionCardContainer>
  ) : (
    <EmptyActionCard
      title="Upcoming Payroll"
      description="Effortlessly monitor and manage your employees."
      btnText="View Payroll"
      onClick={() => navigate(`/payroll`)}
    />
  );
};

export default UpcomingPayrollCard;
