import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../constants/styles";
import Typography from "../../shared/Typography";
import { Table } from "../../shared/Table/styled";

export const Container = styled.div`
  margin-top: 15px;
  width: 100%;
`;

export const FiltersContainer = styled.div`
  display: flex;
  padding: 10px;
  border-radius: ${Radiuses.radius_md};
  border: 1px solid ${Colors.border_primary};
  background-color: ${Colors.bg_primary};
  margin-bottom: 13px;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
`;

export const EmployeeTableContainer = styled(Table)`
  border-radius: ${Radiuses.radius_xl};
  border-color: ${Colors.border_secondary};
  border-style: solid;
  background-color: ${Colors.bg_primary};
  position: relative;
  scrollbar-width: none;
  overflow: hidden;
`;

export const TextFaded = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;

export const TextBold = styled(Typography)`
  color: ${Colors.text_primary};
`;

export const Divider = styled.div`
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: ${Colors.border_secondary};
`;

export const NameCell = styled.div`
  display: flex;
  gap: ${Spaces.spacing_lg};
  align-items: center;
  text-decoration: none;
`;

export const ActionsCell = styled.div`
  display: flex;
  gap: ${Spaces.spacing_xs};
`;