import {
  CloseIcon,
  ContentContainer,
  Information,
  InformationDescription,
  ModalContent,
  ModalContentTitleAndImgContainer,
  ModalFooter,
  ModalTitle,
  SalaryFields,
} from "./styled";
import { ModalProps, SnackbarTypes } from "../../../types/componentsProps";
import Modal from "../../shared/Modal";
import Button from "../../shared/Button";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../constants/styles";
import Close from "../../../Images/icons/alertClose.svg";
import AlertCircle from "../../../Images/icons/alertCircleGray.svg";
import { useEffect, useState } from "react";
import InputField from "../../shared/InputField";
import { useNumberDebounce } from "../../../hooks/useDebounce";
import { usePreviewPayrollEmployeeBonusDeduction } from "../../../queries/payroll";
import { BonusDeductionModalData } from "../../../types/payroll";
import { formErrorArrayHandler, formErrorHandler } from "../../../utilities/formHandler";
import { useSnackbar } from "../../../hooks/useSnackbar";
  
interface BonusDeduction {
  name       : string, 
  amount     : number, 
  grossSalary: number, 
  netSalary  : number
}

interface BonusReductionModalInterface {
  open             : ModalProps["open"];
  onClose          : ModalProps["onClose"];
  modalData        : BonusDeductionModalData;
  addBonusReduction: (bonusDeduction : BonusDeduction) => void;
}

const BonusReductionModal: React.FC<BonusReductionModalInterface> = ({ open, onClose, modalData, addBonusReduction}) => {
  const { showSnackbar } = useSnackbar();

  const [ formData, setFormData ] = useState({
    name           : "",
    amount         : 0,
    previewedAmount: 0,
    newGrossSalary : 0,
    newNetSalary   : 0,
  });

  const [ formErrors, setFormErrors ] = useState({
    name      : "",
    new_amount: "",
  });

  const [amountValue, setAmountValue] = useState<number>(formData.amount);
  const debounceValue = useNumberDebounce(amountValue);

  const { mutateAsync: getPreview } = usePreviewPayrollEmployeeBonusDeduction();

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      amount: debounceValue
    }));
  }, [debounceValue]);

  useEffect(() => {
    if(formData.amount) {
      handleFetchingPreview();
    }
  }, [formData.amount])

  const handleClose = () => {
    setFormData({
      name           : "",
      amount         : 0,
      previewedAmount: 0,
      newNetSalary   : 0,
      newGrossSalary : 0,
    });
    setFormErrors({
      name      : "",
      new_amount: "",
    })
    setAmountValue(0);
    onClose();
  }

  const handleFetchingPreview = async () => {
    if(formData.amount) {
      try{
        const preview = await getPreview({
          id             : modalData.employee.id,
          bonuses        : modalData.employee.bonuses,
          reductions     : modalData.employee.reductions,
          new_amount     : formData.amount,
          new_amount_type: modalData.type === "Deduction" ? "Reduction" : modalData.type
        });
        setFormData((prevData) => ({
          ...prevData,
          previewedAmount: preview.paying_amount,
          newGrossSalary : preview.gross_salary,
          newNetSalary   : preview.net_salary,
        }));
      } catch(err: any) {
        const errorMessage: string  = err.response.data.message;
        const errorArray  : []      = err.response.data.errors;
        const errorHandled: boolean = errorMessage ?
          formErrorHandler(errorMessage, formErrors, setFormErrors) :
          formErrorArrayHandler(errorArray, formErrors, setFormErrors);

        if(!errorHandled) {
          onClose();
          showSnackbar({
            type: SnackbarTypes.ERROR,
            open: true,
            title: `Couldn't Add ${modalData.type}.`,
            description: "Please try again later.",
          });
        }
      }
    }
  }

  const validateData = () => {
    let isValid = true;
    const newErrors = {
      name      : "",
      new_amount: ""
    }
    if(formData.name === "") {
      newErrors.name = "Please enter a description";
      isValid        = false;
    }
    if(!formData.previewedAmount || formData.amount === 0) {
      newErrors.new_amount = "Please enter an amount";
      isValid              = false;
    }
    setFormErrors(newErrors);
    return isValid;
  }

  const handleSubmit = () => {
    if(validateData()) {
      addBonusReduction({
        name       : formData.name,
        amount     : modalData.employee.contractSalaryType === "Gross" ? formData.amount : formData.previewedAmount,
        grossSalary: formData.newGrossSalary,
        netSalary  : formData.newNetSalary
      });
      handleClose();
    }
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <ContentContainer>
        <ModalContentTitleAndImgContainer>
          <ModalTitle size={FontSizes.TX_LG} weight={FontWeights.SEMIBOLD}>
            {`Add ${modalData.type}`}
          </ModalTitle>
          <CloseIcon src={Close} alt="close" onClick={handleClose} />
        </ModalContentTitleAndImgContainer>
        <ModalContent>
          <Information>
            <img src={AlertCircle} width={35} height={35}/>
            <InformationDescription
              size={FontSizes.TX_SM}
              weight={FontWeights.REGULAR}
            >
              The bonus amount you enter represents the net amount the employee will receive. Taxes will be applied, and you will pay the grossed-up amount, which includes the tax.
            </InformationDescription>
          </Information>
          <SalaryFields>
            <InputField
              currency={modalData.employee.currency}
              label={`${modalData.employee.contractSalaryType} ${modalData.type} Amount`}
              type="number"
              value={amountValue}
              onChange={(e) => {
                parseFloat(e.target.value) && setFormData({...formData, previewedAmount: -1});
                setAmountValue(parseFloat(e.target.value));
                setFormErrors((prevError) => ({
                  ...prevError,
                  new_amount: ""
                }));
              }}
              error={formErrors.new_amount}
              onFocus={() => {
                setFormErrors((prevError) => ({
                  ...prevError,
                  new_amount: ""
                }));
              }}
            />
            <InputField
              currency={modalData.employee.currency}
              label={modalData.type === "Bonus" ? "You will pay" : (modalData.employee.contractSalaryType === "Gross" ? "Net Deduction Amount" : "Gross Deduction Amount")}
              disabled={true}
              value={formErrors.new_amount === "" && amountValue ? (formData.previewedAmount === -1 ? "Loading" : formData.previewedAmount) : ""}
            />
          </SalaryFields>
          <InputField
            label="Description"
            type="text"
            containerStyle={{width: "100%"}}
            value={formData.name}
            onChange={(e) => {
              setFormData((prevData) => ({
                ...prevData,
                name: e.target.value
              }))
            }}
            error={formErrors.name}
            onFocus={() => {
              setFormErrors((prevError) => ({
                ...prevError,
                name: ""
              }));
            }}
          />
        </ModalContent>  
        <ModalFooter>
          <Button
            text="Cancel"
            variant={ButtonVariants.secondary_gray}
            size={ButtonSizes.lg}
            style={{ width: "100%" }}
            onClick={handleClose}
          />

          <Button
            text="Submit"
            variant={ButtonVariants.primary}
            size={ButtonSizes.lg}
            style={{ width: "100%" }}
            onClick={() => {
              handleSubmit();
            }}
          />
        </ModalFooter>
      </ContentContainer>
    </Modal>
  );
};
  
export default BonusReductionModal;
  