import { useEffect, useState } from "react";
import PayrollEmptyState from "../../components/payroll/PayrollEmptyState";
import PayrollTable from "../../components/payroll/PayrollTable";
import { FontSizes, FontWeights } from "../../constants/styles";
import { useAppDispatch, useAppSelector } from "../../redux/reduxHooks";
import {
  HeaderContainer,
  PayrollContainer,
  PayrollHeader,
  PayrollSubtitle,
  PayrollTitle,
} from "./styled";
import { Outlet, useLocation } from "react-router-dom";
import { useGetPayrolls } from "../../queries/payroll";
import { updatePayrollListVariables } from "../../redux/slices/payrollSlice";
import { useSnackbar } from "../../hooks/useSnackbar";
import { SnackbarTypes } from "../../types/componentsProps";
import { PAYROLL_STATUS_OPTIONS } from "../../constants/dropDownLists";
import DropdownList from "../../components/shared/DropdownList";
import { DropdownListOption } from "../../types/componentsProps";

const PayrollScreen = () => {
  const { activeCompany } = useAppSelector((state) => state.companies);

  const {
    sortDirection,
    search,
    currentPage,
    itemsPerPage,
    sortKey,
    filteredPayrolls,
    status,
  } = useAppSelector((state) => state.payroll.payrollListVariables);
  const dispatch = useAppDispatch();
  const { showSnackbar } = useSnackbar();

  const { data: payrollData, error } = useGetPayrolls({
    company_id: activeCompany?.id,
    sort: sortKey,
    sort_dir: sortDirection,
    search,
    page: currentPage,
    limit: itemsPerPage,
    status: status === PAYROLL_STATUS_OPTIONS[0] ? undefined : status.label,
  });

  const [isOutlet, setIsOutlet] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (payrollData) {
      dispatch(
        updatePayrollListVariables({
          filteredPayrolls: payrollData.payrolls,
          currentPage: payrollData._metadata.page,
          totalRecords: payrollData._metadata.total_records,
          totalPages: payrollData._metadata.last_page,
        })
      );
    }
  }, [payrollData]);

  useEffect(() => {
    if (error) {
      dispatch(updatePayrollListVariables({ filteredPayrolls: [] }));
      showSnackbar({
        type: SnackbarTypes.ERROR,
        open: true,
        title: "Something went wrong while fetching payrolls.",
        description: "Please try again later.",
      });
    }
  }, [error]);
  useEffect(() => {
    if (
      location.pathname.includes("payroll-review") ||
      location.pathname.includes("payroll-details") ||
      location.pathname.includes("process")
    ) {
      setIsOutlet(true);
    } else {
      setIsOutlet(false);
    }
  }, [location.pathname]);

  return (
    <PayrollContainer>
      {isOutlet ? (
        <Outlet />
      ) : (
        <>
          <PayrollHeader>
            <HeaderContainer>
              <div>
                <PayrollTitle size={FontSizes.SM} weight={FontWeights.SEMIBOLD}>
                  Payroll
                </PayrollTitle>
                <PayrollSubtitle
                  size={FontSizes.TX_MD}
                  weight={FontWeights.REGULAR}
                >
                  Manage your payroll here
                </PayrollSubtitle>
              </div>
              <div>
                <DropdownList
                  options={PAYROLL_STATUS_OPTIONS}
                  selectedOption={status}
                  setSelectedOption={(status: DropdownListOption) =>
                    dispatch(
                      updatePayrollListVariables({
                        status,
                        currentPage: 1,
                      })
                    )
                  }
                  // containerStyle={{ width: "21.3%" }}
                  style={{ height: "48px", marginTop: "0px" }}
                />
              </div>
            </HeaderContainer>
          </PayrollHeader>
          {filteredPayrolls ? <PayrollTable /> : <PayrollEmptyState />}{" "}
        </>
      )}
    </PayrollContainer>
  );
};

export default PayrollScreen;
