import React, { useEffect, useRef, useState } from 'react'
import VerticalModal from "../../../shared/VerticalModal";
import { Content, ContentTitle, ModalContent, ModalContentContainer, ModalContentHeader, ModalFooter, ModalSubtitle, ModalTitle, RadioGroup } from './styled';
import { ButtonSizes, ButtonVariants, FontSizes, FontWeights } from '../../../../constants/styles';
import InputField from '../../InputField';
import DropdownList from '../../DropdownList';
import PartialDropdown from '../../PartialDropdown';
import { DropdownListOption, SnackbarTypes } from '../../../../types/componentsProps';
import { organizationSizeOptions, PhoneCodes } from '../../../../constants/registration';
import { useAppSelector } from '../../../../redux/reduxHooks';
import { CORPORATION_TYPE } from '../../../../constants/dropDownLists';
import Checkbox from '../../Checkbox';
import Typography from '../../Typography';
import Button from '../../Button';
import { useSnackbar } from '../../../../hooks/useSnackbar';
import { useUpdateUserData } from '../../../../queries/auth';
import { useQueryClient } from '@tanstack/react-query';

interface ModalProps {
  open: boolean;
  onClose: () => void;
}

const MissingUserDataModal: React.FC<ModalProps> = ({ open, onClose }) => {
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { user } = useAppSelector((state) => state.auth);
  const { activeCompany } = useAppSelector((state) => state.companies);
  const { countriesOptions } = useAppSelector((state) => state.appState);
  const modalTopRef = useRef<HTMLDivElement | null>(null);

  const { mutateAsync: updateUserData, error } = useUpdateUserData();

  const errors = {
    date_of_birth: "",
    position: "",
    phone_number: "",
    country: "",
    name: "",
    industry: "",
    tax_id: "",     
    registration_number: "",
  }

  const [ formErrors, setFormErrors ] = useState(errors);

  const emptyFormData = {
    user_information: {
      date_of_birth: "",
      position: "",
      phone_number: "",
      phone_code: PhoneCodes[0],
    },
    organization: {
      country: countriesOptions.length > 0
      ? countriesOptions[0]
      : { label: "", value: "" },
      size: organizationSizeOptions[0],
    },
    company: {
      corportation_type: CORPORATION_TYPE[0],
      name: "",
      industry: "",
      is_established: false,
      tax_id: "",     
      registration_number: "",
      billing_name: ""
    },
    address: {
      street: "",
      city: "",
      state: "",
      zip: "",
      country: countriesOptions.length > 0
      ? countriesOptions[0]
      : { label: "", value: "" },
    }
  }
  const [formData, setFormData] = useState(emptyFormData)

  useEffect(() => {
    if(user && user.organization) {
      const phoneCode = PhoneCodes.find((code) => user.user_information.phone?.startsWith(code.value));
      setFormData((prevData) => ({
        ...prevData,
        user_information: {
          date_of_birth: user.user_information.date_of_birth ?? "",
          position: user.user_information.position ?? "",
          phone_code: phoneCode ?? PhoneCodes[0],
          phone_number: phoneCode ? user.user_information.phone.slice(phoneCode?.value.length) : "",
        },
        organization: {
          country: countriesOptions[user?.organization.country - 1],
          size:  organizationSizeOptions.find(
              (option) => option.value.min === user.organization.size_min || option.value.max === user.organization.size_max) || prevData.organization.size
        },
        address: {
          ...prevData.address,
          country: countriesOptions[user?.organization.country - 1]
        }
      }))
    }
  }, [user, countriesOptions, open])

  useEffect(() => {
    if(activeCompany) {
      setFormData((prevData) => ({
        ...prevData,
        company: {
          corportation_type: CORPORATION_TYPE.find((option) => option.value === activeCompany.corporation_type)
          || CORPORATION_TYPE[0],
          name: activeCompany.name ?? "",
          industry: activeCompany.industry ?? "",
          is_established: activeCompany.established ?? "",
          tax_id: activeCompany.tax_id ?? "",
          registration_number: activeCompany.registration_number ?? "",
          billing_name: activeCompany.billing_name ?? ""
        }
      }))
    }
  }, [activeCompany, open])

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };
    if (formData.user_information.date_of_birth === "") {
      newErrors.date_of_birth = "Date Of Birth is required";
      isValid = false;
    }
    if (formData.user_information.position === "") {
      newErrors.position = "Position is required";
      isValid = false;
    }
    if (formData.user_information.phone_number === "") {
      newErrors.phone_number = "Phone Number is required";
      isValid = false;
    }
    if (formData.organization.country.label === "") {
      newErrors.country = "Organization Country is required";
      isValid = false;
    }
    if (formData.company.name === "") {
      newErrors.name = "Company Name is required";
      isValid = false;
    }
    if (formData.company.industry === "") {
      newErrors.industry = "Company Industry is required";
      isValid = false;
    }
    if (formData.company.is_established === true) {
      if (formData.company.tax_id === "") {
        newErrors.tax_id = "Company Tax ID is required";
        isValid = false;
      }
      if (formData.company.registration_number === "") {
        newErrors.registration_number = "Company Registration Number is required";
        isValid = false;
      }
    }
    setFormErrors(newErrors);
    return isValid;
  };

  const handleClose = () => {
    setFormData(emptyFormData);
    setFormErrors(errors);
    onClose();
  }

  const handleSubmit = async () => {
    if(validateForm()) {
      try {
        if(activeCompany) {
          await updateUserData({
            user_information: {
              date_of_birth: formData.user_information.date_of_birth,
              position: formData.user_information.position,
              phone: formData.user_information.phone_code.label + formData.user_information.phone_number
            },
            organization: {
              country: formData.organization.country.value,
              size_min: formData.organization.size.value.min,
              size_max: formData.organization.size.value.max
            },
            company: {
              company_id: activeCompany?.id,
              corportation_type: formData.company.corportation_type.value,
              name: formData.company.name,
              industry: formData.company.industry,
              is_established: formData.company.is_established,
              tax_id: formData.company.tax_id,
              registration_number: formData.company.registration_number,
              billing_name: formData.company.billing_name,
              address: {
                street: formData.address.street || undefined,
                city: formData.address.city || undefined,
                state: formData.address.state || undefined,
                country: formData.address.country.value || undefined,
                zip: formData.address.zip || undefined
              }
            }
          });
          queryClient.invalidateQueries({
            queryKey: ["user"],
          });
          queryClient.invalidateQueries({
            queryKey: ["AllCompanies"],
          });
          handleClose();
          showSnackbar({
            type: SnackbarTypes.SUCCESS,
            open: true,
            title: "Success",
            description: "Successfully Updated Data.",
          });
        }
      } catch(err: any) {
        if(err.response.data.errors){
          const dateError: boolean = err.response.data.errors.some((e: string) => e.includes("should be a date")) ||
            err.response.data.errors.some((e: string) => e.includes("should be before today"));
          const phoneError: boolean = err.response.data.errors.some((e: string) => e.includes("should be a valid phone number"));
          if(dateError) {
            setFormErrors((prevData) => ({...prevData, date_of_birth: "Date is incorrect"}));
          }
          if(phoneError) {
            setFormErrors((prevData) => ({...prevData, phone_number: "Phone length is incorrect"}));
          }
          modalTopRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        } else { 
          handleClose();
          showSnackbar({
            type: SnackbarTypes.ERROR,
            open: true,
            title: "Something went wrong while updating user data.",
            description: "Please try again later.",
          });
        }
      }
    } else {
      modalTopRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      return;
    }
  }

  return (
    <VerticalModal open={open} onClose={handleClose}>
      <ModalContentContainer ref={modalTopRef}>
        <ModalContentHeader>
          <ModalTitle size={FontSizes.XS} weight={FontWeights.SEMIBOLD}>
            Updating Missing information
          </ModalTitle>
          <ModalSubtitle size={FontSizes.TX_MD} weight={FontWeights.REGULAR}>
            Please fill in all the fields below
          </ModalSubtitle>
        </ModalContentHeader>

        <ModalContent>
          <Content>
            <ContentTitle size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              Personal Information
            </ContentTitle>
            <InputField
              label="Date of birth"
              hint="Date of birth is required for regulatory and verification reasons."
              type="date"
              value={formData.user_information.date_of_birth}
              onChange={(e) => {
                setFormData((prevData) => ({
                  ...prevData,
                  user_information: {
                    ...prevData.user_information,
                    date_of_birth: e.target.value
                  }
                }))
              }}
              error={formErrors.date_of_birth}
              onFocus={() => {
                setFormErrors({
                  ...formErrors,
                  date_of_birth: "",
                });
              }}
            />
            <InputField
              label="Position"
              type="text"
              value={formData.user_information.position}
              onChange={(e) => {
                setFormData((prevData) => ({
                  ...prevData,
                  user_information: {
                    ...prevData.user_information,
                    position: e.target.value
                  }
                }));
              }}
              error={formErrors.position}
              onFocus={() => {
              setFormErrors({
                ...formErrors,
                position: "",
              });
              }}
            />
            <PartialDropdown
              label="Phone Number"
              value={formData.user_information.phone_number}
              onChange={(e) => {
                setFormData((prevData) => ({
                  ...prevData,
                  user_information: {
                    ...prevData.user_information,
                    phone_number: e.target.value
                  }
                }));
              }}
              error={formErrors.phone_number}
              onFocus={() => {
                setFormErrors({
                  ...formErrors,
                  phone_number: "",
                });
              }}
              options={PhoneCodes}
              setSelectedOption={(option: DropdownListOption) => {
                setFormData((prevData) => ({
                  ...prevData,
                  user_information: {
                    ...prevData.user_information,
                    phone_code: option
                  }
                }))
              }}
              selectedOption={formData.user_information.phone_code}
              type="number"
            />
          </Content>
          <Content>
            <ContentTitle size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              Organization Information
            </ContentTitle>
            <DropdownList
              label="Headquarters Country"
              searchOption={true}
              options={countriesOptions}
              selectedOption={formData.organization.country}
              setSelectedOption={(option) => setFormData((prevData) => ({
                ...prevData,
                organization: {
                  ...prevData.organization,
                  country: option
                }
              }))}
              error={formErrors.country}
              onFocus={() => {
                setFormErrors({
                  ...formErrors,
                  country: "",
                });
              }}
            />
            <DropdownList
              label="Organization size"
              options={organizationSizeOptions}
              selectedOption={formData.organization.size}
              setSelectedOption={(option) => setFormData((prevData) => ({
                ...prevData,
                organization: {
                  ...prevData.organization,
                  size: option
                }
              }))}
            />
          </Content>
          <Content>
            <ContentTitle size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              Company Information
            </ContentTitle>
            <InputField
              label="Name"
              type="text"
              value={formData.company.name}
              onChange={(e) => {
                setFormData((prevData) => ({
                  ...prevData,
                  company: {
                    ...prevData.company,
                    name: e.target.value
                  }
                }));
              }}
              disabled={true}
              error={formErrors.name}
              onFocus={() => {
                setFormErrors({
                  ...formErrors,
                  name: "",
                });
              }}
            />
            <DropdownList
              label="Company Type"
              options={CORPORATION_TYPE}
              selectedOption={formData.company.corportation_type}
              setSelectedOption={(option) => setFormData((prevData) => ({
                ...prevData,
                company: {
                  ...prevData.company,
                  corportation_type: option
                }
              }))}
            />
            <InputField
              label="Industry"
              type="text"
              value={formData.company.industry}
              onChange={(e) => {
                setFormData((prevData) => ({
                  ...prevData,
                  company: {
                    ...prevData.company,
                    industry: e.target.value
                  }
                }));
              }}
              error={formErrors.industry}
              onFocus={() => {
                setFormErrors({
                  ...formErrors,
                  industry: "",
                });
              }}
            />
            <InputField
              label="Billing Name"
              type="text"
              value={formData.company.billing_name}
              onChange={(e) => {
                setFormData((prevData) => ({
                  ...prevData,
                  company: {
                    ...prevData.company,
                    billing_name: e.target.value
                  }
                }));
              }}
            />
            <Typography size={FontSizes.TX_MD} weight={FontWeights.MEDIUM}>
              Is Established
            </Typography>
            <RadioGroup>
              <Checkbox
                label={"Yes"}
                checked={formData.company.is_established}
                onChange={() => {
                  setFormData((prevData) => ({
                    ...prevData,
                    company: {
                      ...prevData.company,
                      is_established: true
                    }
                  }));
                }}
                radio={true}
              />
              <Checkbox
                label={"No"}
                checked={!formData.company.is_established}
                onChange={() => {
                  setFormData((prevData) => ({
                    ...prevData,
                    company: {
                      ...prevData.company,
                      is_established: false
                    }
                  }));
                }}
                radio={true}
              />
            </RadioGroup>
            {formData.company.is_established && (
              <>
                <InputField
                  label="Tax ID"
                  value={formData.company.tax_id}
                  onChange={(e) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      company: {
                        ...prevData.company,
                        tax_id: e.target.value
                      }
                    }));
                  }}
                  error={formErrors.tax_id}
                  onFocus={() => {
                    setFormErrors({
                      ...formErrors,
                      tax_id: "",
                    });
                  }}
                />
                <InputField
                  label="Registeration Number"
                  value={formData.company.registration_number}
                  onChange={(e) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      company: {
                        ...prevData.company,
                        registration_number: e.target.value
                      }
                    }));
                  }}
                  error={formErrors.registration_number}
                  onFocus={() => {
                    setFormErrors({
                      ...formErrors,
                      registration_number: "",
                    });
                  }}
                />
              </>
            )}
          </Content>
          <Content>
            <ContentTitle size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              Billing Information
            </ContentTitle>
            <InputField
              label="Street"
              type="text"
              value={formData.address.street}
              onChange={(e) => {
                setFormData((prevData) => ({
                  ...prevData,
                  address: {
                    ...prevData.address,
                    street: e.target.value
                  }
                }));
              }}
            />
            <InputField
              label="City"
              type="text"
              value={formData.address.city}
              onChange={(e) => {
                setFormData((prevData) => ({
                  ...prevData,
                  address: {
                    ...prevData.address,
                    city: e.target.value
                  }
                }));
              }}
            />
            <InputField
              label="State"
              type="text"
              value={formData.address.state}
              onChange={(e) => {
                setFormData((prevData) => ({
                  ...prevData,
                  address: {
                    ...prevData.address,
                    state: e.target.value
                  }
                }));
              }}
            />
            <InputField
              label="ZIP"
              type="text"
              value={formData.address.zip}
              onChange={(e) => {
                setFormData((prevData) => ({
                  ...prevData,
                  address: {
                    ...prevData.address,
                    zip: e.target.value
                  }
                }));
              }}
            />
            <DropdownList
              label="Country"
              searchOption={true}
              options={countriesOptions}
              selectedOption={formData.address.country}
              setSelectedOption={(option) => setFormData((prevData) => ({
                ...prevData,
                address: {
                  ...prevData.address,
                  country: option
                }
              }))}
            />
          </Content>
        </ModalContent>

        <ModalFooter>
          <Button
            text="Update"
            variant={ButtonVariants.primary}
            size={ButtonSizes.lg}
            onClick={() => handleSubmit()}
            style={{width: "100%"}}
          />
          <Button
            text="Cancel"
            variant={ButtonVariants.secondary_gray}
            size={ButtonSizes.lg}
            onClick={() => handleClose()}
            style={{width: "100%"}}
          />
        </ModalFooter>
      </ModalContentContainer>
    </VerticalModal>
  );
};

export default MissingUserDataModal;
