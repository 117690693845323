export const formErrorHandler = (
  errorMessage: string,
  formErrors: Record<string, string>,
  setFormErrors: React.Dispatch<React.SetStateAction<any>>
) : boolean => {
  const keyMatch = errorMessage.match(/\[([^\]]+)\]/);
  const errorValue = errorMessage.split(":")[1]?.trim();

  if (keyMatch && errorValue) {
    const key = keyMatch[1];

    if(key in formErrors) {
      setFormErrors((prevData: any) => ({
        ...prevData,
        [key]: errorValue,
      }));
      
      return true;
    }
  }

  return false;
};

export const formErrorArrayHandler = (
  errorMessages: string[],
  formErrors: Record<string, any>,
  setFormErrors: React.Dispatch<React.SetStateAction<any>>
): boolean => {
  let hasUpdated = false;

  errorMessages.forEach((errorMessage) => {
    const keyMatch = errorMessage.match(/\[([^\]]+)\]/);
    const errorValue = errorMessage.split(":")[1]?.trim();

    if (keyMatch) {
      const key = keyMatch[1];

      if (key in formErrors) {
        setFormErrors((prevData: any) => ({
          ...prevData,
          [key]: errorValue,
        }));
        hasUpdated = true;
      }
    }
  });

  return hasUpdated;
};