export function getFirstSundayOfUpcomingMonth(): string {
    // Get the current date
    const today: Date = new Date();

    // Calculate the first day of the next month
    const nextMonth: Date = new Date(today.getFullYear(), today.getMonth() + 1, 1);

    // Find the first Sunday of the next month
    const dayOfWeek: number = nextMonth.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
    const daysUntilSunday: number = (7 - dayOfWeek) % 7; // Calculate days until the next Sunday
    const firstSunday: Date = new Date(nextMonth);
    firstSunday.setDate(nextMonth.getDate() + daysUntilSunday);

    // Return the date in YYYY-MM-DD format
    return firstSunday.toISOString().split('T')[0];
}
