import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../constants/styles";
import { useAppSelector } from "../../../redux/reduxHooks";
import Button from "../../shared/Button";
import EmptyActionCard from "../ActionCard";
import { ActionCardContainer, ActionCardTitle } from "../ActionCard/styled";
import {
  CardDataContainer,
  CardDataLabel,
  CardDataValue,
  CardHeader,
} from "./styled";
import { useGetPayrollCycleDashboard } from "../../../queries/payroll";

const CurrentPayrollCard = () => {
  const navigate = useNavigate();

  const { activeCompany } = useAppSelector((state) => state.companies);

  const { data: payroll } = useGetPayrollCycleDashboard(
    "current",
    activeCompany?.id
  );

  return payroll ? (
    <ActionCardContainer>
      <CardHeader>
        <ActionCardTitle size={FontSizes.TX_MD} weight={FontWeights.MEDIUM}>
          Current Payroll
        </ActionCardTitle>

        <Button
          text="View"
          onClick={() => navigate(`/payroll/payroll-details/${payroll.id}`)}
          size={ButtonSizes.sm}
          variant={ButtonVariants.primary}
          style={{ width: "fit-content" }}
        />
      </CardHeader>
      <CardDataContainer>
        <div>
          <CardDataLabel size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
            Cycle
          </CardDataLabel>
          <CardDataValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {moment(payroll.payroll_cycle.start_date).format("D MMM YYYY")} -{" "}
            {moment(payroll.payroll_cycle.end_date).format("D MMM YYYY")}
          </CardDataValue>
        </div>
        <div>
          <CardDataLabel size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
            Processing Date
          </CardDataLabel>
          <CardDataValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {moment(payroll.payroll_cycle.payroll_processing_date).format(
              "D MMM YYYY"
            )}
          </CardDataValue>
        </div>
      </CardDataContainer>
    </ActionCardContainer>
  ) : (
    <EmptyActionCard
      title="Current Payroll"
      description="Effortlessly monitor and manage your employees."
      btnText="View Payroll"
      onClick={() => navigate(`/payroll`)}
    />
  );
};

export default CurrentPayrollCard;
