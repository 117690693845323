import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../constants/styles";
import Typography from "../Typography";

export const CheckboxContainer = styled.div<{ subtext?: string }>`
  position: relative;
  display: flex;
  gap: ${Spaces.spacing_md};
  align-items: ${(props) => (props.subtext ? "start" : "center")};
  cursor: pointer;
`;

export const Subtext = styled(Typography)`
  color: ${Colors.text_tertiary_600};
  margin-block: ${Spaces.spacing_none};
`;

export const CheckboxText = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_none};
`;

export const CheckIcon = styled.img`
  width: 12px;
  height: 12px;
  position: absolute;
  left: 6px;
  top: 5px;
  pointer-events: none;
`;
export const RadioInnerDev = styled.div`
  width: 6px;
  height: 6px;
  border-radius: ${Radiuses.radius_full};
  position: absolute;
  background-color: white;
  left: 9px;
  top: 8px;
`;
export const CheckboxInput = styled.input<{
  radio?: boolean;
  checked?: boolean;
}>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border: 1px solid ${Colors.border_primary};
  border-radius: ${Spaces.spacing_xs};
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  cursor: pointer;

  &:checked {
    background-color: ${Colors.bg_brand_solid};
    border: none;
  }

  ${(props) =>
    props.radio &&
    `
    border-radius: ${Radiuses.radius_full};
    padding: 5px;
     `}

  ${(props) =>
    props.disabled &&
    `
     background-color: ${Colors.bg_disabled_subtle};

     `}

  &:focus {
    outline: none;

    ${(props) =>
      props.checked && !props.disabled
        ? `box-shadow: 0px 0px 0px 4px rgba(158, 119, 237, 0.24);`
        : props.disabled
        ? ""
        : `box-shadow: rgba(152, 162, 179, 0.14) 0px 0px 0px 4px;`}
  }
`;
