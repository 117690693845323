import { CSSProperties } from "styled-components";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../constants/styles";

export interface TypographyProps
  extends React.HTMLAttributes<HTMLParagraphElement> {
  size?: FontSizes;
  weight?: FontWeights;
  children: React.ReactNode;
}

export interface InputFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  containerStyle?: React.CSSProperties;
  hint?: string;
  error?: string;
  extraMessageStyle?: React.CSSProperties;
  currency?: string;
}

export interface CheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  subtext?: string;
  radio?: boolean;
  containerStyle?: CSSProperties;
  onChange?: () => void;
}

export interface SocialButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  social: "google" | "facebook" | "apple";
  text: string;
}

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  size: ButtonSizes;
  variant: ButtonVariants;
  iconLeading?: React.ReactNode;
  iconTrailing?: React.ReactNode;
  loading?: boolean;
}

export type DropdownListOption = {
  value: any;
  label: string;
  iconLeading?: string;
  comingSoon?: boolean;
  color?: string;
};

export interface DropdownListProps {
  label?: string;
  options: DropdownListOption[];
  setSelectedOption: (option: DropdownListOption) => void;
  selectedOption: DropdownListOption;
  searchOption?: Boolean;
  error?: string;
  onFocus?: () => void;
  style?: CSSProperties;
  containerStyle?: CSSProperties;
  hint?: string;
  listOptionStyle?: CSSProperties;
}

export interface PartialDropdownProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  options: DropdownListOption[];
  setSelectedOption: (option: DropdownListOption) => void;
  selectedOption: DropdownListOption;
  error?: string;
  onFocus?: () => void;
  trailing?: boolean;
}

export interface ModalProps extends React.HTMLAttributes<HTMLDialogElement> {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export interface SingleOTPInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  focus?: boolean;
  error?: boolean;
}

export interface OTPInputProps {
  length: number;
  onChangeOTP: (otp: string) => any;

  isNumberInput?: boolean;
  disabled?: boolean;
  separatorIndex?: number;
  style?: CSSProperties;
  className?: string;

  inputStyle?: CSSProperties;
  inputClassName?: string;
  error?: boolean;
  onFocus?: () => void;
}

export enum SnackbarTypes {
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
}

export interface SnackbarProps {
  open: boolean;
  title: string;
  description: string;
  type: SnackbarTypes;
  actionBtnText?: string;
  actionBtnCallback?: () => void;
  duration?: number;
  onClose: () => void;
}

export enum BadgeTypes {
  warning = "warning",
  blue = "blue",
  gray = "gray",
  blueLight = "blueLight",
  success = "success",
  error = "error",
  brand = "brand",
  grayBlue = "grayBlue",
  orange = "orange",
  purple = "purple",
  indigo = "indigo",
}

export interface TabsProps {
  tabsTitles: string[];
  activeTab: number;
  setActiveTab: (index: number) => void;
}

export interface StepperProps {
  steps: {
    title: string;
    description: string;
    completed: boolean;
    icon?: string;
    completedIcon?: string;
  }[];
  currentStep: number;
}
