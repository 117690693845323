import {
  GeneralDataContainer,
  GeneralDataLabel,
  GeneralDataRow,
  GeneralDataValue,
  DataContainer,
  DataContainerHeader,
  DataContainerTitle,
  NoContractContainer,
  NoContractText,
  ExpiringContractBannerContainer,
  ExpiringContractTitle,
  ExpiringContractDescription,
  ExpiringContractButtons,
} from "./styled";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import Button from "../../../shared/Button";
import NoContact from "../../../../Images/noContract.svg";
import Plus from "../../../../Images/icons/plus.svg";
import { useNavigate, useParams } from "react-router-dom";

import { currencyFormatter } from "../../../../utilities/currencyFormatter";
import {
  useCancelRenewEmployeeContract,
  useGetEmployee,
} from "../../../../queries/people";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import { SnackbarTypes } from "../../../../types/componentsProps";
import { useEffect, useState } from "react";
import { contractExpiringDetails } from "../../../../utilities/contractUtils";

const ContractOverView = ({}) => {
  const { id } = useParams();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutateAsync: cancelRenew } = useCancelRenewEmployeeContract();
  const [expiring, setExpiring] = useState<string | undefined>(undefined)

  const { data: employee, error } = useGetEmployee(id);

  useEffect(() => {
    if(employee?.contract)
    {
      setExpiring(contractExpiringDetails({status: employee.contract.status, employment_term: employee.contract.employment_term, end_date: employee.contract.end_date}))
    }
  }, [employee]);

  const onCancelContract = async () => {
    if (employee?.contract) {
      try {
        await cancelRenew({id: employee.contract.id});
        setExpiring(undefined)
        queryClient.invalidateQueries({
          queryKey: ["employee", employee.id],
        });
        queryClient.invalidateQueries({
          queryKey: ["employees"],
        });

        showSnackbar({
          open: true,
          type: SnackbarTypes.SUCCESS,
          title: "Contract cancelled successfully",
          description: "Successfully cancelled the employee contract",
          actionBtnText: "View Contract",
          actionBtnCallback() {
            navigate(`/people/profile/${employee?.id}/employee-contract`);
          },
        });
      } catch (error) {
        showSnackbar({
          open: true,
          type: SnackbarTypes.ERROR,
          title: "Something went wrong",
          description: "Failed to cancel contract",
        });
      }
    }
  };

  useEffect(() => {
    if (error) {
      showSnackbar({
        open: true,
        type: SnackbarTypes.ERROR,
        title: "Something went wrong while fetching employee data",
        description: "Please try again later",
        actionBtnText: "Go to Employees List",
        actionBtnCallback: () => {
          navigate("/people/manage");
        },
      });
    }
  }, [error]);

  const renderExpiringContractBanner = () => {
    return (
      <ExpiringContractBannerContainer>
        <ExpiringContractTitle
          size={FontSizes.TX_SM}
          weight={FontWeights.MEDIUM}
        >
          Contract Expiration
        </ExpiringContractTitle>
        <ExpiringContractDescription
          size={FontSizes.TX_SM}
          weight={FontWeights.REGULAR}
        >
          This employee’s contract will expire on {employee?.contract?.end_date}.{"\n"}
          Do you want to cancel the contract?
        </ExpiringContractDescription>
        <ExpiringContractButtons>
          <Button
            text={"Yes, Cancel The Contract"}
            size={ButtonSizes.sm}
            variant={ButtonVariants.primary}
            onClick={() => {
              onCancelContract();
            }}
          />
        </ExpiringContractButtons>
      </ExpiringContractBannerContainer>
    );
  };
  return (
    <DataContainer>
      <DataContainerHeader>
        <DataContainerTitle
          size={FontSizes.TX_MD}
          weight={FontWeights.SEMIBOLD}
        >
          Contract Information
        </DataContainerTitle>
      </DataContainerHeader>

      {employee?.contract ? (
        <div>
          {expiring && employee.contract.auto_renew? renderExpiringContractBanner() : null}
          <GeneralDataRow>
            <GeneralDataLabel
              size={FontSizes.TX_SM}
              weight={FontWeights.SEMIBOLD}
            >
              Worker Type
            </GeneralDataLabel>
            <GeneralDataValue
              size={FontSizes.TX_SM}
              weight={FontWeights.SEMIBOLD}
            >
              {employee.type_of_worker}
            </GeneralDataValue>
          </GeneralDataRow>
          <GeneralDataRow>
            <GeneralDataLabel
              size={FontSizes.TX_SM}
              weight={FontWeights.SEMIBOLD}
            >
              Job Title
            </GeneralDataLabel>
            <GeneralDataValue
              size={FontSizes.TX_SM}
              weight={FontWeights.SEMIBOLD}
            >
              {employee.contract.job_title}
            </GeneralDataValue>
          </GeneralDataRow>

          <GeneralDataRow>
            <GeneralDataLabel
              size={FontSizes.TX_SM}
              weight={FontWeights.SEMIBOLD}
            >
              Employment Type
            </GeneralDataLabel>
            <GeneralDataContainer>
              <GeneralDataValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {employee.contract.employment_type}
              </GeneralDataValue>
              <GeneralDataValue
                size={FontSizes.TX_SM}
                weight={FontWeights.REGULAR}
              >
                {employee.contract.hours_per_week}
              </GeneralDataValue>
            </GeneralDataContainer>
          </GeneralDataRow>
          <GeneralDataRow>
            <GeneralDataLabel
              size={FontSizes.TX_SM}
              weight={FontWeights.SEMIBOLD}
            >
              Salary
            </GeneralDataLabel>
            <GeneralDataValue
              size={FontSizes.TX_SM}
              weight={FontWeights.SEMIBOLD}
            >
             {employee?.contract?.salary_type  === "Gross" ? currencyFormatter(employee.contract.salary_gross, employee.contract.currency) : currencyFormatter(employee?.contract?.salary_net, employee?.contract?.currency)}
            </GeneralDataValue>
          </GeneralDataRow>
          <GeneralDataRow>
            <GeneralDataLabel
              size={FontSizes.TX_SM}
              weight={FontWeights.SEMIBOLD}
            >
              Employment Terms
            </GeneralDataLabel>
            <GeneralDataContainer>
              <GeneralDataValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {employee.contract.employment_term < 12
                  ? `${employee.contract.employment_term} months`
                  : "1 year"}
              </GeneralDataValue>
              {expiring ? (
                <GeneralDataValue
                  size={FontSizes.TX_SM}
                  weight={FontWeights.REGULAR}
                  error={true}
                >
                  Contract expiring in {expiring}
                </GeneralDataValue>
              ) : null}
            </GeneralDataContainer>
          </GeneralDataRow>
          <GeneralDataRow>
            <GeneralDataLabel
              size={FontSizes.TX_SM}
              weight={FontWeights.SEMIBOLD}
            >
              Company
            </GeneralDataLabel>
            <GeneralDataValue
              size={FontSizes.TX_SM}
              weight={FontWeights.SEMIBOLD}
            >
              {employee.company.name}
            </GeneralDataValue>
          </GeneralDataRow>
        </div>
      ) : (
        <NoContractContainer>
          <img src={NoContact} alt="No contracts" width={152} height={118} />
          <NoContractText size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            No contract assigned to this employee
          </NoContractText>
          <Button
            text={"Create Contract"}
            size={ButtonSizes.md}
            variant={ButtonVariants.primary}
            iconLeading={<img src={Plus} alt="Add contract" />}
            onClick={() =>
              navigate(`/people/profile/${employee?.id}/employee-contract`)
            }
            style={{ marginBottom: "20px" }}
          />
        </NoContractContainer>
      )}
    </DataContainer>
  );
};

export default ContractOverView;
