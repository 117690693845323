import { useAppDispatch, useAppSelector } from "../../../redux/reduxHooks";
import SearchInput from "../../shared/SearchInput";
import {
  Container,
  NameCellContainer,
  PayrollTableContainer,
  FiltersContainer,
  TableContainer,
  TableHeaderContainer,
  TableHeaderTitle,
  TextFaded,
} from "./styled";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../constants/styles";
import Badge from "../../shared/Badge";
import { BadgeTypes, DropdownListOption } from "../../../types/componentsProps";
import {
  TableCell,
  TableHeader,
  TableRow,
  TableRowDynamic,
  TableHeadWithIcon,
} from "../../shared/Table/styled";
import { TextBold } from "../../people/Manage/EmployeeTable/styled";
import moment from "moment";
import Button from "../../shared/Button";
import { useNavigate } from "react-router-dom";
import { updatePayrollListVariables } from "../../../redux/slices/payrollSlice";
import TablePagination from "../../shared/TablePagination";
import {
  CompanyPayroll,
  PAYROLL_STATUS_BADGE_COLOR,
} from "../../../types/payroll";
import { Sort } from "../../shared/SortIcon";
import { PAYROLL_STATUS_OPTIONS } from "../../../constants/dropDownLists";
import { useEffect } from "react";

const PayrollTable = () => {
  const dispatch = useAppDispatch();
  const {
    sortDirection,
    search,
    currentPage,
    filteredPayrolls,
    totalRecords,
    totalPages,
    sortKey,
    status,
  } = useAppSelector((state) => state.payroll.payrollListVariables);

  const navigate = useNavigate();

  const changeSortDirection = () => {
    dispatch(
      updatePayrollListVariables({
        sortDirection: sortDirection === "ASC" ? "DESC" : "ASC",
      })
    );
  };

  useEffect(() => {
    return function() {
      dispatch(updatePayrollListVariables({
        sortKey: "company",
        search: "",
        sortDirection: "ASC",
        currentPage: 1,
        status: PAYROLL_STATUS_OPTIONS[1]
      }))
    }
  }, [])

  const renderRows = () => {
    return filteredPayrolls?.map((payroll) => {
      return (
        <TableRow key={payroll.id}>
          <TableCell>
            <TextBold size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
              {moment(payroll.payroll_cycle.start_date).format("DD, MMM YYYY")} -
              {moment(payroll.payroll_cycle.end_date).format("DD, MMM YYYY")}
            </TextBold>
          </TableCell>

          <TableCell>
            <TextBold size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
              {moment(payroll.payroll_cycle.review_deadline).format("DD, MMM YYYY")}
            </TextBold>
          </TableCell>
          <TableCell>
            <Badge
              text={payroll.status}
              color={PAYROLL_STATUS_BADGE_COLOR[payroll.status]}
            />
          </TableCell>
          <TableCell>
            <Button
              text="View payroll"
              variant={ButtonVariants.primary}
              size={ButtonSizes.sm}
              onClick={() => {
                navigate(`/payroll/payroll-review/${payroll.id}`);
              }}
            />
          </TableCell>
        </TableRow>
      );
    });
  };

  const renderTable = () => {
    return (
      <PayrollTableContainer>
        <TableHeader>
          <TableCell>
            <TableHeadWithIcon
              onClick={() => {
                dispatch(
                  updatePayrollListVariables({
                    sortKey: "cycleStartDate" as keyof CompanyPayroll,
                  })
                );
              }}
            >
              <TextFaded size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
                Cycle
              </TextFaded>
              <Sort
                sortKey="cycleStartDate"
                currentSortKey={sortKey}
                sortDirection={sortDirection}
                onClick={changeSortDirection}
              />
            </TableHeadWithIcon>
          </TableCell>
          <TableCell>
            <TableHeadWithIcon
              onClick={() => {
                dispatch(
                  updatePayrollListVariables({
                    sortKey: "createdAt" as keyof CompanyPayroll,
                  })
                );
              }}
            >
              <TextFaded size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
                Due Date
              </TextFaded>
              <Sort
                sortKey="createdAt"
                currentSortKey={sortKey}
                sortDirection={sortDirection}
                onClick={changeSortDirection}
              />
            </TableHeadWithIcon>
          </TableCell>
          <TableCell>
            <TextFaded size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
              Status
            </TextFaded>
          </TableCell>
          <TableCell></TableCell>
        </TableHeader>
        {renderRows()}
        {totalPages > 1 && (
          <TableRowDynamic height="68px">
            <TablePagination
              currentPage={currentPage}
              setCurrentPage={(page: number) =>
                dispatch(updatePayrollListVariables({ currentPage: page }))
              }
              totalPages={totalPages}
            />
          </TableRowDynamic>
        )}
      </PayrollTableContainer>
    );
  };
  return (
    <Container>
      <TableContainer>
        <TableHeaderContainer>
          <TableHeaderTitle
            size={FontSizes.TX_LG}
            weight={FontWeights.SEMIBOLD}
          >
            Payroll Submission
          </TableHeaderTitle>
          <Badge text={totalRecords.toString()} color={BadgeTypes.gray} />
        </TableHeaderContainer>
        {renderTable()}
      </TableContainer>
    </Container>
  );
};

export default PayrollTable;
