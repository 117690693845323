import { EmployeeDetails } from '../../../../types/people';
import { DataContainer, EmployeeLeavesCardRow, Header } from './styled';
import { Colors, FontSizes, FontWeights } from '../../../../constants/styles';
import { CardHeader, CardRowTitle, CardRowValue, CardRowValuesContainer, CardTitle } from '../../../shared/Card';

const EmployeeLeavesCards = ({employeeData} : {employeeData: EmployeeDetails}) => {
  return (
    <>
      <Header>
        <CardHeader>
          <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            Employee’s Leave Balance Overview
          </CardTitle>
        </CardHeader>
        <CardRowValuesContainer>
          <EmployeeLeavesCardRow >
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
               Leave Days Used
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {employeeData.leaves ? employeeData.leaves.reduce((sum, leaveItem) => sum + leaveItem.number_of_days, 0) : 0}
            </CardRowValue>
          </EmployeeLeavesCardRow>

          <EmployeeLeavesCardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Total Number Of Days Allowed Per Year
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {employeeData.contract?.leave_balance ? (employeeData.contract.leave_balance == 365 ? "Unlimited" : employeeData.contract.leave_balance) : "--"}
            </CardRowValue>
          </EmployeeLeavesCardRow>
        </CardRowValuesContainer>
      </Header>
      <Header>
        <CardHeader>
          <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            Employee Information
          </CardTitle>
        </CardHeader>

        <CardRowValuesContainer>
          <EmployeeLeavesCardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
               Name
            </CardRowTitle>
            <DataContainer>
              <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                {`${employeeData.user.first_name} ${employeeData.user.last_name}`}
              </CardRowValue>
              <CardRowValue size={FontSizes.TX_XS} weight={FontWeights.REGULAR}>
                {`${employeeData.user.email}`}
              </CardRowValue>
            </DataContainer>
          </EmployeeLeavesCardRow>

          <EmployeeLeavesCardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Employee Type
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {employeeData.type_of_worker}
            </CardRowValue>
          </EmployeeLeavesCardRow>
        </CardRowValuesContainer>

        <CardRowValuesContainer>
          <EmployeeLeavesCardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Job Title
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {employeeData.contract?.job_title}
            </CardRowValue>
          </EmployeeLeavesCardRow>

          <EmployeeLeavesCardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Employment Type
            </CardRowTitle>
            <DataContainer>
              <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                {employeeData.contract?.employment_type}
              </CardRowValue>
              <CardRowValue size={FontSizes.TX_XS} weight={FontWeights.REGULAR}>
                {`${employeeData.contract?.hours_per_week} Hours Per Week`}
              </CardRowValue>
            </DataContainer>
          </EmployeeLeavesCardRow>
        </CardRowValuesContainer>

        <CardRowValuesContainer>
          <EmployeeLeavesCardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Employment Terms
            </CardRowTitle>
            <DataContainer>
              <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                {`${employeeData.contract?.employment_term} Months`}
              </CardRowValue>
              <CardRowValue size={FontSizes.TX_XS} weight={FontWeights.REGULAR} style={{color: Colors.text_secondary_on_brand}}>
                {`Contract expiring on ${employeeData.contract?.end_date}`}
              </CardRowValue>
            </DataContainer>
          </EmployeeLeavesCardRow>

          <EmployeeLeavesCardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Company
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {employeeData.company.name}
            </CardRowValue>
          </EmployeeLeavesCardRow>
        </CardRowValuesContainer>
      </Header>
    </>
  )
}

export default EmployeeLeavesCards