import {
  Container,
  PayrollTableContainer,
  FiltersContainer,
  TextFaded,
  DropDownsContainer,
} from "./styled";
import { useAppDispatch, useAppSelector } from "../../../../redux/reduxHooks";
import { useEffect, useState } from "react";
import { useDebounce } from "../../../../hooks/useDebounce";
import { updatePayrollListVariables } from "../../../../redux/slices/payrollSlice";
import {
  TableCell,
  TableHeader,
  TableHeadWithIcon,
  TableRow,
  TableRowDynamic,
} from "../../../../components/shared/Table/styled";
import { FontSizes, FontWeights } from "../../../../constants/styles";
import Badge from "../../../../components/shared/Badge";
import {
  CompanyPayroll,
  PAYROLL_STATUS_BADGE_COLOR,
} from "../../../../types/payroll";
import { Sort } from "../../../../components/shared/SortIcon";
import TablePagination from "../../../../components/shared/TablePagination";
import SearchInput from "../../../../components/shared/SearchInput";
import DropdownList from "../../../../components/shared/DropdownList";
import { PAYROLL_STATUS_OPTIONS } from "../../../../constants/dropDownLists";
import { DropdownListOption, SnackbarTypes } from "../../../../types/componentsProps";
import ActionsDropDown from "../../../../components/shared/ActionsDropDown";
import { TextBold } from "../../people/Manage/EmployeeTable/styled";
import moment from "moment";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import { useGetAdminPayrollEmployeesCsv, useGetAdminPayrolls, useProcessPayrollAdmin } from "../../../queries/payroll";
import Checkbox from "../../../../components/shared/Checkbox";
import { downloadFile } from "../../../../utilities/file";
import { useQueryClient } from "@tanstack/react-query";
import { currencyFormatter } from '../../../../utilities/currencyFormatter';

const PayrollTable = () => {
  const dispatch = useAppDispatch();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const {
    sortDirection,
    // search,
    currentPage,
    filteredPayrolls,
    totalPages,
    sortKey,
    status,
    itemsPerPage
  } = useAppSelector((state) => state.payroll.payrollListVariables);
  const { userCompaniesOptions, allCompanies } = useAppSelector((state) => state.companies);

  const [ selectedCompany, setSelectedCompany ] = useState<DropdownListOption>(userCompaniesOptions[0]);
  // const [searchValue, setSearchValue] = useState<string>(search);
  const [startDownload, setStartDownload] = useState(false);
  const [selectedPayrolls, setSelectedPayrolls] = useState<number[]>([]);

  // const debounceValue = useDebounce(searchValue);

  const { data: payrollData, error } = useGetAdminPayrolls({
    company_id: allCompanies?.find(company => company.name === selectedCompany.label)?.id,
    sort: sortKey,
    sort_dir: sortDirection,
    // search,
    page: currentPage,
    limit: itemsPerPage,
    status: status === PAYROLL_STATUS_OPTIONS[0] ? undefined : status.label,
  });

  const { data: payrollCsv, error: downloadError, isPending } = useGetAdminPayrollEmployeesCsv({
    enabled: startDownload,
    ids: selectedPayrolls
  })

  const { mutateAsync: processPayroll, error: processPayrollError } = useProcessPayrollAdmin();

  useEffect(() => {
    if(payrollCsv) {
      downloadFile(payrollCsv, "payroll_employees","text/csv");
      setStartDownload(false);
      queryClient.invalidateQueries({
        queryKey: ["payrolls"],
      });
    }
  }, [payrollCsv])

  useEffect(() => {
    if(downloadError) {
      setStartDownload(false);
      showSnackbar({
        type: SnackbarTypes.ERROR,
        open: true,
        title: "Something went wrong while downloading CSV.",
        description: "Please verify payroll status eligibility.",
      });
    }
  }, [downloadError])
  
  // useEffect(() => {
  //   dispatch(
  //     updatePayrollListVariables({
  //       search: debounceValue,
  //       currentPage: 1
  //     })
  //   );
  // }, [debounceValue]);

  useEffect(() => {
    if (payrollData) {
      dispatch(
        updatePayrollListVariables({
          filteredPayrolls: payrollData.payrolls,
          currentPage: payrollData._metadata.page,
          totalRecords: payrollData._metadata.total_records,
          totalPages: payrollData._metadata.last_page,
        })
      );
    }
  }, [payrollData]);

  useEffect(() => {
    if (error) {
      dispatch(updatePayrollListVariables({ filteredPayrolls: [] }));
      showSnackbar({
        type: SnackbarTypes.ERROR,
        open: true,
        title: "Something went wrong while fetching payrolls.",
        description: "Please try again later.",
      });
    }
  }, [error]);

  useEffect(() => {
    return function() {
      dispatch(updatePayrollListVariables({
        sortKey: "company",
        sortDirection: "ASC",
        search: "",
        currentPage: 1,
        status: PAYROLL_STATUS_OPTIONS[0],
      }))
    }
  }, [])

  const handleSelectedPayrolls = (payrolls: CompanyPayroll[]) => {
    const payrollIds = payrolls.map(payroll => payroll.id);
    setSelectedPayrolls((prevData) => {
      return prevData.filter(id => !payrollIds.includes(id)).concat(
          payrollIds.filter(id => !prevData.includes(id))
      );
    });
  };

  const handleProcessPayroll = async (id: number) => {
    try {
      await processPayroll(id);
      queryClient.invalidateQueries({
        queryKey: ["payrolls"],
      });
      showSnackbar({
        type: SnackbarTypes.SUCCESS,
        open: true,
        title: "Success",
        description: "Payroll Marked Processed.",
      });
    } catch (err) {
      showSnackbar({
        type: SnackbarTypes.ERROR,
        open: true,
        title: "Something went wrong while processing payroll.",
        description: "Please try again later.",
      });
    }
  }

  const handleDownload = (id: number) => {
    if(selectedPayrolls.length === 0) {
      setSelectedPayrolls([id])
    }
    setStartDownload(true);
  }

  const renderRows = () => {
    return filteredPayrolls?.map((payroll) => {
      return (
        <TableRow key={payroll.id}>
          <TableCell onClick={() => handleSelectedPayrolls([payroll])}>
            <TableHeadWithIcon>
              <Checkbox
                label={""}
                checked={selectedPayrolls.includes(payroll.id)}
              />
            </TableHeadWithIcon>
          </TableCell>

          <TableCell>
            <TextBold size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
              {payroll.company && payroll.company.name}
            </TextBold>
          </TableCell>

          <TableCell>
            <TextBold size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
              {payroll.payroll_cycle && moment(payroll.payroll_cycle.start_date).format("DD, MMM YYYY")} -
              {payroll.payroll_cycle && moment(payroll.payroll_cycle.end_date).format("DD, MMM YYYY")}
            </TextBold>
          </TableCell>

          <TableCell>
            <Badge
              text={payroll.status}
              color={PAYROLL_STATUS_BADGE_COLOR[payroll.status]}
            />
          </TableCell>

          <TableCell>
            <TextBold size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
              {payroll.payroll_cycle && moment(payroll.payroll_cycle.payroll_processing_date).format("DD, MMM YYYY")}
            </TextBold>
          </TableCell>

          <TableCell>
            <TextBold size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
            {currencyFormatter(payroll.total, payroll.total_currency)}
            </TextBold>
          </TableCell>

          <TableCell>
            <ActionsDropDown
              actions={[
                {
                  text: "Mark Processed Payroll",
                  onSelect: () => {
                    handleProcessPayroll(payroll.id);
                  },
                },
                {
                  text: "Download CSV",
                  onSelect: () => {
                    handleDownload(payroll.id);
                  },
                },
              ]}
            />
          </TableCell>
        </TableRow>
      );
    });
  };

  const renderTable = () => {
    return (
      <PayrollTableContainer>
        <TableHeader>
          <TableCell
            onClick={(e) => {
              e.stopPropagation();
              handleSelectedPayrolls(filteredPayrolls || [])
            }}
          >
            <TableHeadWithIcon>
              <Checkbox
                label=""
                checked={filteredPayrolls ? (filteredPayrolls.length > 0 && selectedPayrolls.length === filteredPayrolls?.length) : false}
              />
            </TableHeadWithIcon>
          </TableCell>
          <TableCell>
            <TableHeadWithIcon
              onClick={() => {
                dispatch(
                  updatePayrollListVariables({
                    sortDirection: sortKey !== "company" ? "ASC" : ( sortDirection === "ASC" ? "DESC" : "ASC"),
                    sortKey: "company"
                  })
                );
              }}
            >
              <TextFaded size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
                Company
              </TextFaded>
              <Sort
                sortKey="company"
                currentSortKey={sortKey}
                sortDirection={sortDirection}
                onClick={() => {}}
              />
            </TableHeadWithIcon>
          </TableCell>
          <TableCell>
            <TableHeadWithIcon
              onClick={() => {
                dispatch(
                  updatePayrollListVariables({
                    sortDirection: sortKey !== "cycleStartDate" ? "ASC" : ( sortDirection === "ASC" ? "DESC" : "ASC"),
                    sortKey: "cycleStartDate"
                  })
                );
              }}
            >
              <TextFaded size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
                Cycle
              </TextFaded>
              <Sort
                sortKey="cycleStartDate"
                currentSortKey={sortKey}
                sortDirection={sortDirection}
                onClick={() => {}} // TODO: Remove all onclick on sort icon
              />
            </TableHeadWithIcon>
          </TableCell>
          <TableCell>
            <TableHeadWithIcon
              onClick={() => {
                dispatch(
                  updatePayrollListVariables({
                    sortDirection: sortKey !== "status" ? "ASC" : ( sortDirection === "ASC" ? "DESC" : "ASC"),
                    sortKey: "status"
                  })
                );
              }}
            >
              <TextFaded size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
                Status
              </TextFaded>
              <Sort
                sortKey="status"
                currentSortKey={sortKey}
                sortDirection={sortDirection}
                onClick={() => {}}
              />
            </TableHeadWithIcon>
          </TableCell>
          <TableCell>
            <TableHeadWithIcon
              onClick={() => {
                dispatch(
                  updatePayrollListVariables({
                    sortDirection: sortKey !== "processingDate" ? "ASC" : ( sortDirection === "ASC" ? "DESC" : "ASC"),
                    sortKey: "processingDate"
                  })
                );
              }}
            >
              <TextFaded size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
                Processing Date
              </TextFaded>
              { <Sort
                sortKey="processingDate"
                currentSortKey={sortKey}
                sortDirection={sortDirection}
                onClick={() => {}}
              /> }
            </TableHeadWithIcon>
          </TableCell>

          <TableCell>
            <TableHeadWithIcon
              // onClick={() => {
              //   dispatch(
              //     updatePayrollListVariables({
              //       sortDirection: sortKey !== "total" ? "ASC" : ( sortDirection === "ASC" ? "DESC" : "ASC"),
              //       sortKey: "total"
              //     })
              //   );
              // }}
            >
              <TextFaded size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
              Total
              </TextFaded>
              {/* <Sort
                sortKey="total"
                currentSortKey={sortKey}
                sortDirection={sortDirection}
                onClick={() => {}}
              /> */}
            </TableHeadWithIcon>
          </TableCell> 

          <TableCell>
            <TextFaded size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
              Actions
            </TextFaded>
          </TableCell>
        </TableHeader>
        {renderRows()}
        {totalPages > 1 && (
          <TableRowDynamic height="68px">
            <TablePagination
              currentPage={currentPage}
              setCurrentPage={(page: number) =>
                dispatch(updatePayrollListVariables({ currentPage: page }))
              }
              totalPages={totalPages}
            />
          </TableRowDynamic>
        )}
      </PayrollTableContainer>
    );
  };
  return (
    <Container>
      <FiltersContainer>
        {/* <SearchInput
          placeholder="Search for payroll"
          containerStyle={{ boxSizing: "border-box" }}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        /> */}

        <DropDownsContainer>
          <DropdownList
            options={userCompaniesOptions}
            selectedOption={selectedCompany}
            setSelectedOption={(company: DropdownListOption) => {
              setSelectedCompany(company)
              dispatch(updatePayrollListVariables({currentPage: 1}))
            }}
            containerStyle={{ width: "100%" }}
            style={{ height: "48px", marginTop: "0px" }}
          />
          <DropdownList
            options={PAYROLL_STATUS_OPTIONS}
            selectedOption={status}
            setSelectedOption={
              (status: DropdownListOption) =>
              dispatch(
                updatePayrollListVariables({
                  status,
                  currentPage: 1
                })
              )
            }
            containerStyle={{ width: "100%" }}
            style={{ height: "48px", marginTop: "0px" }}
          />
        </DropDownsContainer>
      </FiltersContainer>

      {renderTable()}
    </Container>
  );
};

export default PayrollTable;
