import styled from "styled-components";
import {
  Colors,
  FontSizes,
  FontWeights,
  Radiuses,
  Spaces,
} from "../../../constants/styles";
import Typography from "../Typography";

export const DropdownListContainer = styled.div<{ open: boolean }>`
  position: relative;
  width: 100%;
`;

export const DropdownListInput = styled.div<{
  disabled?: boolean;
  open: boolean;
  hasError?: boolean;
  hint?: boolean;
}>`
  width: 100%;
  height: 40px;
  padding: 10px 14px;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border: 1px solid ${Colors.border_primary};
  border-radius: ${Radiuses.radius_md};
  font-size: "1rem";
  font-weight: 400;
  color: ${Colors.text_primary};
  text-overflow: ellipsis;
  display: flex;
  margin-top: ${Spaces.spacing_sm};
  background-color: ${Colors.bg_primary};
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  ${(props) =>
    props.disabled &&
    `
      background-color: ${Colors.bg_disabled_subtle};
      border: 1px solid ${Colors.border_disabled};
      color: ${Colors.text_disabled};
    `};

  ${(props) =>
    props.open &&
    `
      border-color: ${Colors.border_brand};

    
    `};

  ${(props) =>
    props.hasError &&
    `
      border-color: ${Colors.border_error};

      ~ ${ErrorMessage} {
        display: block;
        color: ${Colors.text_error_primary};

      }
    `};

  ${(props) =>
    props.hint &&
    `
    ~ ${ErrorMessage} {
      display: block;
    }
  `};
`;

export const DropdownListValue = styled(Typography)`
  margin-block: ${Spaces.spacing_none};
  color: ${Colors.text_primary};
`;

export const  List = styled.div<{ open: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 20vh;
  background-color: ${Colors.bg_primary};
  margin-top: ${Spaces.spacing_xs};
  border-radius: ${Radiuses.radius_md};
  border: 1px solid ${Colors.border_secondary};
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  overflow-y: scroll;
  margin-bottom: 600px;
  z-index: 1000;
  scrollbar-width: none;
  ${(props) =>
    !props.open &&
    `
    display: none;  
    `};
`;

export const Option = styled.div<{ comingSoon?: boolean }>`
  display: flex;
  align-items: center;
  gap: ${Spaces.spacing_md};
  color: ${(props) =>
    props.comingSoon ? Colors.text_disabled : Colors.text_primary};
`;

export const ListOption = styled.div<{
  selected: boolean;
  disabled?: boolean;
}>`
  padding: 10px 10px 10px ${Spaces.spacing_lg};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  ${(props) =>
    props.selected &&
    `
    border-radius: ${Radiuses.radius_sm};
      background-color: ${Colors.utility_gray_200};
      margin: ${Spaces.spacing_sm};
    `};
`;

export const CheckIcon = styled.img`
  right: 0;
`;

export const ErrorMessage = styled.p`
  display: none;
  margin-block-end: ${Spaces.spacing_none};
  margin-block-start: ${Spaces.spacing_sm};
  font-size: ${FontSizes.TX_SM};
  font-weight: ${FontWeights.REGULAR};
  color: ${Colors.text_tertiary_600};
`;
