import styled from "styled-components";
import { Colors, Spaces } from "../../../../../constants/styles";
import Typography from "../../../../shared/Typography";

export const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Spaces.spacing_3xl};
`;

export const ModalContentHeader = styled.div`
  width: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: ${Spaces.spacing_3xl};
  gap: ${Spaces.spacing_3xl};
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_3xl};
  width: 100%;
`;

export const ModalFooter = styled.div`
  margin-top: ${Spaces.spacing_4xl};
  display: flex;
  bottom: ${Spaces.spacing_xl};
  justify-content: end;
  padding-block: ${Spaces.spacing_xl};
  border-top: 1px solid ${Colors.border_secondary};
  gap: ${Spaces.spacing_lg};
  background-color: ${Colors.bg_primary};
`;

export const ModalTitle = styled(Typography)`
  color: ${Colors.text_primary};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_2xl};
`;