import { useMutation, useQuery } from "@tanstack/react-query";
import {
  GetPayrollListParams,
  GetPayrollListResponse,
  GetPayrollReviewEmployees,
  GetPayrollReviewEmployeesResponse,
  SubmitPayrollParams,
  PayrollCycleCurrentUpcomingResponse,
  UpdateBonusReductionsParams,
  PreviewBonusReductionParams,
  PreviewBonusReductionResponse,
} from "../types/apisTypes";
import { apiClient } from "./apiClient";
import qs from "qs";
import {
  PayrollCycleCurrentUpcoming,
  PayrollDetails,
  UserPaymentProcessReview,
} from "../types/payroll";
import { AxiosError } from "axios";

export const useGetPayrollEmployeesCsv = ({ payroll_id, enabled }: { payroll_id: string | undefined, enabled: boolean }) => {
  return useQuery<void, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/payroll_employee_csv?payroll_id=${payroll_id}`, {
        responseType: 'arraybuffer',
      });
      return response.data;
    },
    enabled: !!payroll_id && enabled,
    retry: false,
    queryKey: ["payroll_employees_csv", payroll_id],
  });
};

export const useGetPayrolls = (params: GetPayrollListParams) => {
  return useQuery<GetPayrollListResponse, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/payroll?${qs.stringify(params)}`);
      return response.data;
    },
    retry: false,
    enabled: !!params.company_id,
    queryKey: ["payrolls", params],
  });
};

export const useGetPayrollReviewEmployees = (
  params: GetPayrollReviewEmployees
) => {
  return useQuery<GetPayrollReviewEmployeesResponse, Error>({
    queryFn: async () => {
      const response = await apiClient.get(
        `/payroll_employee?${qs.stringify(params)}`
      );
      return response.data;
    },
    retry: false,
    queryKey: ["payrollReviewEmployees", params],
    enabled: !!params.payroll_id,
  });
};

export const useAddBonusReduction = () => {
  return useMutation<void, Error, UpdateBonusReductionsParams>({
    mutationFn: async (params) => {
      const response = await apiClient.patch(
        `/payroll_employee/${params.id}`, params);
      return response.data;
    },
  });
};

export const useGetPayroll = (id: string | undefined) => {
  return useQuery<PayrollDetails, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/payroll/${id || ""}`);
      return response.data.payroll;
    },
    retry: false,
    queryKey: ["payrollDetails", id],
    enabled: !!id,
  });
};

export const useGetPayrollProcess = () => {
  return useQuery<UserPaymentProcessReview, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/payroll/process`);
      return response.data.data.payrollProcess;
    },
    retry: false,
    queryKey: ["payrollProcess"],
  });
};

export const useSubmitPayroll = () => {
  return useMutation<void, Error, number>({
    mutationFn: async (id) => {
      const response = await apiClient.post(`/payroll_submit/${id}`);
      return response.data;
    },
  });
};

type PayrollCycle = "upcoming" | "current";
export const useGetPayrollCycleDashboard = (payrollCycle: PayrollCycle, companyId: number | undefined) => {
  return useQuery<PayrollCycleCurrentUpcoming | undefined, Error>({
    queryFn: async () => {
      const response = await apiClient.get(
        `/dashboard/payroll_cycle/${payrollCycle}?company_id=${companyId}`
      );
      return response.data.payroll;
    },
    queryKey: ["PayrollCycleDashboard", payrollCycle, companyId],
    retry: false,
    enabled: !!companyId
  });
};

export const usePreviewPayrollEmployeeBonusDeduction = () => {
  return useMutation<PreviewBonusReductionResponse, Error, PreviewBonusReductionParams>({
    mutationFn: async (params) => {
      const response = await apiClient.post(`/payroll_employee_preview/${params.id}`, params);
      return response.data.data;
    },
  });
};