import SupportIcon from "../Images/icons/supportIcon.svg";
import SettingsIcon from "../Images/icons/settingsIcon.svg";
import OverviewIcon from "../Images/icons/overviewIcon.svg";
import PeopleIcon from "../Images/icons/peopleIcon.svg";
import PayrollIcon from "../Images/icons/payrollIcon.svg";
import PaymentsIcon from "../Images/icons/paymentsIcon.svg";
import AssetsIcon from "../Images/icons/assets-icon.svg";
import CompaniesIcon from "../Images/icons/companies.svg";
import CalenderIcon from "../Images/icons/calendarGrey.svg";
import { SideBarEntry } from "../types";
import { RegistrationSteps } from "./registration";

export const HOME_SCREEN = "/";
export const LOGIN_SCREEN = "/login";
export const RESET_PASSWORD_SCREEN = '/reset-password'
export const REGISTER_SCREEN = "register/:step";
export const PEOPLE_SCREEN = "/people";
export const MANAGE_SCREEN = "manage";
export const PROFILE_SCREEN = "profile/:id";
export const NEW_EMPLOYEE_CONTRACT_SCREEN = "employee-contract";
export const NEW_EMPLOYEE_SCREEN = "new-employee";
export const CONTRACTS_SCREEN = "contracts";
export const REQUEST_DETAILS_SCREEN = "request-details/:request_id";
export const PAYSLIP_DETAILS_SCREEN = "payslip-details/:payslip_id";
export const CONTRACT_SCREEN = "contract";
export const OVERVIEW_SCREEN = "overview";
export const ASSETS_SCREEN = "assets";
export const REQUESTS_SCREEN = "requests";
export const BENEFITS_SCREEN = "benefits";
export const PAYSLIPS_SCREEN = "payslips";
export const PAYROLL_SCREEN = "/payroll";
export const PAYROLL_REVIEW_SCREEN = "payroll-review/:payroll_id";
export const PAYROLL_DETAILS_SCREEN = "payroll-details/:payroll_id";
export const PAYROLL_PROCESS_SCREEN = "process";
export const PAYMENTS_SCREEN = "/payment";
export const INVOICE_DETAILS_SCREEN = "invoice-details/:invoice_id";
export const PAY_INVOICES_SCREEN = "pay-invoices";
export const PAYMENT_DETAILS_SCREEN = "payment-details/:payment_public_id";
export const ASSETS_HUB_SCREEN = "/assets-hub";
export const ADD_ASSET_SCREEN = "add-asset";
export const REQUEST_ASSET_SCREEN = "request-asset";
export const ASSET_DETAILS_SCREEN = "asset-details/:asset_id";
export const COMPANIES_SCREEN = "companies";
export const LEAVE_MANAGEMENT_SCREEN = "/leave-management";
export const LEAVES_SCREEN = "leaves/:id"; 

export const SIDE_BAR_SUPPORT_ENTRIES: SideBarEntry[] = [
  {
    text: "Support",
    icon: SupportIcon,
    path: "",
  },
  {
    text: "Settings",
    icon: SettingsIcon,
    path: "",
  },
];

export const SIDE_BAR_ENTRIES: SideBarEntry[] = [
  {
    text: "Overview",
    icon: OverviewIcon,
    path: "/",
  },
  {
    text: "People",
    icon: PeopleIcon,
    path: "",
    children: [
      {
        text: "Manage",
        path: "/people/manage",
        extraPaths: [
          "/people/new-employee",
          "/people/profile/",
          "people/employee-contract/",
        ],
      },
      {
        text: "Contract",
        path: "/people/contracts",
      },
    ],
  },
  {
    text: "Payroll",
    icon: PayrollIcon,
    path: "/payroll",
    extraPaths: [
      "/payroll/payroll-review/",
      "/payroll/payroll-details/",
      "/payroll/process",
    ],
  },
  {
    text: "Assets Hub (Coming Soon)",
    icon: AssetsIcon,
    path: "/assets-hub",
    extraPaths: [
      "/assets-hub/add-asset",
      "/assets-hub/request-asset",
      "/assets-hub/asset-details/",
    ],
  },
  {
    text: "Payments",
    icon: PaymentsIcon,
    path: "/payment",
    extraPaths: [
      "/payment/invoice-details/",
      "/payment/pay-invoices",
      "/payment/payment-details/",
    ],
  },
  {
    text: "Leave Management",
    icon: CalenderIcon,
    path: "/leave-management",
    extraPaths: [
      "/leave-management/leave",
    ],
  },
];

export const ADMIN_SIDE_BAR_ENTRIES: SideBarEntry[] = [
  {
    text: "People",
    icon: PeopleIcon,
    path: "/people",
  },
  {
    text: "Payroll",
    icon: PayrollIcon,
    path: "/payroll",
    extraPaths: [
      "/payroll/payroll-review/",
      "/payroll/payroll-details/",
      "/payroll/process",
    ],
  },
  {
    text: "Payments",
    icon: PaymentsIcon,
    path: "/payment",
    extraPaths: [
      "/payment/invoice-details/",
      "/payment/pay-invoices",
      "/payment/payment-details/",
    ],
  },
  {
    text: "Companies",
    icon: CompaniesIcon,
    path: "/companies",
  },
];

export const registrationRoutes = [
  RegistrationSteps.account_type,
  RegistrationSteps.user_registration,
  RegistrationSteps.email_confirmation,
  RegistrationSteps.organization_details,
  RegistrationSteps.personal_details,
  // RegistrationSteps.solutions,
  // RegistrationSteps.account_setup,
];

export const PeopleRoutes = ["manage", "contract", "new-employee"];
