import React, { useEffect, useState } from "react";
import VerticalModal from "../../shared/VerticalModal";
import {
  AddBonusButton,
  AddReductionButton,
  CloseIcon,
  ModalContentContainer,
  ModalContentHeader,
  ModalContentTitle,
  ModalContentTitleAndImgContainer,
  ProfileLink,
  ReviewContainer,
  ModalFooter,
  ModalCardTitle,
} from "./styled";
import {
  Card,
  CardHeader,
  CardRow,
  CardRowValue,
} from "../../shared/Card";
import { PayrollReviewEmployee } from "../../../types/payroll";
import InitialsProfilePic from "../../shared/InitialsProfilePic";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../constants/styles";
import External from "../../../Images/icons/link-external-brand.svg";
import Typography from "../../shared/Typography";
import Close from "../../../Images/icons/alertClose.svg";
import moment from "moment";
import { currencyFormatter } from "../../../utilities/currencyFormatter";
import Plus from "../../../Images/icons/plus-brand.svg";
import Button from "../../shared/Button";
import { useAddBonusReduction } from "../../../queries/payroll";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { SnackbarTypes } from "../../../types/componentsProps";
import { BonusReduction } from "../../../types/payroll";
import BonusReductionModal from "../BonusReductionModal";

interface EmployeePayrollDetailsModalProps {
  open: boolean;
  onClose: () => void;
  employee: PayrollReviewEmployee;
  edit: boolean | false
}

interface BonusDeduction {
  name       : string, 
  amount     : number, 
  grossSalary: number, 
  netSalary  : number
}

const EmployeePayrollDetailsModal: React.FC<
  EmployeePayrollDetailsModalProps
> = ({ open, onClose, employee, edit }) => {
  const { showSnackbar } = useSnackbar();

  const [
    openBonusReductionModal,
    setOpenBonusReductionModal]                 = useState(false);

  const [bonusOrDeduction, setBonusOrDeduction] = useState("Bonus");

  const [bonuses, setBonuses]                   = useState<BonusReduction[]>([]);
  const [reductions, setReductions]             = useState<BonusReduction[]>([]);

  const [newBonuses, setNewBonuses]             = useState<BonusReduction[]>([]);
  const [newReductions, setNewReductions]       = useState<BonusReduction[]>([]);

  const [newSalary, setNewSalary] = useState({
    grossSalary: 0,
    netSalary  : 0
  });
  
  const queryClient = useQueryClient();
  const { mutateAsync: addBonusReduction } = useAddBonusReduction();

  useEffect(() => {
    if(employee) {
      setBonuses(employee.bonuses);
      setReductions(employee.reductions);
      setNewSalary({netSalary: employee.salary_net, grossSalary: employee.salary_gross})
    }
  }, [employee, open]);

  const handleClose = () => {
    setBonuses([]);
    setReductions([]);
    setNewBonuses([]);
    setNewReductions([]);
    onClose();
  }

  const handleAddBonus = (bonus: BonusDeduction) => {
    setNewBonuses((prevData) => [...prevData, {name: bonus.name, amount: bonus.amount}])
    setBonuses((prevData)    => [...prevData, {name: bonus.name, amount: bonus.amount}])
    setNewSalary({ grossSalary: bonus.grossSalary, netSalary  : bonus.netSalary })
  }

  const handleAddReduction = (reduction: BonusDeduction) => {
    setNewReductions((prevData) => [...prevData, reduction])
    setReductions((prevData)    => [...prevData, reduction])
    setNewSalary({ grossSalary: reduction.grossSalary, netSalary  : reduction.netSalary })
  }

  const handleAddBonusReductions = async () => {
    try{ 
      await addBonusReduction({
        id: employee?.id,
        bonuses: newBonuses,
        reductions: newReductions,
      });
      queryClient.invalidateQueries({ queryKey: ["payrollReviewEmployees"] });
      handleClose();
      showSnackbar({
        type: SnackbarTypes.SUCCESS,
        open: true,
        title: "Data added successfully.",
        description: "",
      });
    } catch (err: any) {
      handleClose();
      const description = err.response?.data?.message;
      showSnackbar({
        type: SnackbarTypes.ERROR,
        open: true,
        title: "Data failed to be added.",
        description: description || "",
      });
    }
  }

  const getDataFromModal = (bonusDeduction: BonusDeduction) => {
    if(bonusOrDeduction === "Bonus") {
      handleAddBonus(bonusDeduction);
    } else {
      handleAddReduction(bonusDeduction);  
    }
  }

  return (
    <>
      <VerticalModal open={open} onClose={handleClose}>
        <ModalContentContainer>
          <ModalContentHeader>
            <ModalContentTitleAndImgContainer>
              <InitialsProfilePic
                fullName={`${employee?.first_name} ${employee?.last_name}`}
              />
              <div>
                <ModalContentTitle
                  size={FontSizes.TX_XL}
                  weight={FontWeights.SEMIBOLD}
                >
                  {employee?.first_name} {employee?.last_name}
                </ModalContentTitle>
                <ProfileLink href={`/people/profile/${employee?.id}/contract`}>
                  <Typography size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
                    View Contract
                  </Typography>
                  <img
                    src={External}
                    alt="external link"
                    width={16}
                    height={16}
                  />
                </ProfileLink>
              </div>
              <CloseIcon src={Close} alt="close" onClick={onClose} />
            </ModalContentTitleAndImgContainer>
          </ModalContentHeader>

          <ReviewContainer>
            <Card>
              <CardHeader>
                <ModalCardTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                  Contract Details
                </ModalCardTitle>
              </CardHeader>
              <CardRow>
                <ModalCardTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                  Employee Status
                </ModalCardTitle>
                <CardRowValue
                  size={FontSizes.TX_SM}
                  weight={FontWeights.SEMIBOLD}
                >
                  {employee?.contract.status}
                </CardRowValue>{" "}
              </CardRow>

              <CardRow>
                <ModalCardTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                  Payroll Cycle
                </ModalCardTitle>
                <CardRowValue
                  size={FontSizes.TX_SM}
                  weight={FontWeights.SEMIBOLD}
                >
                  {`${moment(employee?.pay_period_start).format(
                    "DD MMM"
                  )} - ${moment(employee?.pay_period_end).format(
                    "DD MMM"
                  )}`}
                </CardRowValue>{" "}
              </CardRow>
            </Card>

            <Card>
              <CardHeader>
                <ModalCardTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                  Salary
                </ModalCardTitle>
              </CardHeader>
              <CardRow>
                <ModalCardTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                  Gross Salary
                </ModalCardTitle>
                <CardRowValue
                  size={FontSizes.TX_SM}
                  weight={FontWeights.SEMIBOLD}
                >
                  {currencyFormatter(newSalary.grossSalary, employee?.currency)}
                </CardRowValue>
              </CardRow>

              <CardRow>
                <ModalCardTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                  Net Salary
                </ModalCardTitle>
                <CardRowValue
                  size={FontSizes.TX_SM}
                  weight={FontWeights.SEMIBOLD}
                >
                  {currencyFormatter(newSalary.netSalary, employee?.currency)}
                </CardRowValue>
              </CardRow>

              <CardRow>
                <ModalCardTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                  Pay Per
                </ModalCardTitle>
                <CardRowValue
                  size={FontSizes.TX_SM}
                  weight={FontWeights.SEMIBOLD}
                >
                  Month              
                </CardRowValue>
              </CardRow>
            </Card>

            <Card>
              <CardHeader>
                <ModalCardTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                  Bonus
                </ModalCardTitle>
                <AddBonusButton
                  onClick={() => {
                    setBonusOrDeduction("Bonus");
                    setOpenBonusReductionModal(true);
                  }}
                >
                  <img src={Plus} alt="plus" width={20} height={20} />

                  <Typography
                    size={FontSizes.TX_SM}
                    weight={FontWeights.SEMIBOLD}
                  >
                    Add Bonus
                  </Typography>
                </AddBonusButton>
              </CardHeader>
              {bonuses.map((bonus, index) => (
                <CardRow key={index}>
                  <ModalCardTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                    {bonus.name}
                  </ModalCardTitle>
                  <CardRowValue
                    size={FontSizes.TX_SM}
                    weight={FontWeights.SEMIBOLD}
                  >
                    {currencyFormatter(bonus.amount, employee?.currency)}
                  </CardRowValue>{" "}
                </CardRow>
              ))}
            </Card>

            <Card>
              <CardHeader>
                <ModalCardTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                  Deductions
                </ModalCardTitle>
                <AddReductionButton
                  onClick={() => {
                    setBonusOrDeduction("Deduction");
                    setOpenBonusReductionModal(true);
                  }}
                >
                  <img src={Plus} alt="plus" width={20} height={20} />

                  <Typography
                    size={FontSizes.TX_SM}
                    weight={FontWeights.SEMIBOLD}
                  >
                    Add Deduction
                  </Typography>
                </AddReductionButton>
              </CardHeader>
              {reductions.map((reduction, index) => (
                <CardRow key={index}>
                  <ModalCardTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                    {reduction.name}
                  </ModalCardTitle>
                  <CardRowValue
                    size={FontSizes.TX_SM}
                    weight={FontWeights.SEMIBOLD}
                  >
                    {currencyFormatter(reduction.amount, employee?.currency)}
                  </CardRowValue>{" "}
                </CardRow>
              ))}
            </Card>

            <ModalFooter>
              {edit && (
                <Button
                  text="Update"
                  size={ButtonSizes.md}
                  variant={ButtonVariants.primary}
                  disabled={!(newBonuses.length > 0 || newReductions.length > 0)}
                  onClick={() => {
                    handleAddBonusReductions();
                  }}
                />
              )}
            </ModalFooter>
          </ReviewContainer>
        </ModalContentContainer>
      </VerticalModal>
      {employee && (
        <BonusReductionModal 
          open={openBonusReductionModal}
          onClose={() => {setOpenBonusReductionModal(false)}}
          addBonusReduction={(bonusDeduction: BonusDeduction ) =>
            getDataFromModal(bonusDeduction)}
          modalData={{
            type: bonusOrDeduction,
            employee: {
              id                : employee.id,
              currency          : employee.currency,
              contractSalaryType: employee.contract_salary_type,
              bonuses           : newBonuses,
              reductions        : newReductions,
            }
          }}
        />
      )}
    </>
  );
};

export default EmployeePayrollDetailsModal;
